import { PropsWithChildren, ReactElement, ReactNode } from 'react';
import ErrorBoundary from '../ErrorBoundary';

const SubheadingDivider = ({
  headingText,
  children,
}: PropsWithChildren<{ headingText?: string }>): ReactNode => {
  if (!children) {
    return null;
  }
  const noValues =
    (children as ReactElement[])?.length &&
    (children as ReactElement[])?.every(
      (child) =>
        typeof child?.props === 'object' &&
        'value' in child.props &&
        [null, undefined].includes(child.props.value)
    );
  if (noValues) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center gap-2.5">
        <span className="text-light-text-primary dark:text-dark-text-primary text-sm">
          {headingText ?? ''}
        </span>
        <span className="border-t border-light-borders-tertiary dark:border-dark-borders-tertiary flex-grow"></span>
      </div>
      <div className="flex flex-row flex-wrap gap-5">{children}</div>
    </div>
  );
};

export default function WrappedSubheadingDivider(
  props: PropsWithChildren<{ headingText?: string }>
): ReactNode {
  return (
    <ErrorBoundary
      errorContext={`Error in SubheadingDivider ${props.headingText} ${props.children?.toString()}`}
    >
      <SubheadingDivider {...props} />
    </ErrorBoundary>
  );
}
