export const getOpportunityData = async (id: string, fetchFn: typeof fetch): Promise<any> => {
  const response = await fetchFn(`/apim/metarater/v1/crm/opportunity/${id}/application`);
  if (response.status === 404) {
    return { error: 'No Intake Data Available' };
  }
  if (!response.ok) {
    return { error: 'Failed to load opportunity data, please try again.' };
  }
  return response.json();
};
