import { FormDriver } from '@/services/forms/agent/schema';
import { OptionsConfig } from '@/services/forms/models/formTypes';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const useGetDynamicOptions = (optionsByField: string) => {
  const { getValues } = useFormContext();
  const [dynamicOptions, setDynamicOptions] = useState<OptionsConfig[]>([]);

  const fields = getValues(optionsByField || '');

  const isArray = Array.isArray(fields) && !!fields.length;
  const dependencies = isArray
    ? fields.flatMap(({ name }: FormDriver) => [name?.firstName, name?.lastName])
    : [];

  useEffect(() => {
    if (isArray) {
      const optionsList: OptionsConfig[] = [];
      // the optionsList logic is drivers specific for MVP
      fields.forEach(({ name }: FormDriver, index: number) => {
        if (name?.firstName || name?.lastName) {
          optionsList.push({
            key: index.toString(),
            display: [name?.firstName, name?.lastName].join(' '),
          });
        }
      });
      setDynamicOptions(optionsList);
    }
  }, [fields, isArray, ...dependencies]);

  return dynamicOptions;
};

export { useGetDynamicOptions };
