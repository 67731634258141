import { FC } from 'react';

type Field = {
  display: string;
  page: string;
};

export type SearchProps = {
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setSearchResults: React.Dispatch<React.SetStateAction<Field[] | null>>;
  searchResults: Field[] | null;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  searchInputRef: React.MutableRefObject<HTMLInputElement | null>;
};

const Search: FC<SearchProps> = ({
  onSearch,
  searchResults,
  setSearchResults,
  searchValue,
  setSearchValue,
  searchInputRef,
}) => {
  const closeButton = (color: string) => {
    return (
      <span
        className="absolute top-0 bottom-0 right-0 px-2 pt-2"
        onClick={() => {
          setSearchResults(null);
          setSearchValue('');
        }}
      >
        <svg
          className={`fill-current h-6 w-6 text-${color}-500"`}
          data-testid="close-button"
          role="button"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <title className="text-sm">Close</title>
          <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
        </svg>
      </span>
    );
  };

  return (
    <>
      <li
        className={`flex items-center gap-[10px] rounded-[4px] m-6 mx-2 font-small cursor-pointer
                  bg-light-surfaces-primary
                  border border-light-borders-secondary dark:border-dark-borders-secondary
                  hover:border-light-borders-primary dark:hover:border-dark-borders-primary
                  has-[:focus]:border-light-borders-primary dark:has-[:focus]:border-dark-borders-primary
                  text-dark-text-tertiary dark:text-dark-text-tertiary`}
      >
        <span className="material-symbols-outlined font24">search</span>
        <input
          data-testid="search-input"
          type="text"
          placeholder="Jump to..."
          className="border-none focus:outline-none text-light-text-contrast dark:text-light-text-contrast bg-transparent w-4/5"
          onChange={onSearch}
          value={searchValue}
          ref={searchInputRef}
        />
      </li>
      {searchResults && (
        <li>
          {searchResults.length > 0 ? (
            <div
              className="bg-blue-100 border border-blue-400 text-blue-700 px-2 py-2 rounded-[4px] relative m-4 mt-0"
              role="alert"
            >
              <span className="block sm:inline text-sm">{searchResults.length} matches found</span>
              {closeButton('blue')}
            </div>
          ) : (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-2 py-2 rounded-[4px] relative m-4 mt-0"
              role="alert"
            >
              <span className="block sm:inline text-sm">No matches found</span>
              {closeButton('red')}
            </div>
          )}
        </li>
      )}
    </>
  );
};

export { Search };
