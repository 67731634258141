import {
  QuoteGroupWithQuote,
  QuoteSubmissionWithGroupAndQuote,
} from '../../../../services/forms/models/quoteTypes';
import { delay } from '../utils';

export type QuoteSubmissionResult = {
  quoteSubmissionId: string;
  applicationId: string;
}; 

export const sendQuote = async ({
  entityId,
  raterName,
  insuranceProduct,
  fetchFn,
}: {
  entityId: string;
  raterName: string;
  insuranceProduct: string;
  fetchFn: typeof fetch;
}): Promise<QuoteSubmissionResult | null> => {
  const url = `apim/metarater/v1/quoting/${entityId}/${raterName}/${insuranceProduct}`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetchFn(url, options);
  if (!response.ok) {
    return null;
  }
  return response.json();
};

export const getQuoteSubmissionsForApplication = async ({
  applicationId,
  fetchFn,
}: {
  applicationId: string;
  fetchFn: typeof fetch;
}): Promise<QuoteSubmissionWithGroupAndQuote[] | null> => {
  const url = `apim/metarater/v1/application/${applicationId}/quoteSubmission?withQuoteGroups=true&withQuotes=true`;
  const response = await fetchFn(url);
  if (response.status === 404 || !response.ok) {
    return null;
  }
  return response.json();
};

export const getQuoteGroupsForSubmission = async ({
  applicationId,
  quoteSubmissionId,
  fetchFn,
}: QuoteGroupsForSubmissionParams): Promise<QuoteGroupWithQuote[] | null> => {
  const url = `apim/metarater/v1/application/${applicationId}/quoteSubmission/${quoteSubmissionId}/quoteGroup?withQuotes=true`;
  const response = await fetchFn(url);
  if (response.status === 404 || !response.ok) {
    return null;
  }
  return response.json();
};

export interface QuoteGroupsForSubmissionParams {
  applicationId: string;
  quoteSubmissionId: string;
  fetchFn: typeof fetch;
}
const MAX_RETRIES = 20;
const RETRY_WAIT = 1000;

export const checkQuoteGroupsStatus = async (
  data: QuoteGroupsForSubmissionParams,
  maxRetry = MAX_RETRIES,
): Promise<QuoteGroupWithQuote[] | null> => {
  try {
    let allCompleted = false;
    let retries = 0;
    let quoteGroups = null;
    while (!allCompleted && retries < maxRetry) {
      await delay(RETRY_WAIT);

      quoteGroups = await getQuoteGroupsForSubmission(data);
      allCompleted = quoteGroups?.length
        ? quoteGroups.every(
            (quoteGroup) =>
              quoteGroup?.status === 'Completed' && (quoteGroup?.raterData || quoteGroup?.errors)
          )
        : false;

      if (!allCompleted) {
        retries += 1;
      }
    }
    
    if (!allCompleted) {
      throw new Error('Quote group status check exceeded maximum retries');
    }
    
    return quoteGroups;
  } catch (error) {
    console.log('Checking quote group status error', error);
    throw error;
  }
};
