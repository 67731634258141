import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

export const Logout = (): JSX.Element | null => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  instance.logoutRedirect();
  navigate('/');
  return null;
};
