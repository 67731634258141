import { useEffect, useState } from 'react';
import Button from '../../../../components/shared/button';
import Dialog from '../../../../components/shared/dialog';
import { Document } from '../../models/document';
import { saveName } from '../../services/documentService';
import TextField from '../../../../components/shared/textField';
import { useAuthenticatedFetch } from '../../../../components/common/useAuthenticatedFetch';

export default function Rename({
  selectedDocument,
  open,
  closeDialog,
  showAlertToast,
  deselectAll,
  refreshData,
  updateField,
}: {
  selectedDocument: Document;
  open: boolean;
  closeDialog: any;
  showAlertToast: any;
  deselectAll: () => void;
  refreshData: () => void;
  updateField: (
    documentId: string,
    field: 'name' | 'policy' | 'category' | 'note',
    value: any
  ) => void;
}) {
  const [name, setName] = useState<string>('');
  const authedFetch = useAuthenticatedFetch();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setName(selectedDocument.fileOriginalName);
    }
  }, [open]);

  const handleSave = () => {
    setLoading(true);
    saveName(selectedDocument, name, authedFetch)
      .then((result: Document) => {
        closeDialog();
        showAlertToast('success', `File name changed to: "${name}"`);
        deselectAll();
        updateField(selectedDocument.id, 'name', name);
        refreshData();
      })
      .catch(() => {
        showAlertToast('warning', `Error renaming file`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={open}>
      <div className="flex justify-between items-center">
        <h5 className="text-light-text-primary dark:text-dark-text-primary">
          Rename
        </h5>
        <span
          className="material-symbols-outlined font26 text-light-text-tertiary dark:text-dark-text-tertiary cursor-pointer"
          onClick={closeDialog}
        >
          close
        </span>
      </div>
      <div className="my-6 flex flex-col gap-6">
        <TextField
          label={'File name'}
          value={name}
          onChange={(value: string) => setName(value)}
        />
      </div>
      <div className="flex justify-end gap-3">
        <Button
          callback={handleSave}
          type={'primary'}
          disabled={!name}
          loading={loading}
        >
          Save
        </Button>
        <Button callback={closeDialog} type={'secondary'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
