import { useState } from 'react';
import Button from '../../../../components/shared/button';
import VerticalDivider from '../../../../components/shared/verticalDivider';
import Email from '../dialogs/email';
import Rename from '../dialogs/rename';
import Edit from '../dialogs/edit';
import Recategorize from '../dialogs/recategorize';
import Link from '../dialogs/link';
import { Document } from '../../models/document';
import { Customer } from '../../models/customer';
import { Policy } from '../../models/policy';
import { Contact } from '../../models/contact';
import Tooltip from '../../../../components/shared/tooltip';
import { documentConfig } from '../../../../components/common/config';

const dialogTypes = {
  EMAIL: 'email',
  RENAME: 'rename',
  EDIT_NOTE: 'note',
  RE_CATEGORIZE: 'categorize',
  CHANGE_POLICY: 'policy',
};

export default function ActionBar({
  customer,
  userId,
  userName,
  selectedDocuments,
  showAlertToast,
  policyList,
  categoryList,
  associatedContactList,
  deselectAll,
  refreshData,
  updateField,
}: {
  customer: Customer;
  userId: string;
  userName: string;
  selectedDocuments: Document[];
  showAlertToast: any;
  policyList: Policy[];
  categoryList: string[];
  associatedContactList: Contact[];
  deselectAll: () => void;
  refreshData: () => void;
  updateField: (
    documentId: string,
    field: 'name' | 'policy' | 'category' | 'note',
    value: any
  ) => void;
}) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string>('');

  const showDialog = (type: string) => {
    setDialogType(type);
    setDialogOpen(true);
  };

  const getSelectedDocumentListSize = () => {
    return selectedDocuments
      .map((document: Document) => document.size)
      .reduce((result: number, value: number) => result + value, 0);
  };

  const anyDocumentMissingCategory = () => {
    return selectedDocuments
      .map((document: Document) => document.documentType === '')
      .reduce((result: boolean, value: boolean) => result || value, false);
  };

  const getEmailTooltipMessage = () => {
    if (anyDocumentMissingCategory()) {
      return `Before emailing, please categorize: 
${selectedDocuments
  .filter((document: Document) => document.documentType === '')
  .map((document: Document) => ' •  ' + document.fileOriginalName)
  .join('\n')}`;
    } else if (
      getSelectedDocumentListSize() > parseInt(documentConfig.maxEmailSize)
    ) {
      return `We unfortunately can't send that many attachments. 
Please de-select some and try again.`;
    } else {
      return '';
    }
  };

  const getDialogContent = () => {
    switch (dialogType) {
      case dialogTypes.EMAIL:
        return (
          <Email
            customer={customer}
            selectedDocumentList={selectedDocuments}
            associatedContactList={associatedContactList}
            open={dialogOpen}
            closeDialog={() => setDialogOpen(false)}
            showAlertToast={showAlertToast}
            deselectAll={deselectAll}
          />
        );
      case dialogTypes.RENAME:
        return (
          <Rename
            selectedDocument={selectedDocuments[0]}
            open={dialogOpen}
            closeDialog={() => setDialogOpen(false)}
            showAlertToast={showAlertToast}
            deselectAll={deselectAll}
            refreshData={refreshData}
            updateField={updateField}
          />
        );
      case dialogTypes.EDIT_NOTE:
        return (
          <Edit
            selectedDocumentList={selectedDocuments}
            open={dialogOpen}
            closeDialog={() => setDialogOpen(false)}
            showAlertToast={showAlertToast}
            deselectAll={deselectAll}
            refreshData={refreshData}
            updateField={updateField}
          />
        );
      case dialogTypes.RE_CATEGORIZE:
        return (
          <Recategorize
            selectedDocumentList={selectedDocuments}
            open={dialogOpen}
            closeDialog={() => setDialogOpen(false)}
            category={selectedDocuments[0].documentType}
            categoryList={categoryList}
            showAlertToast={showAlertToast}
            deselectAll={deselectAll}
            refreshData={refreshData}
            updateField={updateField}
          />
        );
      case dialogTypes.CHANGE_POLICY:
        return (
          <Link
            selectedDocumentList={selectedDocuments}
            open={dialogOpen}
            closeDialog={() => setDialogOpen(false)}
            linkedPolicyList={selectedDocuments[0].links?.policy || []}
            allPolicyList={policyList}
            showAlertToast={showAlertToast}
            deselectAll={deselectAll}
            refreshData={refreshData}
            userId={userId}
            userName={userName}
            updateField={updateField}
          />
        );
      default:
        return;
    }
  };

  if (selectedDocuments.length === 0) {
    return;
  }

  return (
    <>
      <div className="w-[calc(100%-40px)] rounded-[4px] px-3 flex items-center gap-[6px] font-medium shadow bottom-[20px] fixed bg-light-surfaces-primary dark:bg-dark-surfaces-primary">
        <div className="px-[10px] py-[12px] w-[140px] text-center font-bold">{`${
          selectedDocuments.length > 0 ? selectedDocuments.length : 'No'
        } ${selectedDocuments.length !== 1 ? 'files' : 'file'} selected`}</div>
        <VerticalDivider />
        <div className="px-[10px] py-[12px]">
          <Tooltip
            message={getEmailTooltipMessage()}
            disabled={
              !(
                getSelectedDocumentListSize() >
                  parseInt(documentConfig.maxEmailSize) ||
                anyDocumentMissingCategory()
              )
            }
          >
            <Button
              callback={() => showDialog(dialogTypes.EMAIL)}
              type={'text'}
              icon={'forward_to_inbox'}
              disabled={
                getSelectedDocumentListSize() >
                  parseInt(documentConfig.maxEmailSize) ||
                anyDocumentMissingCategory()
              }
            >
              Email
            </Button>
          </Tooltip>
        </div>
        <VerticalDivider />
        <div className="px-[10px] py-[12px]">
          <Button
            callback={() => showDialog(dialogTypes.RENAME)}
            type={'text'}
            icon={'drive_file_rename_outline'}
            disabled={selectedDocuments.length !== 1}
          >
            Rename
          </Button>
          <Button
            callback={() => showDialog(dialogTypes.EDIT_NOTE)}
            type={'text'}
            icon={'sticky_note_2'}
            disabled={selectedDocuments.length === 0}
          >
            Edit note
          </Button>
          <Button
            callback={() => showDialog(dialogTypes.RE_CATEGORIZE)}
            type={'text'}
            icon={'label'}
            disabled={selectedDocuments.length === 0}
          >
            Change category
          </Button>
          <Button
            callback={() => showDialog(dialogTypes.CHANGE_POLICY)}
            type={'text'}
            icon={'link'}
            disabled={selectedDocuments.length === 0}
          >
            Link to...
          </Button>
        </div>
      </div>
      {getDialogContent()}
    </>
  );
}
