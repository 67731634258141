import AlertToast, {
  AlertToast as AlertToastInterface,
} from '../../../../components/shared/alertToast';
import { Form } from '../../models/form';
import ControlBar from './../controlBar';
import FormList from '../formList/FormList';
import Button from '../../../../components/shared/button';
import Email from './../dialogs/EmailController';
import { PolicyKeySet } from './types';
import { useFormContext } from '../form/FormContext';

interface Props {
  alertToastOpen: boolean;
  dialogOpen: boolean;
  setDialogOpen(isOpen: boolean): void;
  alertToast: AlertToastInterface | undefined;
  closeAlertToast(): void;
  showAlertToast(type: string, text: string): void;
}

const FormView: React.FC<Props> = ({
  alertToastOpen,
  setDialogOpen,
  showAlertToast,
  dialogOpen,
  alertToast,
  closeAlertToast,
}) => {
  const {
    selectedPolicyKeys,
    selectedFormListKeys,
    forms,
    associatedContactList,
  } = useFormContext();

  const formCount = forms.length;

  const hasSelectedItems =
    selectedFormListKeys.length !== 0 || selectedPolicyKeys.length !== 0;
  const hasMultipleSelectedItems =
    selectedFormListKeys.length + selectedPolicyKeys.length > 1;

  const selectedForms: Form[] = forms.filter((form) =>
    selectedFormListKeys.includes(form.key)
  );

  const selectedPolicyKeyListWithFormNames: PolicyKeySet[] =
    selectedPolicyKeys.map((policyKeyItem) => {
      const correspondingForm = forms.find(
        (form) => form.key === policyKeyItem.formKey
      );
      if (correspondingForm) {
        let formName = correspondingForm.name;
        if (correspondingForm.policies && correspondingForm.policies.length) {
          const correspondingPolicy = correspondingForm?.policies.find(
            (policy) => policy.policyId === policyKeyItem.policyKey
          );
          if (correspondingPolicy) {
            formName = `${formName} |  ${correspondingPolicy?.carrier.name} | ${correspondingPolicy?.policyNumber}`;
          }
        }
        policyKeyItem.formName = formName;
      }
      return policyKeyItem;
    });

  return (
    <div className="font-segoe w-full h-[calc(100%-5rem)] p-[20px] text-light-text-primary dark:text-dark-text-primary bg-light-surfaces-subtle dark:bg-dark-surfaces-subtle flex flex-col gap-[24px] absolute">
      <ControlBar formCount={formCount} />
      <FormList />
      <div>
        <Button
          type={'square-primary'}
          callback={() => setDialogOpen(true)}
          disabled={!hasSelectedItems}
          classes="font-segoe font-semibold"
        >
          Send
          {hasSelectedItems &&
            ` ${selectedFormListKeys.length + selectedPolicyKeys.length} Form${
              hasMultipleSelectedItems ? 's' : ''
            }`}
        </Button>
      </div>
      {alertToast && (
        <AlertToast
          open={alertToastOpen}
          closeAlert={closeAlertToast}
          type={alertToast.type}
          text={alertToast.text}
        />
      )}
      <Email
        selectedFormList={selectedForms}
        selectedPolicyKeys={selectedPolicyKeyListWithFormNames}
        associatedContactList={associatedContactList}
        open={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        showAlertToast={showAlertToast}
      />
    </div>
  );
};

export default FormView;
