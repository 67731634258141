import { useAuthenticatedFetch } from '../../../../components/common/useAuthenticatedFetch';
import { useEffect, useState } from 'react';
import { getQuoteSubmissionsForApplication } from '../../services/network/quote';
import { QuoteGroupWithQuote } from '../../../../services/forms/models/quoteTypes';

export const useQuoteSubmissionForApplication = ({
  loading,
  applicationId,
}: {
  loading: boolean;
  applicationId?: string;
}) => {
  const authedFetch = useAuthenticatedFetch();

  const [completedQuoteGroups, setCompletedQuoteGroups] = useState<
    QuoteGroupWithQuote[]
  >([]);

  const loadQuoteSubmission = async (applicationId: string) => {
    const quoteSubmissions = await getQuoteSubmissionsForApplication({
      applicationId,
      fetchFn: authedFetch,
    });
    const quoteGroups: QuoteGroupWithQuote[] = [];
    (quoteSubmissions || []).forEach((quoteSubmission) => {
      quoteGroups.push(...quoteSubmission.quoteGroups);
    });

    const completed = quoteGroups.filter(
      (quoteGroup) => quoteGroup.status === 'Completed' && quoteGroup.raterData
    );
    if (completed.length) {
      setCompletedQuoteGroups(completed);
    }
  };
  
  useEffect(() => {
    if (!loading && applicationId) {
      loadQuoteSubmission(applicationId);
    }
  }, [loading, applicationId]);

  return { completedQuoteGroups, setCompletedQuoteGroups };
};
