import React, { Component, ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
  errorContext?: string;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = { hasError: false };

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo, this.props.errorContext);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="text-light-text-primary dark:text-dark-text-primary">
          An error occurred when loading this data
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
