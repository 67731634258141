import { Form } from "../../models/form";
import { Policy } from "../../models/policy";
import { PolicyKeySet } from "./types";


const formIncludesPolicy = (form: Form, policy: Policy) => {
    return form.metadata?.linesOfBusiness?.includes(policy.lineOfBusiness)
}


// TODO: make this work without mutating
export const combineFormsAndPolicies = (forms: Form[], policies: Policy[]): Form[] => {
    for (const form of forms) {
        form.policies = [];
        for (const policy of policies) {
            if (formIncludesPolicy(form,policy)) {
                form.policies.push(policy);
            }
        }
    }
    return forms
}

export const policyIsSelected = (policyID:string, formKey: string,currentSelectedList: PolicyKeySet[]): boolean => {
    const formIsSelected = currentSelectedList.some((selectedKeySet) => {
        return policyID === selectedKeySet.policyKey && selectedKeySet.formKey === formKey
    })
    return formIsSelected
}