import { useMemo, useRef, useState } from 'react';
import { Customer } from '../../models/customer';
import Button from '../../../../components/shared/button';
import Search from './search';
import AutoPull from '../dialogs/autoPull';
import { Policy } from '../../models/policy';
import CarrierButton from '../../../../components/shared/carrierButton';
import Tooltip from '../../../../components/shared/tooltip';
import { maxUploadSize } from '../../../../components/common/config';

export default function ControlBar({
  customer,
  policyList,
  categoryList,
  fileCount,
  showAutoPullMessage,
  setUploadFiles,
  showUploadDialog,
  search,
  setSearch,
  autoPullDisabled,
  refreshData,
  showAlertToast,
}: {
  customer: Customer;
  policyList: Policy[];
  categoryList: string[];
  fileCount: number;
  showAutoPullMessage: any;
  setUploadFiles: any;
  showUploadDialog: any;
  search: string;
  setSearch: any;
  autoPullDisabled: boolean;
  refreshData: () => void;
  showAlertToast: (type: string, message: string) => void;
}) {
  const fileInputRef: { current: any } = useRef(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const showFilePicker = () => {
    fileInputRef.current.value = null;
    fileInputRef.current.click();
  };

  const handleFileSelect = (event: any) => {
    const selectedFileSize = event.target.files[0].size;
    if (selectedFileSize > maxUploadSize) {
      showAlertToast(
        'Error',
        `File is too big. Max size is ${parseInt(maxUploadSize) / 1000 / 1000}Mb.`
      );
      return;
    }
    setUploadFiles(event.target.files);
    showUploadDialog();
  };

  const customerName = useMemo(() => {
    if (customer.firstName && customer.lastName) {
      return `${customer.firstName} ${customer.lastName}`;
    } else if (customer.businessName) {
      return customer.businessName;
    } else {
      return 'customer';
    }
  }, [customer]);

  return (
    <>
      <div className="flex gap-[20px] items-center justify-between">
        <h4 className="font-bold">{`${
          fileCount > 0 ? fileCount : `No${search ? ' matching' : ''}`
        } documents for ${customerName}`}</h4>
        <div className="flex gap-3">
          <Tooltip
            message={`We can't automatically get the latest documents for this customer.
Please download them from the carrier and upload them here.`}
            disabled={!autoPullDisabled}
          >
            <Button
              callback={() => setDialogOpen(true)}
              type={'secondary'}
              icon={'auto_awesome'}
              disabled={autoPullDisabled}
            >
              Get latest
            </Button>
          </Tooltip>
          <CarrierButton type={'secondary'} icon={'open_in_new'} policyList={policyList}>
            Go to carrier
          </CarrierButton>
          <Button callback={showFilePicker} type={'secondary'} icon={'upload_file'}>
            Add to Fusion Docs
          </Button>
          <input
            type="file"
            className="hidden"
            ref={fileInputRef}
            onChange={handleFileSelect}
            accept="image/*, video/*, .doc, .docx, .pdf, .xml, .txt, .csv, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
          <Search search={(event: any) => setSearch(event.target.value)} searchValue={search} />
        </div>
      </div>
      <AutoPull
        open={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        showAutoPullMessage={showAutoPullMessage}
        policyList={policyList}
        refreshData={refreshData}
      />
    </>
  );
}
