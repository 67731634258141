import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const hasPlaceHolders = (url: string): boolean => {
  const regex = /{[^}]+}/g;
  return regex.test(url);
};

// MVP implementation: Only watch fields on same level as input in data model
const getDynamicKey = (fieldName: string, placeholder: string) =>
  fieldName.includes('.')
    ? fieldName.slice(0, fieldName.lastIndexOf('.') + 1) + placeholder
    : placeholder;

const replacePlaceholders = (
  fieldName: string,
  url: string,
  getValues: (key: string) => string
) => {
  return url.replace(/{(\w+)}/g, (_, placeholder: string) => {
    // decide if use key, or if watching dynamic item
    const dynamicKey = getDynamicKey(fieldName, placeholder);
    const value = getValues(dynamicKey);
    if (value) return value;
    return `{${placeholder}}`;
  });
};

// also should pass in field.name
const useGetDynamicUrl = (optionsByUrl: string, fieldName: string) => {
  // dynamically get watch field paths: default for mvp is same level
  const { watch, getValues } = useFormContext();
  const values = watch();

  const [isDynamicUrlComplete, setIsDynamicUrlComplete] = useState(false);
  const [dynamicUrl, setDynamicUrl] = useState(optionsByUrl);

  useEffect(() => {
    // if original is not dynamic
    if (!hasPlaceHolders(optionsByUrl)) {
      setIsDynamicUrlComplete(true);
      return;
    }

    // always run replace off original url so placeholders are not lost
    const updatedUrl = replacePlaceholders(fieldName, optionsByUrl, getValues);

    if (hasPlaceHolders(updatedUrl)) {
      setIsDynamicUrlComplete(false);
      setDynamicUrl(updatedUrl);
      return;
    }

    if (updatedUrl === dynamicUrl) {
      // nothing changed related to this dropdown
      return;
    }

    setDynamicUrl(updatedUrl);
    setIsDynamicUrlComplete(true);
  }, [values, getValues, dynamicUrl, fieldName, optionsByUrl]);

  return { isDynamicUrlComplete, dynamicUrl };
};

export { useGetDynamicUrl };
