'use client';

import DocumentTable from './documentTable';
import {
  getAccountDetails,
  getPolicyList,
  getDocuments,
  getCategoryList,
  getAssociatedContactList,
} from '../../services/documentService';
import { getJobList } from '../../services/jobOrchestrationService';
import { Alert, Document } from '../../models/document';
import { useEffect, useState, useMemo } from 'react';
import ControlBar from './controlBar';
import { Customer } from '../../models/customer';
import Upload from '../dialogs/upload';
import AlertBanner from '../../../../components/shared/alertBanner';
import ActionBar from './actionBar';
import UploadOverlay from './uploadOverlay';
import AlertToast, {
  AlertToast as AlertToastInterface,
} from '../../../../components/shared/alertToast';
import { Policy } from '../../models/policy';
import { TableTextInterface } from '../../../../components/shared/table';
import { useMsal } from '@azure/msal-react';
import {
  callCrmWhoAmI,
  getAccessToken,
} from '../../../../components/common/utils';
import { WhoAmIResponse } from '../../models/whoAmIResponse';
import { useAuthenticatedFetch } from '../../../../components/common/useAuthenticatedFetch';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useLocation } from 'react-router-dom';
import { Contact } from '../../models/contact';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function Documents({
  account,
  pca,
}: {
  account: string;
  pca: IPublicClientApplication;
}) {
  const queryParams = useQuery();
  const { instance, inProgress } = useMsal();
  const [userName, setUserName] = useState<string>('');
  const [accessToken, setAccessToken] = useState('');
  const [crmData, setCrmData] = useState<WhoAmIResponse | undefined>();

  const [documents, setDocuments] = useState<Document[]>();
  const [documentsLoading, setDocumentsLoading] = useState<boolean>(false);
  const [jobs, setJobs] = useState<any[]>();
  const [filteredDocuments, setFilteredDocuments] = useState<Document[]>();
  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);

  const [customer, setCustomer] = useState<Customer>({} as Customer);
  const [associatedContactList, setAssociatedContactList] = useState<Contact[]>(
    []
  );
  const [policyList, setPolicyList] = useState<Policy[]>([]);
  const [rawPolicyList, setRawPolicyList] = useState<Policy[]>([]);
  const [categoryList, setCategoryList] = useState<string[]>([]);

  const [autoPullDisabled, setAutoPullDisabled] = useState<boolean>(false);

  const [uploadOverlayOpen, setUploadOverlayOpen] = useState<boolean>(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = useState<any>();

  const [search, setSearch] = useState<string>(queryParams.get('search') || '');

  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alert, setAlert] = useState<Alert>({
    title: '',
    body: '',
    type: 'info',
    allowDismiss: true,
  });
  const [alertToastOpen, setAlertToastOpen] = useState<boolean>(false);
  const [alertToast, setAlertToast] = useState<AlertToastInterface>({
    text: '',
    type: 'info',
  });

  const [tableText, setTableText] = useState<TableTextInterface>({
    text: '',
    subText: '',
  });
  const authedFetch = useAuthenticatedFetch();

  useEffect(() => {
    if (account) {
      refreshData();
    }
  }, [account]);

  useEffect(() => {
    if (!accessToken) {
      getAccessToken(instance)
        .then((token) => setAccessToken(token))
        .catch((err) => console.error('failed to get access token', err));
    }
    if (!userName) {
      setUserName(instance.getActiveAccount()?.name || '');
    }
  }, [instance, accessToken, setAccessToken, userName]);

  useEffect(() => {
    if (!crmData && accessToken) {
      callCrmWhoAmI(accessToken)
        .then((response) => setCrmData(response))
        .catch((err) => console.error('failed to fetch crm data', err));
    }
  }, [inProgress, crmData, accessToken]);

  useEffect(() => {
    const searchFilter = (document: Document) => {
      const loweredSearch = search?.toLowerCase();
      return (
        document.createdAt?.includes(loweredSearch) ||
        document.fileOriginalName?.toLowerCase()?.includes(loweredSearch) ||
        document.documentType?.toLowerCase()?.includes(loweredSearch) ||
        policyNumberFilter(document.links?.policy, loweredSearch) ||
        document.createdBy?.name?.toLowerCase()?.includes(loweredSearch) ||
        document.term?.toLowerCase()?.includes(loweredSearch) ||
        document.notes?.toLowerCase()?.includes(loweredSearch)
      );
    };

    const policyNumberFilter = (
      linkedPolicyList: Policy[] = [],
      loweredSearch: string
    ): boolean => {
      let match = false;
      linkedPolicyList
        .map((linkedPolicy: Policy) => linkedPolicy.policyNumber)
        .forEach((policyNumber: string) => {
          match = match || policyNumber.toLowerCase().includes(loweredSearch);
        });
      return match;
    };

    const applyFilters = () => {
      const documentList = documents?.filter((document: Document) => {
        return searchFilter(document);
      });
      setFilteredDocuments(documentList);
    };

    applyFilters();

    if (documentsLoading) {
      setTableText({
        text: `Loading documents...`,
        subText: '',
        showSpinner: true,
      });
    } else if (documents?.length) {
      setTableText({
        text: `We couldn't find a matching document...`,
        subText: 'Try getting the latest document from the carrier.',
      });
    } else {
      setTableText({ text: `Looks empty here...`, subText: '' });
    }
  }, [search, documents, documentsLoading, setTableText]);

  useEffect(() => {
    if (documents) {
      setSelectedDocuments(
        documents.filter((document: Document) => !!document.selected)
      );
    }
  }, [documents]);

  useEffect(() => {
    getJobList(authedFetch).then((result) => {
      setJobs(result);
    });
  }, []);

  useEffect(() => {
    if (jobs && rawPolicyList) {
      const mappedPolicies = mapJobsToPolicies(rawPolicyList, jobs);
      setPolicyList(mappedPolicies);
    }
  }, [jobs, rawPolicyList]);

  const refreshData = () => {
    getAccountDetails(account, authedFetch).then((result: Customer) => {
      setCustomer(result);
    });

    getAssociatedContactList(account, authedFetch).then((result: Contact[]) => {
      setAssociatedContactList(result);
    });

    setDocumentsLoading(true);
    getDocuments(account, authedFetch)
      .then((result: Document[]) => {
        if (result) {
          setDocuments(result);
          setFilteredDocuments(result);
        }
      })
      .finally(() => {
        setDocumentsLoading(false);
      });

    getPolicyList(account, authedFetch).then((policies: Policy[]) => {
      setRawPolicyList(policies);
    });

    getCategoryList(authedFetch).then((result: string[]) => {
      setCategoryList(result);
    });
  };

  const updateField = (
    documentId: string,
    field: 'name' | 'policy' | 'category' | 'note',
    value: any
  ) => {
    setDocuments((prevState: any) => {
      const documentsCopy = [...prevState];
      documentsCopy.forEach((document: Document) => {
        if (document.id === documentId) {
          switch (field) {
            case 'name':
              document.fileOriginalName = value;
              break;
            case 'policy':
              document.loading = value;
              break;
            case 'category':
              document.documentType = value;
              break;
            case 'note':
              document.notes = value;
              break;
          }
        }
      });
      return documentsCopy;
    });
  };

  const dropHandler = (event: any) => {
    event.preventDefault();

    setUploadFiles(event.dataTransfer.files);

    showUploadDialog();
  };

  const showUploadDialog = () => {
    setUploadOverlayOpen(false);
    setUploadDialogOpen(true);
  };

  const deselectAll = () => {
    setDocuments((prevState: any) => {
      const documentListCopy = [...prevState];
      documentListCopy.forEach((document: Document) => {
        document.selected = false;
      });
      return documentListCopy;
    });
  };

  const showAutoPullMessage = (type: string) => {
    if (type === 'info') {
      setAlert({
        title: 'Coming right up!',
        body: `We're getting the document you requested from the carrier. This page will auto-refresh once it's ready (it might take a few minutes).`,
        type: 'info',
        allowDismiss: false,
      });
      setAutoPullDisabled(true);
      setAlertOpen(true);
    } else if (type === 'none') {
      setAutoPullDisabled(false);
      setAlertOpen(false);
    } else {
      setAlert({
        title: `Oops, we couldn't pull that document`,
        body: `Please download it from the carrier's website and upload it here.`,
        type: 'warning',
        allowDismiss: true,
      });
      setAutoPullDisabled(false);
      setAlertOpen(true);
    }
  };

  const showAlertToast = (type: string, text: string) => {
    setAlertToast({
      type: type,
      text: text,
    });

    setAlertToastOpen(true);
  };

  const mapJobsToPolicies = (policies: Policy[], jobs: any[]) => {
    setAutoPullDisabled(true);
    policies.forEach((policy: Policy) => {
      policy.jobs = jobs.filter(
        (job) =>
          job.jobInfo?.carrierIds?.includes(policy.carrier?.id) &&
          job.jobInfo?.linesOfBusiness?.includes(policy.lineOfBusiness)
      );
      if (policy.jobs.length > 0) {
        setAutoPullDisabled(false);
      }
    });

    return policies;
  };

  return (
    <div
      className="w-full h-[calc(100%-5rem)] p-[20px] text-light-text-primary dark:text-dark-text-primary flex flex-col gap-10 absolute"
      onDrop={dropHandler}
      onDragOver={(event: any) => {
        if (!uploadDialogOpen) {
          event.preventDefault();
          setUploadOverlayOpen(true);
        }
      }}
    >
      <ControlBar
        customer={customer}
        policyList={policyList}
        categoryList={categoryList}
        fileCount={filteredDocuments?.length || 0}
        showAutoPullMessage={showAutoPullMessage}
        search={search}
        setSearch={setSearch}
        setUploadFiles={setUploadFiles}
        showUploadDialog={showUploadDialog}
        autoPullDisabled={autoPullDisabled}
        refreshData={refreshData}
        showAlertToast={showAlertToast}
      />
      <AlertBanner
        open={alertOpen}
        closeAlert={() => setAlertOpen(false)}
        type={alert.type}
        title={alert.title}
        body={alert.body}
        allowDismiss={alert.allowDismiss}
      />
      <DocumentTable
        documents={filteredDocuments}
        setSelectedDocuments={setSelectedDocuments}
        tableText={tableText}
        showAlertToast={showAlertToast}
      />
      <ActionBar
        customer={customer}
        userId={crmData?.UserId || ''}
        userName={userName}
        selectedDocuments={selectedDocuments}
        showAlertToast={showAlertToast}
        policyList={policyList}
        categoryList={categoryList}
        associatedContactList={associatedContactList}
        deselectAll={deselectAll}
        refreshData={refreshData}
        updateField={updateField}
      />
      <AlertToast
        open={alertToastOpen}
        closeAlert={() => setAlertToastOpen(false)}
        type={alertToast.type}
        text={alertToast.text}
      />
      <Upload
        open={uploadDialogOpen}
        closeDialog={() => setUploadDialogOpen(false)}
        files={uploadFiles}
        categoryList={categoryList}
        policyList={policyList}
        accountId={customer.accountId}
        showAlertToast={showAlertToast}
        refreshData={refreshData}
        pca={pca}
        userName={userName}
      />
      <UploadOverlay
        open={uploadOverlayOpen}
        onClose={(event: any) => {
          event.preventDefault();
          setUploadOverlayOpen(false);
        }}
      />
    </div>
  );
}
