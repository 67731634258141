import { ConditionalConfig } from '@/services/forms/models/formTypes';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';

const useVisible = (visibleIf: ConditionalConfig | undefined) => {
  const watchedValue = useWatch({ name: visibleIf?.fieldName || ''});

  const [visible, setVisible] = useState<boolean>(!visibleIf?.fieldName || !!watchedValue);

  useEffect(() => {
    if (visibleIf?.fieldName) {
      if (visibleIf?.includeList) {
        setVisible(visibleIf.includeList.includes(watchedValue));
      } else if (visibleIf?.excludeList) {
        setVisible(!visibleIf.excludeList.includes(watchedValue) && !!watchedValue);
      }
    }
  }, [watchedValue]);

  return visible;
};

export { useVisible };
