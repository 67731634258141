export default function UploadOverlay({ open, onClose }: { open: boolean; onClose: any }) {
  if (!open) {
    return;
  }
  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-light-surfaces-info bg-opacity-40 backdrop-blur-md flex flex-col justify-center items-center"
      onDragLeave={onClose}
    >
      <h1 className="material-symbols-outlined font200 text-light-text-primary">file_upload</h1>
      <h1 className="text-light-text-primary font-medium">Drop file to upload.</h1>
    </div>
  );
}
