/**
 * Displays a customizable banner style alert message.
 *
 * @param {Object} config - The configuration object for this component.
 * @param {boolean} config.open - Flag indicating whether the alert should be displayed or not.
 * @param {function} config.closeAlert -The function to call to hide the alert.
 * @param {string} [config.type] - Which type of alert should be displayed. Possible options are 'warning' or 'info'. 'info' is default.
 * @param {string} config.title - The header text for the alert.
 * @param {string} config.body - The body text for the alert.
 * @param {boolean} config.allowDismiss - Flag indicating whether the close button should be displayed.
 *
 * @example
 * <AlertBanner open={alertOpen} closeAlert={() => setAlertOpen(false)} type={'info'} title={'Coming right up!'} body={'We`re getting the document you requested.'} />
 */
export default function AlertBanner({
  open,
  closeAlert,
  title,
  body,
  type,
  allowDismiss,
}: {
  open: boolean;
  closeAlert: any;
  title: string;
  body: string;
  type: string;
  allowDismiss: boolean;
}) {
  const colors = {
    // default to info
    background: 'bg-light-surfaces-info dark:bg-dark-surfaces-info',
    border: 'border-light-borders-primary dark:border-dark-borders-primary',
    text: 'text-light-text-primary dark:text-dark-text-inverse',
    icon: 'text-light-text-primary dark:text-dark-text-inverse',
  };
  let icon = 'auto_awesome';

  if (type === 'warning') {
    colors.background = 'bg-light-surfaces-error dark:bg-dark-surfaces-error';
    colors.border = 'border-light-borders-error dark:border-dark-borders-error';
    colors.text = 'text-light-text-contrast dark:text-black';
    colors.icon = 'text-light-borders-error dark:text-dark-borders-error';
    icon = 'error_outline';
  }

  if (!open) {
    return;
  }
  return (
    <div
      className={[
        'border rounded-[4px] w-full flex items-center p-[12px]',
        colors.border,
        colors.text,
        colors.background,
      ].join(' ')}
    >
      <span
        className={['material-symbols-outlined pr-[12px] font32', colors.icon].join(' ')}
        onClick={closeAlert}
      >
        {icon}
      </span>
      <div className="grow">
        <div className="font-bold text-base">{title}</div>
        <div className="font-normal text-base">{body}</div>
      </div>
      {allowDismiss && (
        <span
          className={['material-symbols-outlined cursor-pointer', colors.icon].join(' ')}
          onClick={closeAlert}
        >
          close
        </span>
      )}
    </div>
  );
}
