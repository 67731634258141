import { Applicant } from '@bwinsurance/meta-rater-types';
import { ReactNode } from 'react';
import DisplayValue, { DisplayValueOptional } from '../DisplayValue';
import Section from '../Section';
import { getStringAddress } from '../../components/common/utils';
import SubheadingDivider from '../SubheadingDivider';
import ErrorBoundary from '../ErrorBoundary';

export const ApplicantDisplay = ({
  applicant,
  longform = false,
}: {
  applicant?: Applicant | null;
  longform?: boolean;
}): ReactNode => {
  if (!applicant) {
    return null;
  }
  const headingText = `${applicant.firstName ?? ''} ${applicant.lastName ?? ''}`;

  if (!longform) {
    return (
      <Section headingText={headingText} materialIcon="person">
        <SubheadingDivider headingText="General Info">
          <DisplayValue label="Birthdate" value={applicant?.dateOfBirth} isDate />
          <DisplayValueOptional label="Education" value={applicant?.education} />
          <DisplayValueOptional label="Gender" value={applicant?.gender} />
          <DisplayValueOptional label="Marital status" value={applicant?.maritalStatus} />
        </SubheadingDivider>
      </Section>
    );
  }
  return (
    <Section headingText="Applicant" materialIcon="person">
      <SubheadingDivider headingText="Lead Info">
        <DisplayValueOptional label="Business type" value={applicant.businessType} />
        <DisplayValueOptional label="Applicant type" value={applicant.type} />
      </SubheadingDivider>

      <SubheadingDivider headingText="General Info">
        <DisplayValue label="First name" value={applicant.firstName} />
        <DisplayValueOptional label="Middle name" value={applicant.middleName} />
        <DisplayValue label="Last name" value={applicant.lastName} />
        <DisplayValueOptional label="Company name" value={applicant.companyName} />
        <DisplayValueOptional label="Nickname" value={applicant.nickname} />
        <DisplayValueOptional label="Maiden name" value={applicant.maidenName} />
        <DisplayValueOptional label="SSN" value={applicant.socialSecurityNumber} />
        <DisplayValue label="Birthdate" value={applicant?.dateOfBirth} isDate />
        <DisplayValueOptional label="Education" value={applicant?.education} />
        <DisplayValueOptional label="Gender" value={applicant?.gender} />
        <DisplayValueOptional label="Marital status" value={applicant?.maritalStatus} />
        <DisplayValueOptional label="Occupation" value={applicant.occupation} />
        <DisplayValueOptional label="Years with employer" value={applicant.yearsWithEmployer} />
        <DisplayValueOptional
          label="Years with previous employer"
          value={applicant.yearsWithPreviousEmployer}
        />
      </SubheadingDivider>

      <SubheadingDivider headingText="Contact Info">
        <DisplayValue label="Email address" value={applicant.email} />
        <DisplayValueOptional label="Alternate email" value={applicant.alternateEmail} />
        <DisplayValue label="Phone number" value={applicant.mainPhone} />
        <DisplayValueOptional
          label="Current address"
          value={getStringAddress(applicant.currentAddress)}
        />
        <DisplayValueOptional
          label="Previous address"
          value={getStringAddress(applicant.previousAddress)}
        />
        <DisplayValueOptional
          label="Preferred contact method"
          value={applicant.preferredContactMethod}
        />
        <DisplayValueOptional
          label="Preferred contact time"
          value={applicant.preferredContactTime}
        />
      </SubheadingDivider>

      <SubheadingDivider headingText="Driving Record">
        <DisplayValueOptional
          label="Driver license status"
          value={applicant?.driverLicense?.status}
        />
        <DisplayValueOptional
          label="Driver license number"
          value={applicant?.driverLicense?.licenseNumber}
        />
        <DisplayValueOptional label="Driver license state" value={applicant.driverLicense?.state} />
        <DisplayValueOptional
          label="Driver license date"
          value={applicant.driverLicense?.licensedDate}
          isDate
        />
      </SubheadingDivider>
    </Section>
  );
};

export default function WrapperApplicantDisplay(props: {
  applicant?: Applicant | null;
  longform?: boolean;
}): ReactNode {
  return (
    <ErrorBoundary>
      <ApplicantDisplay {...props} />
    </ErrorBoundary>
  );
}
