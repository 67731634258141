import { useState, useEffect } from 'react';
const NavbarId = 'main-navbar';

const useNavbarHeight = () => {
  const [navbarHeight, setNavbarHeight] = useState<number>(0);

  useEffect(() => {
    const navbar = document.getElementById(NavbarId);
    if (navbar) {
      const resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry.target?.id === NavbarId) {
            setNavbarHeight(entry.contentRect.height);
          }
        }
      });

      resizeObserver.observe(navbar);

      return () => {
        resizeObserver.unobserve(navbar);
      };
    } else {
      setNavbarHeight(0);
    }
  }, []);

  return navbarHeight;
};

export default useNavbarHeight;

