import { useFormContext } from '../components/form/FormContext';

interface Props {
  formCount: number;
}


const ControlBar: React.FC<Props> = ({
  formCount,
}) => {
  const { customer } = useFormContext();
  let customerName = 'Loading...'
  if(customer) {
    if(customer.firstName && customer.lastName) {
    customerName = `${customer.firstName} ${customer.lastName}`
    } else {
      customerName = customer.businessName
    }
  }

  let formText = "No forms available"
  if(formCount === 1 ) {
    formText = "1 form available"
  } else if(formCount > 1) {
    formText = `${formCount} forms available`
  }

  return (
      <div className="flex gap-[20px] items-center justify-between">
        <div>
          <h4 className="font-semibold font-segoe text-[20px]">
            {customerName}
          </h4>
          <span className="font-semibold font-segoe text-[16px] text-light-text-secondary dark:text-dark-text-secondary">
            {formText}
          </span>
        </div>
        <div className="flex gap-3">{/* space for other controls */}</div>
      </div>
  );
}


export default ControlBar