import { ReactNode } from 'react';

interface IfProps {
  condition: boolean;
  children: ReactNode;
}

const If = ({ condition, children }: IfProps): ReactNode => {
  if (condition) {
    return children;
  }
  return null;
};

export default If;
