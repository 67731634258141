import './Header.module.css';

const Header = (): JSX.Element => {
  return (
    <header
      id="main-navbar"
      className="sticky flex justify-center border-b bg-primary text-white z-50"
    >
      <nav className="flex items-center justify-between w-full h-16 max-w-7xl px-4 mx-auto sm:px-6">
        <a className="flex items-center" href="/">
          <img src="/img/bw_yellow_icon.svg" alt="Brightway Insurance" width="48" height="48" />
          <span className="ml-3 pt-1 font-bold color-secondary font-exo text-2xl">FUSION</span>
        </a>
        <ul className="flex">
          <li className="mr-6">
            <a className="" href="/resources/account">
              Accounts
            </a>
          </li>
          <li className="mr-6">
            <a className="" href="/settings">
              <span className="material-symbols-outlined">settings</span>
            </a>
          </li>
          <li className="mr-6">
            <a className="" href="/help">
              <span className="material-symbols-outlined">help</span>
            </a>
          </li>
          <li className="mr-6">
            <a className="" href="/profile">
              <span className="material-symbols-outlined">account_circle</span>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
