import type { FormDriverDetails, FormPolicy, FormPriorPolicy } from './helpers';

export interface FormAuto {
  policy: FormPolicy;
  priorPolicy: FormPriorPolicy;
}

export interface FormDriver extends FormDriverDetails {
  license: {
    licenseNumber?: string;
    state?: string;
    status?: string;
    licensedDate?: string | Date;
  };
  hasLicenseRevokedSuspended?: boolean;
}

export interface FormVehicle {
  year?: string;
  make?: string;
  model?: string;
  subModel?: string;
  usage?: {
    usage?: string;
    principalDriverId?: string;
    oneWayMiles?: string;
    daysPerWeek?: string;
    weeksPerMonth?: string;
    annualMiles?: string;
    ownership?: string;
    odometer?: string;
  };
  vin?: string;
}

export const vehicleDefaults: FormVehicle = {
  year: undefined,
  make: undefined,
  model: undefined,
  subModel: undefined,
  vin: undefined,
  usage: {
    principalDriverId: undefined,
    usage: undefined,
    ownership: undefined,
    odometer: undefined,
    oneWayMiles: undefined,
    daysPerWeek: undefined,
    weeksPerMonth: undefined,
    annualMiles: undefined,
  },
};

export const driverDefaults: FormDriver = {
  name: {
    firstName: undefined,
    lastName: undefined,
  },
  gender: undefined,
  dateOfBirth: undefined,
  education: undefined,
  maritalStatus: undefined,
  relation: undefined,
  employmentStatus: undefined,
  industry: undefined,
  occupation: undefined,
  license: {
    licenseNumber: undefined,
    licensedDate: undefined,
    state: undefined,
    status: undefined,
  },
  hasLicenseRevokedSuspended: undefined,
};
