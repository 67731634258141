import type { FC } from 'react';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { FormField } from '@/services/forms/models/formTypes';
import { FieldError, get, useFormContext, useFormState } from 'react-hook-form';

export type RadioButtonGroupProps = {
  field: FormField;
};

const RadioButtonGroup: FC<RadioButtonGroupProps> = ({ field }) => {
  const { trigger, setValue, watch, register } = useFormContext();
  const { id, name, display, disabled, options = [], highlight } = field;

  const { errors } = useFormState();
  const error: FieldError = get(errors, name);

  const selectedValue = watch(name);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val =
      e.target.value === 'true' ? true : e.target.value === 'false' ? false : e.target.value;

    setValue(name, val, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  const handleOnBlur = () => {
    trigger(name);
  };

  return (
    <div className="flex flex-col min-w-[236px] max-w-[492px] gap-1.5">
      <Label id={id || name}>
        <span className={highlight ? 'bg-yellow-200 px-2' : ''}>{display}</span>
      </Label>
      <div role="radiogroup" aria-labelledby={id || name} className="flex items-center gap-2">
        {options.map((option) => (
          <Label
            key={`${id || name}.${option.key}`}
            className="flex gap-1 py-1.5 text-light-text-contrast dark:text-dark-text-contrast"
          >
            <Input
              {...register(name, { ...(field.validation ? { ...field.validation } : {}) })}
              type={'radio'}
              data-testid={`${id || name}.${option.key}`}
              className="border-2 border-[#8F8F9C]"
              value={option.key}
              onChange={handleOnChange}
              checked={selectedValue?.toString() === option.key}
              onBlur={handleOnBlur}
              disabled={disabled}
            />
            {option.display}
          </Label>
        ))}
      </div>
      {error && <p className="text-sm text-light-text-error">{error.message}</p>}
    </div>
  );
};

export { RadioButtonGroup };
