interface ButtonProps {
  children: any;
  callback?: any;
  type:
    | 'primary-xl'
    | 'primary'
    | 'square-primary'
    | 'secondary-xl'
    | 'secondary'
    | 'square-secondary'
    | 'text'
    | 'link';
  disabled?: boolean;
  icon?: string;
  loading?: boolean;
  classes?: string;
}

/**
 * Displays a customizable styled button.
 *
 * @param {Object} config - The configuration object for this component.
 * @param {HTML} config.children - The text to display in the button.
 * @param {function} [config.callback] - The function to call when the button is clicked.
 * @param {string} config.type - Which type of button to display. Options are 'primary-xl', 'primary', 'square-primary', 'secondary-xl', 'secondary', 'square-secondary', 'text', 'link'
 * @param {boolean} [config.disabled] - Flag indicating if the button is disabled. Default is false.
 * @param {string} [config.icon] - Material symbol icon to display in the button.
 * @param {boolean} [config.loading] - Flag indicating if a loading spinner should be displayed.
 * @param {string} [config.classes] - Any additional tailwind css classes to apply to the button.
 *
 * @example
 * <Button callback={showUploadDialog} type={'secondary'} icon={'upload_file'}>Upload</Button>
 */
export default function Button({
  children,
  callback,
  type,
  disabled = false,
  icon,
  loading = false,
  classes = '',
}: ButtonProps) {
  let typeClass = '';
  if (type === 'primary-xl') {
    typeClass = `border 
            border-light-borders-brand hover:border-light-borders-primary 
            disabled:border-light-borders-secondary
            dark:border-dark-borders-brand hover:dark:border-dark-borders-primary 
            dark:disabled:border-light-borders-secondary
            bg-light-surfaces-brand hover:bg-light-surfaces-primary
            disabled:bg-light-surfaces-primary disabled:hover:bg-light-surfaces-primary
            dark:bg-dark-surfaces-brand hover:dark:bg-dark-surfaces-contrast
            dark:disabled:bg-dark-surfaces-primary dark:disabled:hover:bg-dark-surfaces-primary
            disabled:bg-opacity-40
            dark:disabled:bg-opacity-40
            text-light-text-primary hover:text-light-text-primary
            disabled:text-light-text-tertiary disabled:hover:text-light-text-tertiary
            dark:text-dark-text-primary hover:dark:text-dark-text-inverse
            text-xl rounded-full
            px-[24px] py-[10px]`;
  } else if (type === 'primary') {
    typeClass = `border 
            border-light-borders-primary
            disabled:border-light-borders-secondary
            dark:border-dark-borders-primary
            dark:disabled:border-light-borders-secondary
            bg-light-surfaces-brand hover:bg-light-surfaces-contrast
            disabled:bg-light-surfaces-brand disabled:hover:bg-light-surfaces-brand
            dark:bg-dark-surfaces-brand hover:dark:bg-dark-surfaces-contrast
            dark:disabled:bg-dark-surfaces-brand dark:disabled:hover:bg-dark-surfaces-brand
            disabled:bg-opacity-40 disabled:hover:bg-opacity-40
            dark:disabled:bg-opacity-40 dark:disabled:hover:bg-opacity-40
            text-light-text-primary hover:text-light-text-inverse
            disabled:text-light-text-tertiary disabled:hover:text-light-text-tertiary
            dark:text-dark-text-inverse hover:dark:text-dark-text-inverse
            text-base rounded-full
            px-[24px] py-[10px]`;
  } else if (type === 'square-primary') {
    typeClass = `border
            border-light-borders-primary
            disabled:border-light-borders-secondary
            dark:border-dark-borders-primary
            dark:disabled:border-dark-borders-secondary
            bg-light-surfaces-contrast
            disabled:bg-light-surfaces-contrast
            dark:bg-dark-surfaces-contrast
            dark:disabled:bg-dark-surfaces-contrast
            disabled:bg-opacity-40
            dark:disabled:bg-opacity-40
            text-light-text-inverse
            disabled:text-light-text-inverse
            dark:text-dark-text-inverse
            text-base rounded-[4px]
            px-[12px] py-[8px]`;
  } else if (type === 'secondary-xl') {
    typeClass = `border 
            border-light-borders-primary
            disabled:border-light-borders-secondary
            dark:border-dark-borders-primary
            dark:disabled:border-light-borders-secondary
            bg-light-surfaces-primary hover:bg-light-surfaces-contrast
            disabled:hover:bg-light-surfaces-primary
            dark:bg-dark-surfaces-primary hover:dark:bg-dark-surfaces-contrast
            dark:disabled:hover:bg-dark-surfaces-primary
            text-light-text-primary hover:text-light-text-inverse
            disabled:text-light-text-tertiary disabled:hover:text-light-text-tertiary
            dark:text-dark-text-primary hover:dark:text-dark-text-inverse
            text-xl rounded-full
            px-[24px] py-[10px]`;
  } else if (type === 'secondary') {
    typeClass = `border 
            border-light-borders-primary
            disabled:border-light-borders-secondary
            dark:border-dark-borders-primary
            dark:disabled:border-light-borders-secondary
            bg-light-surfaces-primary hover:bg-light-surfaces-contrast 
            disabled:hover:bg-light-surfaces-primary
            dark:bg-dark-surfaces-primary hover:dark:bg-dark-surfaces-contrast
            dark:disabled:hover:bg-dark-surfaces-primary
            text-light-text-primary hover:text-light-text-inverse 
            disabled:text-light-text-tertiary disabled:hover:text-light-text-tertiary
            dark:text-dark-text-primary hover:dark:text-dark-text-inverse
            text-base rounded-full
            px-[24px] py-[10px]`;
  } else if (type === 'square-secondary') {
    typeClass = `border 
            border-light-borders-primary
            disabled:border-light-borders-secondary
            dark:border-dark-borders-primary
            dark:disabled:border-light-borders-secondary
            bg-light-surfaces-primary
            dark:bg-dark-surfaces-primary
            text-light-text-primary
            disabled:text-light-text-tertiary
            dark:text-dark-text-primary
            text-base rounded-[4px]
            px-[12px] py-[8px]`;
  } else if (type === 'link') {
    typeClass = 'text-light-text-link underline';
  } else {
    // default to text
    typeClass = `hover:bg-light-surfaces-subtle
            disabled:hover:bg-light-surfaces-primary
            dark:hover:bg-dark-surfaces-subtle
            dark:disabled:hover:bg-dark-surfaces-primary
            disabled:text-light-text-tertiary
            dark:disabled:text-dark-text-tertiary
            dark:hover:text-dark-text-inverse
            py-[10px] px-[12px]
            rounded-full`;
  }

  return (
    <button
      className={['font-medium', classes, typeClass].join(' ')}
      onClick={callback ? callback : undefined}
      disabled={disabled || loading}
    >
      {icon && !loading && (
        <span className="material-symbols-outlined pr-3 align-middle mt-[-3px]">
          {icon}
        </span>
      )}
      {loading && (
        <span className="material-symbols-outlined text-[16px] mr-[5px] align-middle animate-spin">
          progress_activity
        </span>
      )}
      {children}
    </button>
  );
}
