type Field = {
  display: string;
  page: string;
};

type Section = {
  elements: { [key: string]: Field };
};

type Page = {
  id: string;
  display: string;
  elements: { [key: string]: Section };
};

const isSectionWithElements = (
  section: Section
): section is { elements: { [key: string]: Field } } => {
  return typeof section !== 'number' && 'elements' in section;
};

const isFieldWithDisplay = (field: Field): field is { display: string; page: string } => {
  return typeof field !== 'number' && 'display' in field;
};

export function formSearch(search: string, form: any) {
  if (!search.trim()) {
    return null;
  }

  const searchValue = search.toLowerCase();
  const results: Field[] = [];
  const pages: Page[] = Object.values(form.pages);

  const searchFields = (fields: any[], pageId: string) => {
    fields.forEach((field) => {
      if (
        !field.elements &&
        isFieldWithDisplay(field) &&
        field.display.toLowerCase().includes(searchValue)
      ) {
        field['page'] = pageId;
        results.push(field);
      }
      if (field.elements) {
        searchFields(Object.values(field.elements), pageId);
      }
    });
  };

  pages.forEach((page) => {
    const typedPage = page as Page;
    const sections = Object.values(typedPage.elements);

    sections.forEach((section) => {
      if (isSectionWithElements(section)) {
        const fields = Object.values(section.elements);
        searchFields(fields, typedPage.id);
      }
    });
  });

  return results;
}
