import type { OptionsConfig } from '@/services/forms/models/formTypes';

export const getOptionList = async (
  optionKey: string,
  fetchFn: typeof fetch
): Promise<OptionsConfig[] | null> => {
  const response = await fetchFn(`/apim/metarater/v1/${optionKey}`);
  if (response.status === 404 || !response.ok) {
    return null;
  }
  return response.json();
};
