import { FC } from 'react';
import type { DefaultValuesMap } from '../../../services/forms/agent/schema';
import type {
  FormField,
  FormPage,
} from '../../../services/forms/models/formTypes';
import { QuoteGroupWithQuote } from '../../../services/forms/models/quoteTypes';
import Section from '../../Section';
import SubheadingDivider from '../../SubheadingDivider';
import ActionMapper from '../Actions';
import { DynamicPanel } from '../DynamicPanel';
import { DynamicSection } from '../DynamicSection';
import FieldMapper from '../FieldMapper';

type Field = {
  display: string;
  page: string;
};

interface FormPageProps {
  page: FormPage;
  defaultValuesMap?: DefaultValuesMap;
  searchResults?: Field[] | null;
  quoteGroups?: QuoteGroupWithQuote[];
}

const FormPage: FC<FormPageProps> = ({
  page,
  defaultValuesMap,
  searchResults,
  quoteGroups,
}) => {
  const actions = page.actions ? (
    <div
      data-testid="rater-link-container"
      className="flex flex-row gap-2"
    >
      {page.actions.map((action) => (
        <ActionMapper
          key={action.id}
          action={action}
          quoteGroup={quoteGroups?.find(
            (quoteGroup) =>
              quoteGroup.rater.toLowerCase() === action.type.toLowerCase()
          )}
        />
      ))}
    </div>
  ) : null;

  return (
    <Section
      headingText={page.display}
      materialIcon={page.icon}
      actions={actions}
    >
      {page.elements.map((element) => {
        if (element.type === 'dynamicPanel' && defaultValuesMap) {
          const defaults =
            defaultValuesMap[element.defaults as keyof DefaultValuesMap];
          return (
            <DynamicPanel
              panel={element}
              panelDefaults={defaults}
              searchResults={searchResults}
              key={element.id}
            />
          );
        }
        if (element.type === 'dynamicSection' && defaultValuesMap) {
          const defaults =
            defaultValuesMap[element.defaults as keyof DefaultValuesMap];
          return (
            <DynamicSection
              section={element}
              sectionDefaults={defaults}
              searchResults={searchResults}
            />
          );
        }
        if (element.type === 'section') {
          return (
            <SubheadingDivider headingText={element.display}>
              {element.elements.map((field: FormField) => {
                field.highlight =
                  searchResults?.some(
                    (result: Field) => field.display === result.display
                  ) || null;

                return (
                  <div
                    key={field.id || field.name}
                    className={field.type === 'hidden' ? 'hidden' : ''}
                  >
                    <FieldMapper field={field} searchResults={searchResults} />
                    {field.actions && (
                      <div
                        data-testid="action-container"
                        className="flex flex-row gap-2 items-center"
                      >
                        {field.actions.map((action) => (
                          <ActionMapper key={action.id} action={action} />
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
            </SubheadingDivider>
          );
        }
        return null;
      })}
    </Section>
  );
};

export { FormPage };
