import { Input } from '@/components/ui/input';
import { FormField } from '@/services/forms/models/formTypes';
import type { FC } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';

export type HiddenFieldProps = {
  field: FormField;
  register: UseFormRegister<FieldValues>;
};

const HiddenField: FC<HiddenFieldProps> = ({ field, register}) => {
  const { id, name, disabled  } = field;

  return (
    <Input
      {...register(name, { setValueAs: (value) => value || undefined })}
      type={'hidden'}
      id={id || name}
      aria-hidden
      data-testid={id || name}
      disabled={disabled}
    />
  );
};

export { HiddenField };
