import type { FC } from 'react';
import { useFormContext, FieldError, useFormState, get } from 'react-hook-form';

import { phoneMask, currencyMask, numberMask } from '../inputMasks';
import type { FormField, FieldTypes } from '@/services/forms/models/formTypes';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useInputMask } from '../../utility/useInputMask.js';

const getMask = (format: FieldTypes | undefined): Record<string, any> => {
  switch (format) {
    case 'telephone':
      return phoneMask;
    case 'currency':
      return currencyMask;
    case 'number':
      return numberMask;
    default:
      return {};
  }
};

type MaskedInputProps = {
  field: FormField;
};

const MaskedInput: FC<MaskedInputProps> = ({ field }) => {
  const { type, id, name, display, placeholder, disabled, validation, syncWith, highlight } = field;
  const { errors } = useFormState();
  const error: FieldError = get(errors, name);

  const { setValue, watch, trigger, register } = useFormContext();
  const inputValue = watch(name);

  const mask = getMask(type);

  const { inputRef } = useInputMask({
    mask,
    register,
    value: inputValue,
    setValue,
    name,
    validation,
    syncWith,
  });

  const handleOnblur = () => {
    trigger(name);
  };
  return (
    <div className="flex flex-col w-[236px] gap-1.5">
      <Label htmlFor={id || name}>
        <span className={highlight ? 'bg-yellow-200 px-2' : ''}>{display}</span>
      </Label>
      <Input
        type={'text'}
        id={id || name}
        placeholder={placeholder}
        data-testid={id || name}
        ref={inputRef}
        onBlur={handleOnblur}
        disabled={disabled}
      />
      {error && <p className="text-sm text-light-text-error">{error.message}</p>}
    </div>
  );
};

export { MaskedInput };
