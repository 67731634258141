import { FC } from 'react';
import { IntakeNavOptions } from '../../components/common/constants';

export type NavBarItem = {
  icon?: JSX.Element;
  value: string;
  label: string;
  parent?: IntakeNavOptions;
  form_page?: string;
  search_count: number | null;
};

export type SideNavItemProps = {
  navItem: NavBarItem;
  selected: NavBarItem;
  setSelected: React.Dispatch<React.SetStateAction<NavBarItem>>;
};

const SideNavItem: FC<SideNavItemProps> = ({ navItem, setSelected, selected }) => {
  const isSelected = navItem.value === selected.value;

  return (
    <li>
      <button
        className={`flex items-center w-full gap-2.5 h-12 py-3 pl-1 pr-4 ${
          isSelected ? 'bg-light-surfaces-primary dark:bg-dark-surfaces-primary' : ''
        } text-sm text-light-text-primary dark:text-dark-text-primary`}
        onClick={() => setSelected(navItem)}
      >
        <span
          className={`w-[5px] h-[26px] rounded ${
            isSelected ? 'bg-light-text-link dark:bg-dark-text-link' : ''
          }`}
        ></span>
        <span className="w-6 h-6">{navItem.icon}</span>
        <span className="flex-1 flex justify-between">
          <span>{navItem.label}</span>
          <span className="text-right">{navItem.search_count}</span>
        </span>
      </button>
    </li>
  );
};

export default SideNavItem;
