import { AlertToast as AlertToastInterface } from '../../../../components/shared/alertToast';
import { useAuthenticatedFetch as _useAuthenticatedFetch } from '@/components/common/useAuthenticatedFetch';
import { getAccessToken as _getAccessToken } from '@/components/common/utils';
import { callCrmWhoAmI as _callCrmWhoAmI } from '@/pages/profile';
import { useMsal as _useMsal } from '@azure/msal-react';
import { useState, useCallback, useEffect } from 'react';
import {
  getAccountDetails as _getAccountDetails,
  getAssociatedContactList as _getAssociatedContactList,
  getFormList as _getFormList,
  getPolicyList as _getPolicyList,
} from '../../services/formsService';
import FormView from './FormView';
import {
  combineFormsAndPolicies as _combineFormsAndPolicies,
  policyIsSelected as _policyIsSelected,
} from './helpers';
import { useFormContext } from '../form/FormContext';

interface InjectedDependencies {
  useMsal?: typeof _useMsal;
  useAuthenticatedFetch?: typeof _useAuthenticatedFetch;
  getAccountDetails?: typeof _getAccountDetails;
  getAssociatedContactList?: typeof _getAssociatedContactList;
  getFormList?: typeof _getFormList;
  getPolicyList?: typeof _getPolicyList;
  combineFormsAndPolicies?: typeof _combineFormsAndPolicies;
  policyIsSelected?: typeof _policyIsSelected;
  getAccessToken?: typeof _getAccessToken;
  callCrmWhoAmI?: typeof _callCrmWhoAmI;
}

interface Props {
  account: string;
  injectedDependencies?: InjectedDependencies;
}

const FormController: React.FC<Props> = ({ account, injectedDependencies }) => {
  const {
    useMsal = _useMsal,
    useAuthenticatedFetch = _useAuthenticatedFetch,
    getAccountDetails = _getAccountDetails,
    getAssociatedContactList = _getAssociatedContactList,
    getFormList = _getFormList,
    getPolicyList = _getPolicyList,
    combineFormsAndPolicies = _combineFormsAndPolicies,
    getAccessToken = _getAccessToken,
    callCrmWhoAmI = _callCrmWhoAmI,
  } = injectedDependencies ?? {};

  // Async Data States
  const { instance } = useMsal();
  const authedFetch = useAuthenticatedFetch();
  const { fetchedData, setFetchedData } = useFormContext();
  // Alerts and Dialogs States
  const [alertToast, setAlertToast] = useState<
    AlertToastInterface | undefined
  >();
  const [alertToastOpen, setAlertToastOpen] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  // =================

  const refreshData = useCallback(async () => {
    if (fetchedData.state !== 'initialized') {
      return;
    }

    const [
      forms,
      policies,
      customer,
      associatedContactList,
      [accessToken, crmData],
    ] = await Promise.all([
      getFormList(authedFetch),
      getPolicyList(account, authedFetch),
      getAccountDetails(account, authedFetch),
      getAssociatedContactList(account, authedFetch),
      getAccessToken(instance).then((newAccessToken) => {
        return Promise.all([newAccessToken, callCrmWhoAmI(newAccessToken)]);
      }),
    ]);

    const aggregateFormData = combineFormsAndPolicies(forms, policies);
    const username = instance.getActiveAccount()?.name || '';

    setFetchedData({
      state: 'complete',
      content: {
        crmData,
        customer,
        associatedContactList,
        accessToken,
        formList: aggregateFormData,
        username,
      },
    });
  }, [fetchedData, setFetchedData, instance, authedFetch, account]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const showAlertToast = useCallback(
    (type: string, text: string) => {
      setAlertToast({
        type: type,
        text: text,
      });
      setAlertToastOpen(true);
    },
    [setAlertToast]
  );

  return (
    <FormView
      alertToastOpen={alertToastOpen}
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      alertToast={alertToast}
      showAlertToast={showAlertToast}
      closeAlertToast={() => {
        setAlertToast(undefined);
      }}
    />
  );
};

export default FormController;
