import { useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { getDynamicsOpportunityDetails } from '../../services/network/dynamics';
import { postEav } from '../../services/network/eav';
import { generateEAVItem } from '../../services/utils';
import { useAuthenticatedFetch } from '../../../../components/common/useAuthenticatedFetch';
import { getAccessToken } from '../../../../components/common/utils';
import { AgentIntakeForm } from '@/services/forms/agent/schema';

const selectFields = 'name,_bw_agent_value,_owningbusinessunit_value';
const expandFields = '';

const useDynamicsCrmAgentId = () => {
  const { instance } = useMsal();
  const authedFetch = useAuthenticatedFetch();

  const getDynamicsCrmAgentId = useCallback(
    async (opportunityId: string, entityId: string, initFormData: AgentIntakeForm) => {
      let formDataWithCrmInfo = {...initFormData};
      
      try {
        const accessToken = await getAccessToken(instance);

        if (!accessToken) {
          throw new Error('Failed to get access token');
        }

        const result = await getDynamicsOpportunityDetails({
          accessToken,
          opportunityId,
          queryParams: { selectFields, expandFields },
        });

        const existingCrmAgentId = initFormData.crm?.agentId;

        const dynamicsCrmAgentId =
          result && result._bw_agent_value ? result._bw_agent_value : undefined;

        const dynamicsCrmAgencyId =
          result && result._owningbusinessunit_value
            ? result._owningbusinessunit_value
            : undefined;

        if ((!dynamicsCrmAgentId && !existingCrmAgentId) || existingCrmAgentId === dynamicsCrmAgentId) {
          return formDataWithCrmInfo;
        }

        formDataWithCrmInfo = {
          ...initFormData,
          crm: {
            ...initFormData.crm,
            agentId: dynamicsCrmAgentId,
            agencyId: dynamicsCrmAgencyId,
          },
        };

        const crmEav = [
          generateEAVItem(entityId, 'crm.agentId', dynamicsCrmAgentId),
          generateEAVItem(entityId, 'crm.agencyId', dynamicsCrmAgencyId),
        ];

        const eavResult = await postEav({ body: crmEav, fetchFn: authedFetch });

        if (!eavResult) {
          console.log('Failed to persist crm EAV');
        }
      } catch (error) {
        console.log('Failed to load crmAgentId from Dynamics', error);
        return formDataWithCrmInfo;
      }

      return formDataWithCrmInfo;
    },
    [authedFetch, instance]
  );

  return { getDynamicsCrmAgentId };
};

export { useDynamicsCrmAgentId };
