import DisplayValue from '@/components/DisplayValue';
import { useVisible } from '@/components/utility';
import { FormField } from '@/services/forms/models/formTypes';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { AddressAutoCompleteField } from '../AddressAutoComplete';
import { ComboboxDropdown, ApiDropdown } from '../ComboboxDropdown';
import { DatePicker } from '../DatePicker';
import { HiddenField } from '../HiddenField';
import { InputField } from '../InputField';
import { MaskedInput } from '../MaskedInput';
import { RadioButtonGroup } from '../RadioButtonGroup';
import { DynamicDropdown } from '../ComboboxDropdown/DynamicDropdown';

type Field = {
  display: string;
  page: string;
};

export type FieldMapperProps = {
  field: FormField;
  searchResults?: Field[] | null;
};

const FieldMapper: FC<FieldMapperProps> = ({ field, searchResults }) => {
  const { register, setValue, getValues } = useFormContext();

  const visible = useVisible(field.visibleIf);

  useEffect(() => {
    if (!visible) {
      setValue(field.name, undefined, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      if (field.syncWith) {
        setValue(field.syncWith.fieldName, undefined, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      }
    }
  }, [visible, field.name]);

  if (!visible) return null;

  switch (field.type) {
    case 'addressautocomplete':
      return <AddressAutoCompleteField field={field} register={register} />;
    case 'apidropdown':
      return <ApiDropdown field={field} />;
    case 'bool':
      return <RadioButtonGroup field={field} />;
    case 'date':
      return <DatePicker field={field} />;
    case 'dropdown':
      return <ComboboxDropdown field={field} />;
    case 'dynamicdropdown':
      return <DynamicDropdown field={field} />;
    case 'hidden':
      return <HiddenField field={field} register={register} />;
    case 'number':
      return <MaskedInput field={field} />;
    case 'readonly':
      return (
        <DisplayValue
          label={field.display}
          fieldType={field.type}
          value={getValues(field.id || field.name)}
          searchResults={searchResults}
        />
      );
    case 'telephone':
      return <MaskedInput field={field} />;
    default:
      return <InputField field={field} />;
  }
};

export default FieldMapper;
