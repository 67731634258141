import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AgentIntakeForm } from '@/services/forms/agent/schema';
import { getEav, getEavRelationship } from '../../services/network/eav';
import { useAuthenticatedFetch } from '../../../../components/common/useAuthenticatedFetch';
import { useDynamicsOpportunity } from './useDynamicsOpportunity';
import { expandEAV, setDynamicDefaults } from '../../services/utils';
import { useDynamicsCrmAgentId } from './useDynamicsCrmAgentId';

export const useData = () => {
  const [formData, setFormData] = useState<AgentIntakeForm>();
  const [loading, setLoading] = useState<boolean>(true);
  const [errorText, setErrorText] = useState<string>();
  const [eavEntityId, setEavEntityId] = useState<string>();

  const { id } = useParams();
  const oppId = id ? id.toLowerCase() : id;
  const authedFetch = useAuthenticatedFetch();

  const { getDynamicsFormData } = useDynamicsOpportunity();
  const { getDynamicsCrmAgentId } = useDynamicsCrmAgentId();

  useEffect(() => {
    if (oppId) {
      const loadEav = async (oppId: string) => {
        setLoading(true);
        try {
          const eavRelationship = await getEavRelationship('Opportunity', oppId, authedFetch);

          if (!eavRelationship) {
            throw new Error('Unable to check for EAVRelationship');
          }

          if (!eavRelationship.length) {
            console.log('No eva relationship found, loading opportunity data from Dynamics');

            const { dynamicsFormData, dynamicsErrorText, entityId } = await getDynamicsFormData(
              oppId
            );

            if (dynamicsErrorText) {
              throw new Error(dynamicsErrorText);
            }

            setEavEntityId(entityId);

            if (!dynamicsFormData) {
              console.log('Unable to load dynamics data. Rendering empty form');
              setLoading(false);
              return;
            }

            const initFormData = setDynamicDefaults(dynamicsFormData);
            setFormData(initFormData);
          } else {
            const entId = eavRelationship[0].entity;
            const eav = await getEav(entId, authedFetch);

            if (!eav) {
              throw new Error('Failed to fetch EAV list');
            }

            if (eav.length !== 0) {
              const expandedEav = expandEAV(eav);
              let initFormData = setDynamicDefaults(expandedEav);
              initFormData = await getDynamicsCrmAgentId(oppId, entId, initFormData);
              setFormData(initFormData);
              setEavEntityId(entId);
            } else {
              const { dynamicsFormData, dynamicsErrorText, entityId } = await getDynamicsFormData(
                oppId,
                entId
              );

              if (dynamicsErrorText) {
                throw new Error(dynamicsErrorText);
              }

              setEavEntityId(entityId);

              if (!dynamicsFormData) {
                setLoading(false);
                return;
              }

              const initFormData = setDynamicDefaults(dynamicsFormData);
              setFormData(initFormData);
            }
          }
        } catch (error) {
          console.log(error);
          setErrorText('Failed to load opportunity data, please try again.');
        }
        setLoading(false);
      };
      loadEav(oppId);
    }
  }, [oppId, authedFetch, getDynamicsFormData]);

  return { formData, loading, errorText, eavEntityId };
};
