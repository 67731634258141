import { dset } from 'dset';
import dayjs from 'dayjs';
import type { EAVItem, EAVItemValue } from '../../models/eav';
import type { DynamicsOpportunity } from '../../models/dynamicOpportunity';
import { AgentIntakeForm, defaultValuesMap, FormAuto, FormHome } from '@/services/forms/agent/schema';
import { FormPriorPolicy } from '@/services/forms/agent/schema/helpers';
import { RaterIntegration } from '../../models/raterIntegration';
import { QuoteGroupWithQuote } from '../../../../services/forms/models/quoteTypes';
import { RaterEnum } from '@/services/forms/models/rateProductTypes';
import { InsuranceProduct } from '@bwinsurance/meta-rater-types';

export function transformToUIDateString(value: EAVItemValue) {
  if (typeof value !== 'string') {
    return value;
  }

  const yyyyMmDdRegex = /^\d{4}-\d{2}-\d{2}$/;

  if (yyyyMmDdRegex.test(value)) {
    return dayjs(value).format('MM/DD/YYYY');
  } else {
    return value;
  }
}

export function transformToApiDateString(value: EAVItemValue) {
  if (typeof value !== 'string') {
    return value;
  }

  const mmDdYyyyRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (mmDdYyyyRegex.test(value)) {
    return dayjs(value).format('YYYY-MM-DD');
  } else {
    return value;
  }
}

export function removeNull(value: EAVItemValue) {
  return value === null || value === 'undefined' ? undefined : value;
}

export function trimWhitespace(value: EAVItemValue) {
  return typeof value === 'string' ? value.trim() : value;
}

// will execute functions from right to left when invoked sanitizeValue(third, second, first)(value)
const sanitizeValue =
  (...transformations: ((input: EAVItemValue) => EAVItemValue)[]) =>
  (input: EAVItemValue) => {
    return transformations.reduce((result, fn) => fn(result), input);
  };

export function expandEAV(eav: EAVItem[]) {
  const formData = {};
  eav.forEach((eavItem) => {
    const sanitizedValue = sanitizeValue(
      transformToUIDateString,
      removeNull,
      trimWhitespace
    )(eavItem.value);
    dset(formData, eavItem.attribute, sanitizedValue);
  });

  return formData;
}

export function flattenToEAV(form: any, id: string) {
  const stack: { path: string; value: any }[] = [{ path: '', value: form }];
  const result: EAVItem[] = [];

  while (stack.length > 0) {
    const stackItem = stack.pop();
    if (!stackItem) continue;

    const { path, value } = stackItem;

    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          stack.push({ path: `${path}[${index}]`, value: item });
        });
      } else {
        for (const key in value) {
          let newPath = key;

          if (path && path.endsWith(']')) {
            newPath = `${path}${key}`;
          } else if (path) {
            newPath = `${path}.${key}`;
          }

          stack.push({ path: newPath, value: value[key] });
        }
      }
    } else {
      // replace array brackets with dot notation & drop dot at end of path if present
      const sanitizedPath = path.replace(/\[|\]/g, '.').replace(/\.$/, '');
      const sanitizedValue = sanitizeValue(transformToApiDateString)(value);
      result.push({
        entity: id,
        attribute: sanitizedPath,
        value: sanitizedValue,
      });
    }
  }

  return result;
}

export const dynamicsOpportunitySearchFields = `$select=name,bw_opportunitytype,_parentaccountid_value,bw_insuranceproductspcf,bw_insuranceproductspcfjson&$expand=parentaccountid($select=bw_firstname,bw_lastname,accountid,new_customerid,telephone3,emailaddress1,businesstypecode,address1_line1,address1_line2,address1_city,address1_stateorprovince,address1_postalcode)`;

export const generateEAVItem = (
  entityID: string,
  attribute: string,
  value?: string,
  source?: string
): EAVItem => {
  return {
    entity: entityID,
    attribute,
    value,
    ...(source ? { source } : {}),
  };
};

const stateNamesMap = {
  Alaska: 'AK',
  Alabama: 'AL',
  Arkansas: 'AR',
  Arizona: 'AZ',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  'District of Columbia': 'DC',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Iowa: 'IA',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Massachusetts: 'MA',
  Maryland: 'MD',
  Maine: 'ME',
  Michigan: 'MI',
  Minnesota: 'MN',
  Missouri: 'MO',
  Mississippi: 'MS',
  Montana: 'MT',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Nebraska: 'NE',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  Nevada: 'NV',
  'New York': 'NY',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Virginia: 'VA',
  Vermont: 'VT',
  Washington: 'WA',
  Wisconsin: 'WI',
  'West Virginia': 'WV',
  Wyoming: 'WY',
};
type StateNames = typeof stateNamesMap;

const insuranceProductFromCrmMapping = {
  Condo: 'Condo',
  Flood: 'Flood',
  'General Liability': 'Business',
  Homeowners: 'Home',
  'Home Warranty': 'Home Warranty',
  'Inland Marine (P)': 'Personal Articles',
  Life: 'Life',
  'Private Passenger Auto': 'Auto',
  Motorcycle: 'Motorcycle',
  'Pet Health Insurance': 'Pets',
  'Personal Travel Trailer': 'RV',
  Renters: 'Renters',
  'Umbrella (P)': 'Umbrella',
  'Watercraft (small boat)': 'Boat',
};

type InsuranceProductFromCrmMapping = typeof insuranceProductFromCrmMapping;

export const getInsuranceProductsString = (
  prodspcjson: string
): string | undefined => {
  let productsArray;
  try {
    productsArray = JSON.parse(prodspcjson);
  } catch (err) {
    console.log('Dynamics product list was not JSON');
    return;
  }

  if (!Array.isArray(productsArray)) {
    console.log('JSON products did not parse to an array');
    return;
  }
  const productNames: string[] = [];
  const crmProducts = Object.keys(insuranceProductFromCrmMapping);
  // currently only sending home (and auto in the plans) to rater
  // to get through the schema validation process when send data to rater
  // crm insurance products that are not in meta-rater-types insurance product are excluded
  productsArray.forEach((item) => {
    if (item.name && crmProducts.includes(item.name)) {
      const product = item.name as keyof InsuranceProductFromCrmMapping;
      productNames.push(insuranceProductFromCrmMapping[product]);
    }
  });
  if (productNames.length === 0) {
    return;
  }
  return productNames.join(',');
};

export const dynamicsOpportunityToEav = (
  opportunityDetails: DynamicsOpportunity,
  entityId: string
): EAVItem[] => {
  const stateStr = opportunityDetails.parentaccountid.address1_stateorprovince;
  let stateCode;
  if (stateStr && typeof stateStr === 'string') {
    // check if it is already a state code
    if (stateStr.length === 2) {
      stateCode = stateStr;
    } else if (stateStr in stateNamesMap) {
      stateCode = stateNamesMap[stateStr as keyof StateNames];
    }
  }

  return [
    generateEAVItem(
      entityId,
      'crm.accountId',
      opportunityDetails._parentaccountid_value ?? undefined
    ),
    generateEAVItem(entityId, 'crm.agentId', opportunityDetails._bw_agent_value ?? undefined),
    generateEAVItem(
      entityId,
      'crm.agencyId',
      opportunityDetails._owningbusinessunit_value ?? undefined
    ),
    generateEAVItem(
      entityId,
      'applicant.firstName',
      opportunityDetails.parentaccountid.bw_firstname ?? undefined
    ),
    generateEAVItem(
      entityId,
      'applicant.lastName',
      opportunityDetails.parentaccountid.bw_lastname ?? undefined
    ),
    generateEAVItem(
      entityId,
      'applicant.email',
      opportunityDetails.parentaccountid.emailaddress1 ?? undefined
    ),
    generateEAVItem(
      entityId,
      'applicant.mainPhone',
      opportunityDetails.parentaccountid.telephone3 ?? undefined
    ),
    generateEAVItem(
      entityId,
      'insuranceProducts',
      opportunityDetails.bw_insuranceproductspcfjson
        ? getInsuranceProductsString(opportunityDetails.bw_insuranceproductspcfjson)
        : undefined
    ),
    generateEAVItem(
      entityId,
      'applicant.currentAddress.lineOne',
      opportunityDetails.parentaccountid.address1_line1 ?? undefined
    ),
    generateEAVItem(
      entityId,
      'applicant.currentAddress.lineTwo',
      opportunityDetails.parentaccountid.address1_line2 ?? undefined
    ),
    generateEAVItem(
      entityId,
      'applicant.currentAddress.city',
      opportunityDetails.parentaccountid.address1_city ?? undefined
    ),
    generateEAVItem(entityId, 'applicant.currentAddress.stateCode', stateCode),
    generateEAVItem(
      entityId,
      'applicant.currentAddress.postalCode',
      opportunityDetails.parentaccountid.address1_postalcode ?? undefined
    ),
  ];
};

export const setDynamicDefaults = (expandedEav: Partial<AgentIntakeForm>): AgentIntakeForm => {
  const vehicleDefaults = defaultValuesMap.vehicles;
  const driverDefaults = defaultValuesMap.drivers;

  const updatedFormData = structuredClone(expandedEav);

  // fix data condition when auto and/or home has prior policy data
  // but hasPriorPolicy is not set
  checkAndSetHasPriorPolicyField(updatedFormData);

  convertInsuranceProductsFromCrm(updatedFormData);

  if (
    updatedFormData.drivers &&
    updatedFormData.drivers.length > 0 &&
    updatedFormData.vehicles &&
    updatedFormData.vehicles.length > 0
  ) {
    return updatedFormData as AgentIntakeForm;
  }

  const { drivers, vehicles, applicant } = updatedFormData;

  if (!drivers || drivers.length === 0) {
    updatedFormData.drivers = [
      {
        ...driverDefaults,
        ...(applicant
          ? {
              name: {
                firstName: applicant.firstName,
                lastName: applicant.lastName,
              },
              gender: applicant.gender,
              dateOfBirth: applicant.dateOfBirth,
              education: applicant.education,
              maritalStatus: applicant.maritalStatus,
              relation: applicant.relation,
              employmentStatus: applicant.employmentStatus,
              industry: applicant.industry,
              occupation: applicant.occupation,
            }
          : {}),
      },
    ];
  }

  if (!vehicles || vehicles.length === 0) {
    updatedFormData.vehicles = [vehicleDefaults];
  }

  return updatedFormData as AgentIntakeForm;
};

export const checkAndSetHasPriorPolicyField = (updatedFormData: Partial<AgentIntakeForm>) => {
  const setHasPriorPolicy = (priorPolicy: FormPriorPolicy) => {
    if (priorPolicy.carrier && !priorPolicy.hasPriorPolicy) {
      priorPolicy.hasPriorPolicy = 'hasCurrentPolicy';
    }
  };

  const extractPriorPolicy = (product?: FormAuto | FormHome) => {
    if (product && product.priorPolicy) {
      setHasPriorPolicy(product.priorPolicy);
    }
  };

  const { home, auto } = updatedFormData;
  extractPriorPolicy(home);
  extractPriorPolicy(auto);
};

export const convertInsuranceProductsFromCrm = (
  updatedFormData: Partial<AgentIntakeForm>
) => {
  if (updatedFormData.insuranceProducts) {
    const crmProducts = Object.keys(insuranceProductFromCrmMapping);
    const updatedInsuranceProducts = updatedFormData.insuranceProducts
      .split(',')
      .filter(Boolean)
      .map((insuranceProduct) => {
        const trimmedProduct = insuranceProduct.trim();
        if (crmProducts.includes(trimmedProduct)) {
          return insuranceProductFromCrmMapping[
            trimmedProduct as keyof InsuranceProductFromCrmMapping
          ];
        }
        return trimmedProduct;
      });
    updatedFormData.insuranceProducts = updatedInsuranceProducts.join(',');
  }
};

export const mapRaterIntegrationFromRecordSet = (
  data: Record<string, unknown>
): RaterIntegration => {
  return {
    agentId: data._bw_agent_value as string,
    status: data.statuscode as number,
    rater: data['bw_rater@OData.Community.Display.V1.FormattedValue'] as string,
    tenantId: data.bw_tenantid as string,
    userId: data.bw_userid as string,
    product: data['_bw_product_value@OData.Community.Display.V1.FormattedValue'] as string,
  };
};

export const findQuoteGroup = ({
  rater,
  insuranceProduct,
  quoteGroups,
}: {
  rater: RaterEnum;
  insuranceProduct: InsuranceProduct;
  quoteGroups: QuoteGroupWithQuote[];
}) => {
  const successQuoteGroups: QuoteGroupWithQuote[] = [];
  const errorQuoteGroups: QuoteGroupWithQuote[] = [];
  quoteGroups.forEach((quoteGroup) => {
    if (quoteGroup.rater === rater && quoteGroup.insuranceProduct === insuranceProduct) {
      if (quoteGroup.raterData) {
        successQuoteGroups.push(quoteGroup);
      }
      if (quoteGroup.errors) {
        errorQuoteGroups.push(quoteGroup)
      }
    }
  });

  return { successQuoteGroups, errorQuoteGroups };
};

export const delay = (timeInMs: number) => {
  return new Promise((resolve) => setTimeout(resolve, timeInMs));
};


export type ErrorType =
  | 'AuthenticationError'
  | 'ValidationError'
  | 'UnhandledError'
  | 'AlreadySentError'
  | 'InvalidFields';

export const getErrorMessage = (errorName?: ErrorType, options: any = {} ) => {
  const { leadId, message } = options;
  const leadIdString = leadId ? ` (Lead ID: ${leadId})` : '';

  switch (errorName) {
    case 'AuthenticationError':
      return 'The username or password we have for QuoteRUSH is incorrect. Please contact Agency Support.';
    case 'UnhandledError':
      return 'Failed to connect to QuoteRUSH. Please contact Agency Support.';
    case 'ValidationError':
      return message || 'Something went wrong. Please try again!';
    case 'AlreadySentError':
      return `You've already sent Homeowners. Please make adjustments directly in QuoteRush${leadIdString}.`;
    case 'InvalidFields':
      return `Some fields are invalid. Please correct the errors and try again.`;
    default:
      return 'Something went wrong. Please try again!';
  }
};
