import { IMask } from 'react-imask';

export const mask = {
  mask: Date,
  pattern: '`MM/`DD/`YYYY',
  lazy: false,
  overwrite: true,
  min: new Date('1900-01-01'),
  blocks: {
    MM: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
      maxLength: 2,
    },
    DD: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31,
      maxLength: 2,
    },
    YYYY: {
      mask: IMask.MaskedRange,
      from: 1900,
      to: 2999,
    },
  },
  format: (date: any) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();

    if (day < 10) day = '0' + day;
    if (month < 10) month = '0' + month;
    
    return [month, day, year].join('/');
  },
  parse: (inputStr: string) => {
    const monthDayYear = inputStr.split('/');
    // create a new Date with arguments year, month, day
    return new Date(Number(monthDayYear[2]), Number(monthDayYear[0]) - 1, Number(monthDayYear[1]));
  },
};

export const phoneMask = {
  mask: '(`0`0`0) `0`0`0-`0`0`0`0',
  lazy: false,
  placeholderChar: '_',
  overwrite: true,
};

// not sure this is the one we want, might just update to a dynamic mask
export const currencyMask = {
  mask: Number,
  scale: 2,
  thousandsSeparator: ',',
  padFractionalZeros: false,
  normalizeZeros: true,
  radix: '.',
  mapToRadix: ['.'],
};

export const numberMask = {
  mask: Number,
  scale: 0, // digits after point, 0 for integers
  min: 1, // backend needs number to be greater than 0
};
