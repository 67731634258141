import type { FC } from 'react';
import type { FormPanel } from '@/services/forms/models/formTypes';
import { useFormContext, useFieldArray } from 'react-hook-form';
import Section from '../../Section';
import SubheadingDivider from '../../SubheadingDivider';
import FieldMapper from '../FieldMapper';

type Field = {
  display: string;
  page: string;
};

type DynamicPanelProps = {
  panel: FormPanel;
  panelDefaults: Record<string, unknown>;
  searchResults?: Field[] | null;
};

const DynamicPanel: FC<DynamicPanelProps> = ({ panel, panelDefaults, searchResults }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: panel.id,
    control,
  });

  const handleClickAppend = () => {
    append(panelDefaults);
  };

  return (
    <div className="flex flex-col gap-8 -ml-8">
      {fields.map((field, fieldIdx) => {
        return (
          <Section
            key={field.id}
            headingText={`${panel.display} ${fieldIdx + 1}`}
            materialIcon={panel.icon}
          >
            <div>
              <section className="flex flex-col gap-8" key={`${field.id}::${field.id}`}>
                {panel.elements.map((section, idx) => {
                  if (section.type === 'section') {
                    return (
                      <SubheadingDivider
                        headingText={section.display}
                        key={`${field.id}::${section.id}`}
                      >
                        {section.elements.map((inputField, i) => {
                          const dynamicName = `${panel.id}.${fieldIdx}.${inputField.name}`;
                          const dynamicVisibleIf = inputField.visibleIf
                            ? `${panel.id}.${fieldIdx}.${inputField.visibleIf.fieldName}`
                            : null;
                          const dynamicField = {
                            ...inputField,
                            name: dynamicName,
                            ...(dynamicVisibleIf
                              ? {
                                  visibleIf: {
                                    ...inputField.visibleIf,
                                    fieldName: dynamicVisibleIf,
                                  },
                                }
                              : {}),
                            ...(inputField.syncWith && fieldIdx === 0
                              ? {
                                  syncWith: {
                                    ...inputField.syncWith,
                                  },
                                }
                              : { syncWith: undefined }),
                          };

                          dynamicField.highlight =
                            searchResults?.some(
                              (result: Field) => dynamicField.display === result.display
                            ) || null;

                          return (
                            <FieldMapper
                              key={`${field.id}::${inputField.name}`}
                              field={dynamicField}
                            />
                          );
                        })}
                        {idx + 1 === panel.elements.length && fieldIdx !== 0 && (
                          <button
                            className="flex pb-[4px] gap-1.25 items-end text-light-text-error"
                            type="button"
                            onClick={() => remove(fieldIdx)}
                          >
                            <span className="material-symbols-outlined font18 text-light-text-error dark:text-dark-text-error">
                              {'delete'}
                            </span>
                            <span>Delete</span>
                          </button>
                        )}
                      </SubheadingDivider>
                    );
                  }
                  // TODO: When needed, add dynamic sections here
                  return null;
                })}
              </section>
            </div>
          </Section>
        );
      })}
      <button
        className="flex items-center gap-1.25 pl-8 w-fit"
        type="button"
        onClick={handleClickAppend}
      >
        <span className="material-symbols-outlined font18 text-light-text-secondary dark:text-dark-text-secondary">
          {'add'}
        </span>
        <span>{`Add ${panel.display}`}</span>
      </button>
    </div>
  );
};

export { DynamicPanel };
