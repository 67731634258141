export enum RaterEnum {
  QuoteRush = 'QuoteRush',
  EZLynx = 'EZLynx',
}

export enum RaterProductEnum {
  home = 'Homeowners',
}

export type RaterProductEnabledMap = Record<
  `${RaterEnum}${RaterProductEnum}`,
  boolean
>;
