import { useEffect, useRef, useState } from 'react';
import Button from '../../../../components/shared/button';
import Dialog from '../../../../components/shared/dialog';
import TextField from '../../../../components/shared/textField';
import { ListOption, Select } from '../../../../components/shared/select';
import { Policy } from '../../models/policy';
import SectionDivider from '../../../../components/shared/sectionDivider';
import { addFileToFusion } from '../../services/documentService';
import { UploadData } from '../../models/uploadData';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useAuthenticatedFetch } from '../../../../components/common/useAuthenticatedFetch';

export default function Upload({
  open,
  closeDialog,
  files = [],
  categoryList,
  accountId,
  policyList,
  showAlertToast,
  refreshData,
  pca,
  userName,
}: {
  open: boolean;
  closeDialog: any;
  files?: FileList[];
  categoryList: string[];
  accountId: string;
  policyList: Policy[];
  showAlertToast: (type: string, message: string) => void;
  refreshData: () => void;
  pca: IPublicClientApplication;
  userName: string;
}) {
  const fileInputRef: { current: any } = useRef<HTMLInputElement>(null);
  const [fileDisplayName, setFileDisplayName] = useState<string>('');

  const [category, setCategory] = useState<string>('');
  const [categoryOptionList, setCategoryOptionList] = useState<ListOption[]>(
    []
  );

  const [notes, setNotes] = useState<string>('');

  const [selectedPolicies, setSelectedPolicies] = useState<string[]>([]);
  const [policyOptionList, setPolicyOptionList] = useState<ListOption[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const authedFetch = useAuthenticatedFetch();

  useEffect(() => {
    const mappedValues = policyList.map(
      (policy: Policy) =>
        ({
          label: `${policy.carrier.name} (${policy.policyNumber})`,
          value: policy.policyId,
          groupByValue: new Date(policy.effectiveDate).getFullYear(),
        } as ListOption)
    );
    setPolicyOptionList(mappedValues);
  }, [policyList]);

  useEffect(() => {
    const mappedValues = categoryList.map((category: string) => {
      return {
        label: category,
      } as ListOption;
    });
    setCategoryOptionList(mappedValues);
  }, [categoryList]);

  useEffect(() => {
    if (files && files.length && fileInputRef && fileInputRef.current) {
      fileInputRef.current.files = files;
      setFileDisplayName(fileInputRef.current.files[0].name);
    }
  }, [files]);

  const handlePolicySelect = (clickedPolicy: ListOption) => {
    const selectedPolicyIds: string[] = [];

    const policyOptionListCopy = [...policyOptionList];
    policyOptionListCopy.forEach((policy: ListOption) => {
      if (policy.value === clickedPolicy.value) {
        policy.selected = !policy.selected;
      }
      if (policy.selected) {
        selectedPolicyIds.push(policy.value);
      }
    });
    setPolicyOptionList(policyOptionListCopy);
    setSelectedPolicies(selectedPolicyIds);
  };

  const handleCategorySelect = (clickedCategory: ListOption) => {
    setCategory(clickedCategory.label);

    const categoryOptionListCopy = [...categoryOptionList];
    categoryOptionListCopy.forEach((category) => {
      if (category.label === clickedCategory.label) {
        category.selected = true;
      } else {
        category.selected = false;
      }
    });

    setCategoryOptionList(categoryOptionListCopy);
  };

  const save = () => {
    const user = pca.getActiveAccount();

    if (!user) {
      showAlertToast('warning', 'User not logged in.');
      return;
    }
    const data: UploadData = {
      file: fileInputRef.current.files[0],
      fileName: fileDisplayName,
      category,
      notes,
      policyList: selectedPolicies,
      accountId,
      userId: user.homeAccountId.split('.')[0],
      userName: userName,
    };

    setLoading(true);
    addFileToFusion(data, authedFetch)
      .then((result: string) => {
        resetThenClose();
        showAlertToast('success', `Added "${result}"`);
        refreshData();
      })
      .catch((error: string) => {
        showAlertToast('warning', 'Error adding file.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetThenClose = () => {
    setSelectedPolicies([]);
    setPolicyOptionList((prevState: ListOption[]) => {
      const optionListCopy = [...prevState];
      optionListCopy.forEach((option: ListOption) => {
        option.selected = false;
      });
      return optionListCopy;
    });

    setCategory('');
    setCategoryOptionList((prevState: ListOption[]) => {
      const optionListCopy = [...prevState];
      optionListCopy.forEach((option: ListOption) => {
        option.selected = false;
      });
      return optionListCopy;
    });

    setNotes('');
    setFileDisplayName('');

    closeDialog();
  };

  return (
    <Dialog open={open}>
      <div className="flex justify-between mb-5 items-center">
        <h5 className="text-light-text-primary dark:text-dark-text-primary">
          Add to Fusion Docs
        </h5>
        <span
          className="material-symbols-outlined font26 text-light-text-tertiary dark:text-dark-text-tertiary cursor-pointer"
          onClick={resetThenClose}
        >
          close
        </span>
      </div>
      <SectionDivider title={'Properties'} icon={'description'} />
      <div className="my-5 pl-[35px] flex flex-col gap-6">
        <label>
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            accept="image/*, video/*, .doc, .docx, .pdf, .xml, .txt, .csv, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
          <TextField
            label={'File Name'}
            value={fileDisplayName}
            onChange={(value: string) => setFileDisplayName(value)}
            required={true}
          />
        </label>
        <Select
          label={'Category'}
          options={categoryOptionList}
          required={true}
          placeholder={'Select a category...'}
          updateValue={handleCategorySelect}
        />
        <TextField
          label={'Note'}
          value={notes}
          onChange={(value: string) => setNotes(value)}
          placeholder={'Add additional details here...'}
        />
      </div>

      <SectionDivider title={'Link to...'} icon={'link'} />
      <div className="my-5 pl-[35px] flex flex-col gap-6">
        <Select
          label={'Policy #'}
          options={policyOptionList}
          placeholder={'Select an associated policy...'}
          updateValue={handlePolicySelect}
          multiple={true}
        />
      </div>
      <div className="flex justify-end gap-3">
        <Button
          callback={save}
          type={'primary'}
          classes="flex items-center gap-[5px]"
          disabled={!fileDisplayName || !category}
          loading={loading}
        >
          Save
        </Button>
        <Button callback={resetThenClose} type={'secondary'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
