import Button from '../../../../components/shared/button';
import Dialog from '../../../../components/shared/dialog';
import Checkbox from '../../../../components/shared/checkbox';
import TextField from '../../../../components/shared/textField';
import { Customer } from '../../models/customer';
import { Contact } from '../../models/contact';
import { ListOption, Select } from '../../../../components/shared/select';

interface Props {
  resetThenClose(): void;
  setSelectedOption(option: string): void;
  handleEmailSelect(clickedEmail: ListOption): void;
  handleSendEmail(): void;
  setAltEmailAddress(email: string): void;
  selectedOption: string;
  emailOptionList: ListOption[];
  customer: Customer | undefined;
  associatedContactList: Contact[];
  selectedEmail: string;
  altEmailAddress: string;
  loading: boolean;
  open: boolean;
}

const EmailComponent: React.FC<Props> = ({
  resetThenClose,
  setSelectedOption,
  handleEmailSelect,
  handleSendEmail,
  setAltEmailAddress,
  selectedOption,
  emailOptionList,
  customer,
  associatedContactList,
  selectedEmail,
  altEmailAddress,
  loading,
  open,
}) => {
  return (
    <Dialog open={open}>
      <div className="flex justify-between items-center">
        <h5 className="text-light-text-primary dark:text-dark-text-primary">
          Email form
        </h5>
        <span
          className="material-symbols-outlined font26 text-light-text-tertiary dark:text-dark-text-tertiary cursor-pointer"
          onClick={resetThenClose}
        >
          close
        </span>
      </div>
      <div className="my-6 flex flex-col gap-[20px]">
        <div className="font-medium text-base">Who&apos;s the recipient?</div>
        <div>
          <Checkbox
            label={`Customer's authorized contacts`}
            type={'radio'}
            checked={selectedOption === 'customer'}
            onChange={() => setSelectedOption('customer')}
            classes={'font-medium text base'}
          />
          <div className="pl-[35px] pt-[20px]">
            <Select
              label={''}
              options={emailOptionList}
              placeholder={
                associatedContactList && associatedContactList.length
                  ? 'Select or enter an email address...'
                  : `Enter customer's email address...`
              }
              updateValue={handleEmailSelect}
              combo={true}
              disabled={selectedOption !== 'customer'}
            />
          </div>
        </div>
        <div>
          <Checkbox
            label={`Someone else`}
            type={'radio'}
            checked={selectedOption === 'other'}
            onChange={() => setSelectedOption('other')}
            classes={'font-medium text base'}
          />
          <div className="pl-[35px] pt-[20px]">
            <TextField
              placeholder={`Enter the email address...`}
              value={altEmailAddress}
              onChange={(value: string) => setAltEmailAddress(value)}
              disabled={selectedOption !== 'other'}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end items-center gap-3">
        <Button
          callback={handleSendEmail}
          type={'square-primary'}
          disabled={!(selectedEmail || altEmailAddress)}
          loading={loading}
        >
          Send
        </Button>
        <Button callback={resetThenClose} type={'square-secondary'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};

export default EmailComponent;
