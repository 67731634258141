import { Form } from "../../models/form"
import FormListSection from "./FormListSection";
import Checkbox from '@/components/shared/checkbox';
import { useFormContext} from '../form/FormContext'


const FormList: React.FC = () => {
    const { toggleFormKey, selectedFormListKeys, forms} = useFormContext();

    return (
        <div className="flex flex-col gap-[12px]">
            {forms.map((form: Form) => {
                const hasFormPolicies = form.policies?.length ? form.policies.length > 0 : false;
                if (hasFormPolicies) {
                    return (
                        <FormListSection
                            header={form.name || ''}
                            policiesList={form.policies ?? []}
                            formKey={form.key}
                        />
                    );
                } else {
                    //individual form
                    const formIsSelected = selectedFormListKeys.includes(form.key)


                    return (
                        <div
                            className={`flex gap-4 p-[16px] font-normal font-segoe
                  w-full rounded-[4px] bg-light-surfaces-primary
                  text-light-text-primary dark:text-dark-text-primary`}
                        >
                            <Checkbox
                                checked={formIsSelected}
                                onChange={(event) => {
                                    event.stopPropagation()
                                    event.preventDefault()
                                    toggleFormKey(form.key)
                                }}
                                label={form.name}
                                classes="font-segoe"
                            />
                        </div>
                    );
                }
            })}
        </div>
    );
}



export default FormList