import { Application } from '@bwinsurance/meta-rater-types';
import { ReactNode } from 'react';
import { DisplayValueOptional } from '../DisplayValue';
import Section from '../Section';
import SubheadingDivider from '../SubheadingDivider';
import { getYears } from '../../components/common/utils';
import ErrorBoundary from '../ErrorBoundary';

export const PriorPolicy = ({
  application,
  headingText,
  additionalFields,
}: {
  application?: Application | null;
  headingText?: string;
  additionalFields?: ReactNode;
}): ReactNode => {
  if (!application || !application?.priorPolicy) {
    return null;
  }

  return (
    <SubheadingDivider headingText={headingText}>
      <DisplayValueOptional label="Carrier" value={application?.priorPolicy?.carrier} />
      <DisplayValueOptional label="Premium" value={application?.priorPolicy?.premium} isCurrency />
      <DisplayValueOptional
        label="Years with carrier"
        value={getYears(application?.priorPolicy?.monthsWithCarrier)}
      />
      <DisplayValueOptional
        label="Years with continuous coverage"
        value={getYears(application?.priorPolicy?.monthsWithContinuousCoverage)}
      />
      <DisplayValueOptional
        label="Policy number"
        value={application?.priorPolicy?.carrierPolicyId}
      />
      <DisplayValueOptional
        label="Effective date"
        value={application?.priorPolicy?.effectiveDate}
        isDate
      />
      <DisplayValueOptional
        label="Expiration"
        value={application?.priorPolicy?.expirationDate}
        isDate
      />
      <DisplayValueOptional
        label="Canceled date"
        value={application?.priorPolicy?.canceledDate}
        isDate
      />
      <DisplayValueOptional
        label="Renewal date"
        value={application?.priorPolicy?.renewalDate}
        isDate
      />
      <DisplayValueOptional
        label="Commercial form of business"
        value={application?.priorPolicy?.commercialFormOfBusiness}
      />
      <DisplayValueOptional
        label="Reason no prior coverage"
        value={application?.priorPolicy?.reasonNoPrior}
      />
      <DisplayValueOptional label="Description" value={application?.priorPolicy?.description} />
      {additionalFields}
    </SubheadingDivider>
  );
};

const PriorPolicyInfo = ({ applications }: { applications?: Application[] | null }): ReactNode => {
  if (!applications?.length) {
    return null;
  }
  const withPriorPolicies = applications.filter((app) => Boolean(app.priorPolicy));
  if (!withPriorPolicies.length) {
    return null;
  }
  return (
    <Section headingText="Existing Policies" materialIcon="shield">
      {withPriorPolicies.map((application) => (
        <PriorPolicy
          headingText={application.insuranceProduct}
          application={application}
          key={application.id}
        />
      ))}
    </Section>
  );
};

export default function WrappedPriorPolicyInfo(props: {
  applications?: Application[] | null;
}): ReactNode {
  return (
    <ErrorBoundary errorContext="Error in PriorPolicyInfo">
      <PriorPolicyInfo {...props} />
    </ErrorBoundary>
  );
}
