import type { EAVItem, EAVRelationshipItem } from '../../models/eav';

export const getEavRelationship = async (
  resourceType: string,
  resourceId: string,
  fetchFn: typeof fetch
): Promise<EAVRelationshipItem[] | null> => {
  const response = await fetchFn(
    `/apim/metarater/v1/eav/relationships/${resourceType}/${resourceId}`
  );
  if (response.status === 404 || !response.ok) {
    return null;
  }
  return response.json();
};

export const getEav = async (
  entityId: string,
  fetchFn: typeof fetch
): Promise<EAVItem[] | null> => {
  const response = await fetchFn(`/apim/metarater/v1/eav/${entityId}`);
  if (response.status === 404 || !response.ok) {
    return null;
  }
  return response.json();
};

export const postEavRelationship = async ({
  entityId,
  resourceId,
  fetchFn,
  resourceType,
}: {
  entityId: string;
  resourceId: string;
  fetchFn: typeof fetch;
  resourceType: string;
}) => {
  const body = [
    {
      entity: entityId,
      resource_id: resourceId,
      resource_type: resourceType,
    },
  ];

  const response = await fetchFn(`/apim/metarater/v1/eav${entityId}/relationships`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (response.status !== 201 || !response.ok) {
    return null;
  }
  return response.json();
};

export const postEav = async ({ body, fetchFn }: { body: EAVItem[]; fetchFn: typeof fetch }) => {
  const response = await fetchFn('/apim/metarater/v1/eav', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    return null;
  }

  return response.json();
};
