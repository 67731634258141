import type { FC } from 'react';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { FormField } from '@/services/forms/models/formTypes';
import { useFormState, get, FieldError, useFormContext } from 'react-hook-form';

export type InputFieldProps = {
  field: FormField;
};

const InputField: FC<InputFieldProps> = ({ field }) => {
  const { register, setValue } = useFormContext();
  const { id, name, display, placeholder, syncWith, disabled, highlight } = field;
  const { errors } = useFormState();
  const error: FieldError = get(errors, name);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (syncWith) {
      setValue(syncWith.fieldName, e.target.value);
    }
  };

  return (
    <div className="flex flex-col min-w-[236px] max-w-[492px] gap-1.5">
      <Label htmlFor={id || name}>
        <span className={highlight ? 'bg-yellow-200 px-2' : ''}>{display}</span>
      </Label>
      <Input
        {...register(name, {
          setValueAs: (value) => value || undefined,
          onChange: handleChange,
          ...(field.validation ? { ...field.validation } : {}),
        })}
        type={'text'}
        id={id || name}
        placeholder={placeholder}
        data-testid={id || name}
        disabled={disabled}
      />
      {error && <p className="text-sm text-light-text-error">{error.message}</p>}
    </div>
  );
};

export { InputField };
