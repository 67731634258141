import React from 'react';

type SwitchProps = {
  selectedKey: string;
  children: React.ReactNode;
};

const Switch: React.FC<SwitchProps> = ({ selectedKey, children }) => {
  return (
    <>
      {React.Children.map(children, child => {
        if (React.isValidElement(child) && child.key === selectedKey) {
          return child;
        }
        return null;
      })}
    </>
  );
};

export default Switch;
