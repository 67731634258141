import { Claim } from '@bwinsurance/meta-rater-types';
import { ReactNode } from 'react';
import { DisplayValueOptional } from '../DisplayValue';
import { getStringAddress } from '../../components/common/utils';
import SubheadingDivider from '../SubheadingDivider';

const ClaimDisplay = ({ claim }: { claim: Claim }): JSX.Element => (
  <div className="flex flex-row flex-wrap gap-5">
    <DisplayValueOptional label="Claim type" value={claim?.type} />
    <DisplayValueOptional label="Status" value={claim?.status} />
    <DisplayValueOptional label="Payout" value={claim?.payout} isCurrency />
    <DisplayValueOptional label="Date occurred" value={claim?.dateOccurred} isDate />
    <DisplayValueOptional label="Date closed" value={claim?.dateClosed} isDate />
    <DisplayValueOptional label="Carrier claim id" value={claim?.carrierClaimId} />
    <DisplayValueOptional label="Address" value={getStringAddress(claim?.address)} />
  </div>
);

export const ClaimList = ({ list }: { list?: Array<Claim | null> | null }): ReactNode => {
  if (!Array.isArray(list) || !list?.some(Boolean)) {
    return null;
  }
  const validClaims = list.filter(Boolean);
  return (
    <SubheadingDivider headingText="Claims">
      {validClaims.map((claim) => (
        <ClaimDisplay claim={claim as Claim} key={(claim as Claim).id} />
      ))}
    </SubheadingDivider>
  );
};
