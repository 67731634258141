import { useEffect } from 'react';

/**
 * Displays a customizable toast style alert message.
 *
 * @param {Object} config - The configuration object for this component.
 * @param {boolean} config.open - Flag indicating whether the alert should be displayed or not.
 * @param {function} config.closeAlert -The function to call to hide the alert.
 * @param {string} config.text - The text to display in the alert.
 * @param {string} config.type - Which type of alert should be displayed. Possible options are 'warning', 'info' or 'success'. 'info' is default.
 *
 * @example
 * <AlertToast open={alertToastOpen} closeAlert={() => setAlertToastOpen(false)} type={'success'} text={'Successfully saved.'} />
 */
export default function AlertToast({
  open,
  closeAlert,
  text,
  type,
}: {
  open: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  closeAlert: any;
  text: string;
  type: string;
}) {
  const DEFAULT_TIMEOUT = 10000;

  const colors = {
    // default to info
    background: 'bg-light-surfaces-info dark:bg-dark-surfaces-info',
    border: 'border-light-borders-primary dark:border-dark-borders-primary',
    text: 'text-light-text-primary dark:text-dark-text-inverse',
    icon: 'text-light-text-primary dark:text-dark-text-inverse',
  };
  let icon = 'info';

  if (type === 'warning') {
    colors.background = 'bg-light-surfaces-error dark:bg-dark-surfaces-error';
    colors.border = 'border-light-borders-error dark:border-dark-borders-error';
    colors.text = 'text-light-text-contrast dark:text-black';
    colors.icon = 'text-light-borders-error dark:text-dark-borders-error';
    icon = 'error_outline';
  } else if (type === 'success') {
    colors.background = 'bg-light-surfaces-confirmation dark:bg-dark-surfaces-confirmation';
    colors.border = 'border-light-borders-confirmation dark:border-dark-borders-confirmation';
    colors.text = 'text-light-text-contrast dark:text-black';
    colors.icon = 'text-light-borders-confirmation dark:text-dark-borders-confirmation';
    icon = 'check';
  }

  useEffect(() => {
    if (open) {
      setTimeout(closeAlert, DEFAULT_TIMEOUT);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (!open) {
    return;
  }
  return (
    <div className="fixed top-0 left-0 w-full h-full z-20 pointer-events-none">
      <div className="fixed bottom-5 left-0 w-screen flex justify-center px-[20px] z-30">
        <div
          className={[
            'border rounded-[4px] flex items-center p-3 gap-3 pointer-events-auto',
            colors.border,
            colors.text,
            colors.background,
          ].join(' ')}
        >
          <span
            className={['material-symbols-outlined font32', colors.icon].join(' ')}
            onClick={closeAlert}
          >
            {icon}
          </span>
          <div className="">{text}</div>
          <span
            className={['material-symbols-outlined cursor-pointer font24', colors.icon].join(' ')}
            onClick={closeAlert}
          >
            close
          </span>
        </div>
      </div>
    </div>
  );
}

export interface AlertToast {
  text: string;
  type: string;
}
