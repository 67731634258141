import TextField from '../../../../components/shared/textField';

export default function Search({ search, searchValue }: { search: any; searchValue: string }) {
  return (
    <div
      className={`flex items-center gap-[10px] rounded-[4px] p-[12px] font-medium cursor-pointer
                bg-light-surfaces-primary
                border border-light-borders-secondary dark:border-dark-borders-secondary
                hover:border-light-borders-primary dark:hover:border-dark-borders-primary
                has-[:focus]:border-light-borders-primary dark:has-[:focus]:border-dark-borders-primary
                text-light-text-tertiary dark:text-dark-text-tertiary`}
    >
      <span className="material-symbols-outlined font24">search</span>
      <input
        type="text"
        placeholder="Find a file, policy, etc..."
        className="border-none focus:outline-none text-light-text-contrast dark:text-light-text-contrast"
        onChange={search}
        value={searchValue}
      />
    </div>
  );
}
