import { useEffect, useRef } from 'react';

/**
 * Displays a styled text input field.
 *
 * @param {Object} config - The configuration object for this component.
 * @param {string} config.label - The text to display above the input.
 * @param {string} config.value - The value of the input field.
 * @param {function} config.onChange - The function to handle changes in the input field.
 * @param {boolean} [config.readonly] - Flag indicating whether the field is editable.
 * @param {boolean} [config.required] - Flag indicating whether the field is required.
 * @param {boolean} [config.disabled] - Flag indicating whether the field is disabled.
 * @param {string} [config.placeholder] - Placeholder text.
 * @param {string} [config.classes] - Any additional tailwind css classes to apply to the input.
 *
 * @example
 * <TextField label={'Email address'}  value={emailAddress} onChange={(event) => setEmailAddress(event)} />
 */
export default function TextField({
  label,
  value,
  onChange,
  readonly = false,
  required = false,
  disabled = false,
  placeholder,
  classes,
}: {
  label?: string;
  value: string;
  onChange: any;
  readonly?: boolean;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  classes?: string;
}) {
  const inputRef: { current: any } = useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      setTimeout(() => inputRef.current.select());
    }
  }, [inputRef]);

  return (
    <label className={classes}>
      {label && (
        <span className="text-light-text-secondary dark:text-light-text-secondary font-medium text-base">
          {label}
        </span>
      )}
      {required && <span className="text-light-text-error font-medium text-base">*</span>}
      <input
        type="text"
        className={`outline-none border border-light-borders-secondary dark:border-dark-borders-secondary 
                    hover:border-light-borders-primary dark:hover:border-dark-borders-primary
                    focus:border-light-borders-primary dark:focus:border-dark-borders-primary
                    ${
                      disabled
                        ? 'text-light-text-secondary dark:text-dark-text-secondary'
                        : 'text-light-text-contrast dark:text-light-text-contrast'
                    }
                    rounded-[4px] p-[12px] w-full font-medium 
                    ${disabled ? 'bg-light-surfaces-subtle dark:bg-light-surfaces-subtle' : ''}
                    ${readonly || disabled ? '' : 'cursor-pointer'}`}
        onChange={(event) => onChange(event.target.value)}
        value={value}
        ref={inputRef}
        readOnly={readonly}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus
      />
    </label>
  );
}
