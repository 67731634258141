/**
 * Displays a customizable styled checkbox.
 *
 * @param {Object} config - The configuration object for this component.
 * @param {string} [config.label] - Text to display next to the checkbox.
 * @param {boolean} config.checked - Flag indicating whether the checkbox is checked or not.
 * @param {function} [config.onChange] - Function called whenever the checkbox state changes.
 * @param {boolean} [config.disabled] - Flag indicating whether the checkbox is disabled or not.
 *
 * @example
 * <Checkbox checked={document.selected} onChange={() => toggleSelection(document.id, !document.selected)} />
 */
export default function Checkbox({
  label,
  checked,
  onChange,
  disabled,
  type,
  classes,
}: {
  label?: string;
  checked: boolean;
  onChange?: React.DOMAttributes<HTMLLabelElement>["onClick"]
  disabled?: boolean;
  type?: string;
  classes?: string;
}) {
  return (
    <label
      className={`
                ${disabled ? '' : 'cursor-pointer'}
                flex items-center gap-3
            `}
      onClick={onChange ? onChange : undefined}
    >
      {!checked && (
        <div
          className={`w-[24px] h-[24px] inline-block
                    border
                    border-light-borders-secondary
                    ${disabled
              ? 'hover:border-light-borders-secondary'
              : 'hover:border-light-borders-primary'
            }
                    dark:border-dark-borders-secondary
                    dark:hover:border-dark-borders-secondary
                    bg-light-surfaces-primary
                    ${disabled ? 'dark:bg-dark-borders-secondary' : 'dark:bg-dark-surfaces-contrast'
            }
                    ${type === 'radio' ? 'rounded-full' : 'rounded-sm'}`}
        ></div>
      )}
      {checked && (
        <div
          className={`w-[24px] h-[24px] inline-block
                    border
                    border-light-borders-primary
                    dark:border-dark-borders-brand
                    bg-light-surfaces-contrast
                    dark:bg-dark-surfaces-brand
                    ${type === 'radio' ? 'rounded-full' : 'rounded-sm'}`}
        >
          <span
            className={`material-symbols-outlined 
                        bg-transparent
                        dark:bg-transparent
                        text-light-text-inverse
                        dark:text-dark-text-inverse
                        font22`}
          >
            check
          </span>
        </div>
      )}
      {label && (
        <span
          className={[
            `${disabled ? 'text-light-text-tertiary' : 'text-light-text-primary'}
                    ${disabled ? 'dark:text-dark-text-tertiary' : 'dark:text-dark-text-primary'}`,
            classes,
          ].join(' ')}
        >
          {label}
        </span>
      )}
    </label>
  );
}
