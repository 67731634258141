import { useEffect, useCallback } from 'react';

type OnSaveFunction = () => Promise<{ saveError: boolean }>;

const useWindowConfirm = (isDirty: boolean, onSave: OnSaveFunction) => {
  const windowConfirm = useCallback(
    async (e: BeforeUnloadEvent) => {
      const { saveError } = await onSave();

      if (saveError) {
        // Message is for older browsers; modern browsers restrict custom messages
        const shouldLeavePage = window.confirm('Are you sure you want to leave?');

        if (!shouldLeavePage) {
          e.preventDefault();
          e.returnValue = ''; // Required for older browsers
        }
      }
    },
    [onSave]
  );

  useEffect(() => {
    if (isDirty) {
      window.addEventListener('beforeunload', windowConfirm);
    }

    return () => {
      window.removeEventListener('beforeunload', windowConfirm);
    };
  }, [isDirty, windowConfirm]);

  return null;
};

export { useWindowConfirm };
