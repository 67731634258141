import { useEffect, useRef, useState } from 'react';

type TooltipPositions = 'above' | 'below' | 'auto' | 'right';
const positionMapping = {
  above: { bottom: '100%' },
  below: { top: '100%' },
  right: { top: '15%', left: '80%' },
  auto: {}
}

/**
 * Displays a styled tooltip.
 *
 * @param {Object} config - The configuration object for this component.
 * @param {string} config.message - The text to display in the tooltip.
 * @param {HTML} config.children - The element to associate the tooltip to.
 * @param {boolean} [config.disabled] - Flag indicating whether the tooltip is disabled.
 * @param {string} [config.position] - Whether the tooltip should be displayed above or below the associated element.
 * @param {boolean} [config.hidden] - Flag indicating whether the tooltip is hidden.
 *
 * @example
 * <Tooltip
        message={`Tooltip for the button.`}
    >
        <button>Click here!</button>
    </Tooltip>
 */

export default function Tooltip({
  message,
  children,
  disabled = false,
  position = 'above',
  id,
  hidden = false,
}: {
  message: string;
  children: any;
  disabled?: boolean;
  position?: TooltipPositions;
  id?: string
  hidden?: boolean;
}) {
  const tooltipRef: { current: any } = useRef(null);
  const [positionFlag, setPositionFlag] = useState<TooltipPositions>(position);

  // this sets the position of the tooltip
  // to make sure it's onscreen
  useEffect(() => {
    function updateTooltipPosition() {
      if (tooltipRef.current && position === 'auto') {
        const rect = tooltipRef.current.getBoundingClientRect();

        if (rect.top >= window.innerHeight / 2) {
          setPositionFlag('above');
        } else {
          setPositionFlag('below');
        }
      }
    }

    window.addEventListener('resize', updateTooltipPosition);
    updateTooltipPosition();
    return () => window.removeEventListener('resize', updateTooltipPosition);
  }, [position]);

  if (hidden) {
    return children;
  }

  return (
    <div className="group relative flex" ref={tooltipRef}>
      {children}
      {!disabled && (
        <div
          id={id}
          style={positionMapping[positionFlag]}
          className={`absolute left-1/2 scale-0 rounded-[6px] p-[10px] shadow-tooltip font-medium text-base z-20 whitespace-pre
                    border border-light-borders-inverse dark:border-dark-borders-inverse
                    bg-light-surfaces-contrast dark:bg-dark-surfaces-contrast
                    text-light-text-inverse dark:text-dark-text-inverse
                    group-hover:scale-100`}
        >
          {message}
        </div>
      )}
    </div>
  );
}
