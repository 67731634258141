import { useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { getAccessToken } from '../../../../components/common/utils';
import { v4 as uuid } from 'uuid';
import { getDynamicsOpportunityDetails } from '../../services/network/dynamics';
import { postEavRelationship, postEav } from '../../services/network/eav';
import { dynamicsOpportunityToEav } from '../../services/utils';
import { expandEAV } from '../../services/utils';
import { useAuthenticatedFetch } from '../../../../components/common/useAuthenticatedFetch';
import type { AgentIntakeForm } from '../../../../services/forms/agent/schema';

const selectFields =
  'name,_bw_agent_value,_owningbusinessunit_value,bw_opportunitytype,_parentaccountid_value,bw_insuranceproductspcf,bw_insuranceproductspcfjson';
const expandFields =
  'parentaccountid($select=bw_firstname,bw_lastname,accountid,new_customerid,telephone3,emailaddress1,businesstypecode,address1_line1,address1_line2,address1_city,address1_stateorprovince,address1_postalcode)';

const useDynamicsOpportunity = () => {
  const { instance } = useMsal();
  const authedFetch = useAuthenticatedFetch();

  const getDynamicsFormData = useCallback(
    async (opportunityId: string, existingEntityId?: string) => {
      let formData, errorText, entityId;

      try {
        const accessToken = await getAccessToken(instance);

        if (!accessToken) {
          throw new Error('Failed to get access token');
        }

        entityId = existingEntityId ? existingEntityId : uuid();

        if (!existingEntityId) {
          const result = await postEavRelationship({
            entityId,
            resourceId: opportunityId,
            resourceType: 'Opportunity',
            fetchFn: authedFetch,
          });

          if (!result) {
            throw new Error('Failed to create EAV relationship');
          }
        }

        const opportunitiesDetails = await getDynamicsOpportunityDetails({
          accessToken,
          opportunityId,
          queryParams: { selectFields, expandFields },
        });

        if (!opportunitiesDetails) {
          console.log('Failed to fetch dynamics data. Rendering blank form');
          return { dynamicsFormData: formData, dynamicsErrorText: errorText, entityId };
        }

        const eav = dynamicsOpportunityToEav(opportunitiesDetails, entityId);
        const expandedEav = expandEAV(eav) as AgentIntakeForm;
        formData = expandedEav;

        const eavResult = await postEav({ body: eav, fetchFn: authedFetch });

        if (!eavResult) {
          console.log('Failed to persist initial EAV');
        }
      } catch (error) {
        console.log(error);
        errorText = 'Failed to load dynamics data';
      }

      return { dynamicsFormData: formData, dynamicsErrorText: errorText, entityId };
    },
    [authedFetch, instance]
  );

  return { getDynamicsFormData };
};

export { useDynamicsOpportunity };
