import { Applicant, Application } from '@bwinsurance/meta-rater-types';
import IntakeHeaderDisplay from '../IntakeHeaderDisplay';

const IntakeHeader = ({
  applicant,
  applications,
  customerData,
}: {
  applicant?: Applicant | null;
  applications?: Application[] | null;
  customerData?: any;
}): JSX.Element => {
  const applicantName = [applicant?.firstName, applicant?.lastName].filter(Boolean).join(' ');
  const businessType = applicant?.businessType ? `(${applicant?.businessType})` : '';
  const insuranceInterests = applications
    ?.reduce((prev, next) => {
      if (!prev.includes(next.insuranceProduct)) {
        prev.push(next.insuranceProduct);
      }
      return prev;
    }, [] as string[])
    .join(' • ');
  return (
    <header id="main-navbar" className="bg-primary text-white z-50">
      <nav className="flex items-center w-full h-20">
        <span className="pr-5 pl-6 min-w-56">
          <img
            src="/img/brightway-logo-yellow.svg"
            alt="Brightway Insurance"
            width="185"
            height="48"
            data-testid="brightway-logo"
          />
        </span>
        <IntakeHeaderDisplay label="Customer" value={applicantName} subValue={businessType} />
        <IntakeHeaderDisplay label="Insurance interests" value={insuranceInterests} />
        {customerData?.amsUrl && <IntakeHeaderDisplay label="" value={customerData?.amsUrl} />}
        {/* TODO display customerNumber after AMS sync */}
        {/* <IntakeHeaderDisplay label="AMS Customer Number" value={customerData.customerNumber} /> */}
      </nav>
    </header>
  );
};

export default IntakeHeader;
