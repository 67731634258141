import { useEffect, useRef, useState } from 'react';
import { Policy } from '../../pages/documents/models/policy';
import Button from './button';

export default function CarrierButton({
  children,
  type,
  disabled = false,
  icon,
  classes = '',
  policyList,
}: {
  children: any;
  type: 'primary-xl' | 'primary' | 'secondary-xl' | 'secondary' | 'text' | 'link';
  disabled?: boolean;
  icon?: string;
  classes?: string;
  policyList: Policy[];
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dropdownPosition, setDropdownPosition] = useState<number>(0);
  const dropdownRef: { current: any } = useRef(null);

  // this sets the height of the select dropdown
  // to make sure it doesn't overflow the window
  useEffect(() => {
    function updateDropdownPosition() {
      if (dropdownRef.current) {
        const rect = dropdownRef.current.getBoundingClientRect();
        setDropdownPosition(window.innerHeight - rect.bottom - 20);
      }
    }

    window.addEventListener('resize', updateDropdownPosition);
    updateDropdownPosition();
    return () => window.removeEventListener('resize', updateDropdownPosition);
  }, []);

  return (
    <div ref={dropdownRef}>
      {policyList.length < 2 && (
        <a
          href={
            policyList.length && policyList[0].carrier.agentPortalLink !== ''
              ? policyList[0].carrier.agentPortalLink
              : ''
          }
          target="_blank"
          onClick={() => setIsOpen(false)}
          rel="noreferrer"
        >
          <Button
            type={type}
            icon={icon}
            classes={['flex items-center', classes].join(' ')}
            disabled={
              disabled || policyList.length < 1 || policyList[0].carrier.agentPortalLink === ''
            }
          >
            Go to {policyList.length ? policyList[0].carrier.name : 'carrier'}
          </Button>
        </a>
      )}
      {policyList.length > 1 && (
        <Button
          callback={() => setIsOpen(!isOpen)}
          type={type}
          icon={icon}
          classes={['flex items-center', classes].join(' ')}
          disabled={disabled}
        >
          {children}
          <span className="material-symbols-outlined ml-[6px]">
            {isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          </span>
        </Button>
      )}
      {isOpen && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full z-10"
            onClick={() => setIsOpen(false)}
          ></div>
          <div className="h-[0px] w-[0px] relative overflow-visible">
            <div
              style={{ maxHeight: dropdownPosition }}
              className="shadow-select rounded-[4px] border p-[6px] top-[10px] bg-light-surfaces-primary dark:bg-dark-surfaces-primary relative z-20 w-min overflow-y-auto"
            >
              {policyList
                .sort((a: Policy, b: Policy) => (a.carrier.name < b.carrier.name ? -1 : 1))
                .map((policy: Policy, index: number) =>
                  policy.carrier.agentPortalLink ? (
                    <a
                      className={`px-[12px] py-[6px] w-full rounded-sm block
                                text-left font-normal cursor-pointer
                                text-light-text-primary dark:text-dark-text-primary
                                hover:bg-light-surfaces-subtle dark:hover:bg-dark-surfaces-subtle hover:dark:text-dark-text-inverse`}
                      href={policy.carrier.agentPortalLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span
                        onClick={() => setIsOpen(false)}
                        className="whitespace-nowrap"
                      >{`${policy.carrier.name} (${policy.policyNumber})`}</span>
                    </a>
                  ) : (
                    <div className="px-[12px] py-[6px] w-full rounded-sm block text-light-text-tertiary dark:text-dark-text-tertiary">
                      <span className="whitespace-nowrap">{`${policy.carrier.name} (${policy.policyNumber})`}</span>
                    </div>
                  )
                )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
