import { useEffect, useState } from 'react';
import Button from '../../../../components/shared/button';
import Dialog from '../../../../components/shared/dialog';
import { ListOption, Select } from '../../../../components/shared/select';
import { Policy } from '../../models/policy';
import { Document } from '../../models/document';
import { link } from '../../services/documentService';
import { useAuthenticatedFetch } from '../../../../components/common/useAuthenticatedFetch';

export default function Link({
  selectedDocumentList,
  open,
  closeDialog,
  linkedPolicyList,
  allPolicyList,
  showAlertToast,
  deselectAll,
  refreshData,
  userId,
  userName,
  updateField,
}: {
  selectedDocumentList: Document[];
  open: boolean;
  closeDialog: any;
  linkedPolicyList: Policy[];
  allPolicyList: Policy[];
  showAlertToast: any;
  deselectAll: () => void;
  refreshData: () => void;
  userId: string;
  userName: string;
  updateField: (
    documentId: string,
    field: 'name' | 'policy' | 'category' | 'note',
    value: any
  ) => void;
}) {
  const [selectedPolicies, setSelectedPolicies] = useState<string[]>(
    linkedPolicyList.map((policy: Policy) => policy.policyId)
  );
  const [policyOptionList, setPolicyOptionList] = useState<ListOption[]>([]);
  const authedFetch = useAuthenticatedFetch();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      const mappedValues = allPolicyList.map(
        (policyItem: Policy) =>
          ({
            label: `${policyItem.carrier.name} (${policyItem.policyNumber})`,
            value: policyItem.policyId,
            groupByValue: new Date(policyItem.effectiveDate).getFullYear(),
            selected: selectedPolicies.includes(policyItem.policyId),
          } as ListOption)
      );
      setPolicyOptionList(mappedValues);
    }
  }, [open]);

  const save = () => {
    setLoading(true);
    link(
      userId,
      userName,
      selectedDocumentList.map((document: Document) => document.id),
      selectedPolicies,
      getDeselectedPolicies(),
      authedFetch
    )
      .then((result: any) => {
        showAlertToast(
          'success',
          `File${
            selectedDocumentList.length > 1 ? 's' : ''
          } linked to: ${selectedPolicies
            .map((policyId: string) => {
              const policy = allPolicyList.find(
                (policy: Policy) => policy.policyId === policyId
              );
              return `"${policy?.policyNumber}"`;
            })
            .join(', ')}`
        );
        resetThenClose();
        deselectAll();
        selectedDocumentList.forEach((document: Document) => {
          updateField(document.id, 'policy', true);
        });
        refreshData();
      })
      .catch((error: any) => {
        showAlertToast('warning', `Error updating links`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePolicySelect = (clickedPolicy: ListOption) => {
    const selectedPolicyIds: string[] = [];

    const policyOptionListCopy = [...policyOptionList];
    policyOptionListCopy.forEach((policy: ListOption) => {
      if (policy.value === clickedPolicy.value) {
        policy.selected = !policy.selected;
      }
      if (policy.selected) {
        selectedPolicyIds.push(policy.value);
      }
    });
    setPolicyOptionList(policyOptionListCopy);
    setSelectedPolicies(selectedPolicyIds);
  };

  const getDeselectedPolicies = (): string[] => {
    console.log('linkedPoliciyList', linkedPolicyList);
    console.log('selectedPolicies', selectedPolicies);
    const deselectedPolicyIdList: string[] = [];

    linkedPolicyList.forEach((linkedPolicy: Policy) => {
      if (!selectedPolicies.includes(linkedPolicy.policyId)) {
        deselectedPolicyIdList.push(linkedPolicy.policyId);
      }
    });

    return deselectedPolicyIdList;
  };

  const resetThenClose = () => {
    setSelectedPolicies(
      linkedPolicyList.map((policy: Policy) => policy.policyId)
    );
    setPolicyOptionList((prevState: ListOption[]) => {
      const optionListCopy = [...prevState];
      optionListCopy.forEach((option: ListOption) => {
        option.selected = false;
      });
      return optionListCopy;
    });

    closeDialog();
  };

  return (
    <Dialog open={open}>
      <div className="flex justify-between items-center">
        <h5 className="text-light-text-primary dark:text-dark-text-primary">
          Link to...
        </h5>
        <span
          className="material-symbols-outlined font26 text-light-text-tertiary dark:text-dark-text-tertiary cursor-pointer"
          onClick={resetThenClose}
        >
          close
        </span>
      </div>
      <div className="my-6 flex flex-col gap-6">
        <Select
          label={'Policy #'}
          options={policyOptionList}
          placeholder={'Select an associated policy...'}
          updateValue={handlePolicySelect}
          multiple={true}
        />
      </div>
      <div className="flex justify-end gap-3">
        <Button callback={save} type={'primary'} loading={loading}>
          Save
        </Button>
        <Button callback={resetThenClose} type={'secondary'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
