import { getStringAddress } from '@/components/common/utils';
import { FormAction } from '@/services/forms/models/formTypes';
import { QuoteGroupWithQuote } from '@/services/forms/models/quoteTypes';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

export type ActionMapperProps = {
  action: FormAction;
  quoteGroup?: QuoteGroupWithQuote;
};

const ActionMapper: FC<ActionMapperProps> = ({ action, quoteGroup }) => {
  switch (action.type) {
    case 'zillow':
      return <ZillowAction action={action} />;
    case 'google-maps':
      return <GoogleMapsAction action={action} />;
    case 'quoterush':
      return <QuoteRushAction action={action} quoteGroup={quoteGroup} />;
  }
};

export default ActionMapper;

const linkClassNames = `text-left underline cursor-pointer text-light-text-link hover:text-light-text-primary`;

const ZillowAction = ({ action }: { action: FormAction }) => {
  const { watch } = useFormContext();
  const address = watch(action.fieldName);

  if (!address || !address.lineOne || !address.city) {
    return null;
  }

  const link = `https://zillow.com/homes/${getStringAddress(address)}`;
  return (
    <div id={action.id} data-testid={action.id} className="">
      <a
        href={link}
        title="Open Zillow"
        target="_blank"
        rel="noreferrer"
        className={linkClassNames}
      >
        Zillow
      </a>
    </div>
  );
};

const GoogleMapsAction = ({ action }: { action: FormAction }) => {
  const { watch } = useFormContext();
  const address = watch(action.fieldName);

  if (!address || !address.lineOne || !address.city) {
    return null;
  }

  const link = `https://www.google.com/maps/place/${getStringAddress(address)}`;
  return (
    <div id={action.id} data-testid={action.id} className="">
      <a
        href={link}
        title="Open Google Maps"
        target="_blank"
        rel="noreferrer"
        className={linkClassNames}
      >
        Google Maps
      </a>
    </div>
  );
};

const QuoteRushAction = ({
  action,
  quoteGroup,
}: {
  action: FormAction;
  quoteGroup?: QuoteGroupWithQuote;
}) => {
  if (!quoteGroup || !quoteGroup?.raterData?.leadId || !quoteGroup?.raterData?.mainLink) {
    return null;
  }

  return (
    <div id={action.id} data-testid={action.id} className="">
      <a
        href={quoteGroup?.raterData?.mainLink}
        title="Open QuoteRUSH"
        target="_blank"
        rel="noreferrer"
        className={'flex items-center gap-1 text-light-text-link'}
      >
        <span className="material-symbols-outlined text-base">north_east</span>
        <span className="text-sm">{`View in QuoteRUSH (Lead ID: ${quoteGroup?.raterData?.leadId})`}</span>
      </a>
    </div>
  );
};
