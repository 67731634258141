/**
 * Displays a section separator
 *
 * @param {Object} config - The configuration object for this component.
 * @param {string} [config.title] - Text to display in the separator.
 * @param {string} [config.icon] - The icon to display in the separator.
 *
 * @example
 * <SectionDivider title={'Properties'} icon={'description'} />
 */
export default function SectionDivider({ title, icon }: { title: string; icon: string }) {
  return (
    <div className="flex items-center gap-[10px]">
      <span className="material-symbols-outlined font24">{icon}</span>
      <span className="font-medium text-[18px] whitespace-nowrap">{title}</span>
      <div className="h-0 w-full grow border-b"></div>
    </div>
  );
}
