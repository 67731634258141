import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/services/lib/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-[4px] text-sm transition-colors disabled:pointer-event-none disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-[#F5F5F5]',
        secondary: 'bg-[#2C6DB7] border-t border-t-[#DDDDDD] gap-1 font-semibold text-light-text-inverse dark:text-dark-text-inverse',
        link: 'bg-light-text-link dark:bg-dark-text-link gap-2.5 font-semibold text-light-text-inverse dark:text-dark-text-inverse',
        outline: 'border border-light-border-tertiary dark:border-dark-border-tertiary gap-2.5 bg-light-surface-primary dark:bg-dark-surface-primary font-semibold text-light-text-contrast dark:text-dark-text-contrast',
        ghost: 'bg-light-surface-primary dark:bg-dark-surface-primary',
      },
      size: {
        default: 'text-sm py-1.5 px-3 ',
        sm: 'w-[66px] py-1.5 px-3',
        md: 'w-[182px] py-2 h-10',
        lg: 'w-[242px] py-1.5 px-3',
        icon: 'text-[20px] w-5 h-5',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
