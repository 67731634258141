export const booleanDropdownOptions = [
  { key: 'true', display: 'Yes' },
  { key: 'false', display: 'No' },
];

export const constructionTypeOptions = [
  { key: 'A_FRAME', display: 'A frame' },
  { key: 'ADOBE', display: 'Adobe' },
  { key: 'ALUMINUM_FRAME', display: 'Aluminum frame' },
  { key: 'ALUMINUM_SIDING', display: 'Aluminum siding' },
  { key: 'ALUMINUM_VINYL', display: 'Aluminum vinyl' },
  { key: 'ARCHED_DOME', display: 'Arched dome' },
  { key: 'BLOCK_BRICK', display: 'Block brick' },
  { key: 'BLOCK_METAL', display: 'Block metal' },
  { key: 'BLOCK_STEEL', display: 'Block steel' },
  { key: 'BRICK', display: 'Brick' },
  { key: 'BRICK_CINDER_BLOCK', display: 'Brick cinder block' },
  { key: 'BRICK_CONCRETE', display: 'Brick concrete' },
  { key: 'BRICK_FRAME', display: 'Brick frame' },
  { key: 'BRICK_METAL', display: 'Brick metal' },
  { key: 'BRICK_STEEL', display: 'Brick steel' },
  { key: 'BRICK_STONE', display: 'Brick stone' },
  { key: 'BRICK_STUCCO', display: 'Brick stucco' },
  { key: 'CINDER_BLOCK', display: 'Cinder block' },
  { key: 'CLAPBOARD', display: 'Clapboard' },
  { key: 'COMBINATION', display: 'Combination' },
  { key: 'CONCRETE', display: 'Concrete' },
  { key: 'CONCRETE_BLOCK', display: 'Concrete block' },
  { key: 'CONCRETE_MASONRY', display: 'Concrete masonry' },
  { key: 'CONCRETE_STEEL', display: 'Concrete steel' },
  { key: 'CUSTOM', display: 'Custom' },
  { key: 'DOME', display: 'Dome' },
  { key: 'FLEXIBLE_FLEXICORE', display: 'Flexible flexicore' },
  { key: 'FRAME', display: 'Frame' },
  { key: 'FRAME_MASONRY', display: 'Frame masonry' },
  { key: 'FRAME_STEEL', display: 'Frame steel' },
  { key: 'FRAME_STUCCO', display: 'Frame stucco' },
  { key: 'GLASS', display: 'Glass' },
  { key: 'HEAVY', display: 'Heavy' },
  { key: 'LIGHT', display: 'Light' },
  { key: 'LOG', display: 'Log' },
  { key: 'MANUFACTURED_MODULAR', display: 'Manufactured modular' },
  { key: 'MASONRY', display: 'Masonry' },
  { key: 'MASONRY_TILT_UP', display: 'Masonry tilt up' },
  { key: 'METAL', display: 'Metal' },
  { key: 'NONE', display: 'None' },
  { key: 'POLE_FRAME', display: 'Pole frame' },
  { key: 'PREFAB_METAL', display: 'Prefab metal' },
  { key: 'STEEL', display: 'Steel' },
  { key: 'STEEL_MASONRY', display: 'Steel masonry' },
  { key: 'STEEL_STUCCO', display: 'Steel stucco' },
  { key: 'STEEL_WOOD', display: 'Steel wood' },
  { key: 'STONE_VENEER', display: 'Stone veneer' },
  { key: 'STONE_ROCK', display: 'Stone rock' },
  { key: 'STUCCO', display: 'Stucco' },
  { key: 'TILT_UP', display: 'Tilt up' },
  { key: 'TILT_UP_BRICK_METAL', display: 'Tilt up brick metal' },
  { key: 'TILT_UP_CONCRETE', display: 'Tilt up concrete' },
  { key: 'TYPE_NOT_SPECIFIED', display: 'Type not specified' },
  { key: 'TYPE_UNKNOWN', display: 'Type unknown' },
  { key: 'WOOD', display: 'Wood' },
  { key: 'WOOD_FRAME', display: 'Wood frame' },
  {
    key: 'WOOD_FRAME_CONCRETE_BLOCK',
    display: 'Wood frame concrete block',
  },
  { key: 'WOOD_FRAME_METAL', display: 'Wood frame metal' },
  { key: 'WOOD_BRICK', display: 'Wood brick' },
  { key: 'WOOD_STEEL', display: 'Wood steel' },
  { key: 'WOOD_STONE', display: 'Wood stone' },
  { key: 'WOOD_STUCCO', display: 'Wood stucco' },
];

export const dwellingTypeOptions = [
  { key: 'One Family', display: 'One family' },
  { key: 'Two Family', display: 'Two family' },
  { key: 'Three Family', display: 'Three family' },
  { key: 'Four Family', display: 'Four family' },
];

export const dwellingUseOptions = [
  { key: 'Primary', display: 'Primary' },
  { key: 'Secondary', display: 'Secondary' },
  { key: 'Seasonal', display: 'Seasonal' },
  { key: 'Farm', display: 'Farm' },
  { key: 'Unoccupied', display: 'Unoccupied' },
  { key: 'Vacant', display: 'Vacant' },
  { key: 'COC', display: 'COC' },
];

export const heatingTypeOptions = [
  { key: 'BASEBOARD', display: 'Baseboard' },
  { key: 'CEILING_CABLE', display: 'Ceiling cable' },
  { key: 'CEILING_FURNACE', display: 'Ceiling furnace' },
  { key: 'CENTRAL', display: 'Central' },
  { key: 'COAL', display: 'Coal' },
  { key: 'CONVECTION', display: 'Convection' },
  { key: 'ELECTRIC', display: 'Electric' },
  { key: 'FIREPLACE', display: 'Fireplace' },
  { key: 'FLOOR_FURNACE', display: 'Floor furnace' },
  { key: 'FLOOR_WALL_FURNACE', display: 'Floor wall furnace' },
  { key: 'FORCED_AIR', display: 'Forced air' },
  {
    key: 'FORCED_AIR_WITH_AIR_CONDITIONING',
    display: 'Forced air with air conditioning',
  },
  { key: 'GAS', display: 'Gas' },
  { key: 'GEO_THERMAL', display: 'Geo thermal' },
  { key: 'GRAVITY', display: 'Gravity' },
  { key: 'HEAT_PUMP', display: 'Heat pump' },
  { key: 'HOT_WATER', display: 'Hot water' },
  { key: 'NONE', display: 'None' },
  { key: 'NOT_AVAILABLE', display: 'Not available' },
  { key: 'NP', display: 'Np' },
  { key: 'OIL', display: 'Oil' },
  { key: 'OTHER', display: 'Other' },
  { key: 'PACKAGE', display: 'Package' },
  { key: 'PARTIAL', display: 'Partial' },
  { key: 'PROPANE', display: 'Propane' },
  { key: 'RADIANT', display: 'Radiant' },
  { key: 'SOLAR', display: 'Solar' },
  { key: 'SPACE', display: 'Space' },
  { key: 'STEAM', display: 'Steam' },
  { key: 'STOVE', display: 'Stove' },
  { key: 'UNKNOWN', display: 'Unknown' },
  { key: 'WALL_GAS', display: 'Wall gas' },
  { key: 'WOOD_FURNACE', display: 'Wood furnace' },
  { key: 'YES', display: 'Yes' },
  { key: 'ZONE', display: 'Zone' },
];

export const homeUpdateOptions = [
  { key: 'NOT UPDATED', display: 'Not updated' },
  { key: 'PARTIAL UPDATE', display: 'Partial update' },
  { key: 'COMPLETE UPDATE', display: 'Complete update' },
];

export const roofShapeOptions = [
  { key: 'A_FRAME', display: 'A frame' },
  { key: 'ARCHED', display: 'Arched' },
  { key: 'BARN', display: 'Barn' },
  { key: 'BARREL', display: 'Barrel' },
  { key: 'BOWSTRING_TRUSS', display: 'Bowstring truss' },
  { key: 'BUBBLE', display: 'Bubble' },
  { key: 'BUTTERFLY', display: 'Butterfly' },
  { key: 'CANOPY', display: 'Canopy' },
  { key: 'CATHEDRAL_CLERESTORY', display: 'Cathedral clerestory' },
  { key: 'COMPLEX_CUSTOM', display: 'Complex custom' },
  { key: 'CONTEMPORARY', display: 'Contemporary' },
  { key: 'DORMER', display: 'Dormer' },
  { key: 'FLAT', display: 'Flat' },
  { key: 'FRAME', display: 'Frame' },
  { key: 'GABLE', display: 'Gable' },
  { key: 'GABLE_HIP', display: 'Gable hip' },
  { key: 'GAMBREL', display: 'Gambrel' },
  { key: 'GAMBREL_MANSARD', display: 'Gambrel mansard' },
  { key: 'GEODESIC_DOME', display: 'Geodesic dome' },
  { key: 'HIP', display: 'Hip' },
  { key: 'MANSARD', display: 'Mansard' },
  { key: 'MONITOR', display: 'Monitor' },
  { key: 'OTHER', display: 'Other' },
  { key: 'PITCHED', display: 'Pitched' },
  { key: 'PRESTRESS_CONCRETE', display: 'Prestress concrete' },
  { key: 'PYRAMID', display: 'Pyramid' },
  { key: 'REINFORCED_CONCRETE', display: 'Reinforced concrete' },
  { key: 'RIGID_FRM_BAR_JT', display: 'Rigid frm bar jt' },
  { key: 'SAWTOOTH', display: 'Sawtooth' },
  { key: 'SHED', display: 'Shed' },
  { key: 'STEEL_FRM_TRUSS', display: 'Steel frm truss' },
  { key: 'SWISS_CHALET_ALPINE', display: 'Swiss chalet alpine' },
  { key: 'WOOD_TRUSS', display: 'Wood truss' },
];

export const roofTypeOptions = [
  { key: 'ALUMINUM', display: 'Aluminum' },
  { key: 'ASBESTOS', display: 'Asbestos' },
  { key: 'ASPHALT', display: 'Asphalt' },
  {
    key: 'AVERAGE_COMPOSITION_SHINGLE',
    display: 'Average composition shingle',
  },
  { key: 'BAR_TILE_SPANISH_STYLE', display: 'Bar tile spanish style' },
  { key: 'BI_METAL_TWO_PLY', display: 'Bi metal two ply' },
  {
    key: 'BUILTUP_LAYERED_ASPHALT',
    display: 'Builtup layered asphalt',
  },
  { key: 'CLAY_TILE', display: 'Clay tile' },
  { key: 'COMPOSITION_SHINGLE', display: 'Composition shingle' },
  { key: 'CONCRETE', display: 'Concrete' },
  { key: 'CONCRETE_TILE', display: 'Concrete tile' },
  { key: 'ENAMEL', display: 'Enamel' },
  { key: 'FIBERGLASS', display: 'Fiberglass' },
  { key: 'GALVANIZED', display: 'Galvanized' },
  { key: 'GYPSUM', display: 'Gypsum' },
  {
    key: 'HEAVY_COMPOSITION_SHINGLE',
    display: 'Heavy composition shingle',
  },
  {
    key: 'LIGHT_COMPOSITION_SHINGLE',
    display: 'Light composition shingle',
  },
  { key: 'MASONITE_CEMENT_SHAKE', display: 'Masonite cement shake' },
  { key: 'MEDIUM_SHAKE', display: 'Medium shake' },
  { key: 'METAL', display: 'Metal' },
  { key: 'NOT_AVAILABLE', display: 'Not available' },
  { key: 'NP', display: 'Np' },
  { key: 'OTHER_NOT_CLASSIFIED', display: 'Other not classified' },
  { key: 'ROCK_GRAVEL', display: 'Rock gravel' },
  {
    key: 'ROLL_COMPOSITION_ROLLED_MINERAL_ROOF',
    display: 'Roll composition rolled mineral roof',
  },
  {
    key: 'ROLL_PAPER_ROLLED_SMOOTH_ROOF',
    display: 'Roll paper rolled smooth roof',
  },
  { key: 'ROLL_TAR_GRAVEL', display: 'Roll tar gravel' },
  { key: 'RUBBER_ELASTOMETRIC', display: 'Rubber elastometric' },
  { key: 'SHAKE', display: 'Shake' },
  { key: 'SHINGLE', display: 'Shingle' },
  { key: 'SLATE', display: 'Slate' },
  { key: 'SLATE_TILE', display: 'Slate tile' },
  { key: 'SYNTHETIC_TILE', display: 'Synthetic tile' },
  { key: 'TILE', display: 'Tile' },
  { key: 'UNKNOWN', display: 'Unknown' },
  {
    key: 'UNKNOWN_OR_NOT_PROVIDED',
    display: 'Unknown or not provided',
  },
  { key: 'URETHANE', display: 'Urethane' },
  { key: 'WOOD', display: 'Wood' },
  { key: 'WOOD_SHAKE_SHINGLE', display: 'Wood shake shingle' },
  { key: 'WOOD_SHINGLE', display: 'Wood shingle' },
];

export const subTypeOptions = [
  { key: 'AGRICULTURE_FARMING', display: 'Agriculture farming' },
  { key: 'COMMERCIAL', display: 'Commercial' },
  { key: 'INDUSTRIAL', display: 'Industrial' },
  { key: 'OTHER', display: 'Other' },
  { key: 'OTHER_UNKNOWN', display: 'Other unknown' },
  { key: 'PUBLIC_WORKS', display: 'Public works' },
  { key: 'RESIDENTIAL', display: 'Residential' },
  { key: 'VACANT_LAND', display: 'Vacant land' },
];

export const swimmingPoolTypesOptions = [
  { key: 'OTHER', display: 'Other' },
  { key: 'ABOVE_GROUND_POOL', display: 'Above ground pool' },
  { key: 'ADULT_KIDDIE_POOLS', display: 'Adult kiddie pools' },
  { key: 'BUMPER_BOAT', display: 'Bumper boat' },
  { key: 'CONCRETE_VINYL', display: 'Concrete vinyl' },
  { key: 'FISH_POND', display: 'Fish pond' },
  { key: 'HEATED_POOL_GUNITE', display: 'Heated pool gunite' },
  { key: 'INDOOR_POOL', display: 'Indoor pool' },
  { key: 'IN_GROUND_POOL', display: 'In ground pool' },
  { key: 'IN_GROUND_VINYL_POOL', display: 'In ground vinyl pool' },
  { key: 'KIDDIE_PLAY_POOL', display: 'Kiddie play pool' },
  { key: 'LAGOON', display: 'Lagoon' },
  { key: 'LAP_POOL', display: 'Lap pool' },
  {
    key: 'MULTIPLE_POOLS_UNSPECIFIED',
    display: 'Multiple pools unspecified',
  },
  { key: 'NO_POOL', display: 'No pool' },
  { key: 'NOT_STATED', display: 'Not stated' },
  { key: 'POOL_SPA_WITH_DECK', display: 'Pool spa with deck' },
  {
    key: 'POOL_CONCRETE_WITH_CABANA_POOL_HOUSE',
    display: 'Pool concrete with cabana pool house',
  },
  {
    key: 'POOL_CONCRETE_WITH_EQUIPMENT',
    display: 'Pool concrete with equipment',
  },
  { key: 'POOL_CONCRETE_FENCED', display: 'Pool concrete fenced' },
  {
    key: 'POOL_GUNITE_WITH_HOT_TUB_SPA',
    display: 'Pool gunite with hot tub spa',
  },
  { key: 'POOL_GUNITE_ENCLOSED', display: 'Pool gunite enclosed' },
  {
    key: 'POOL_GUNITE_KIDNEY_SHAPED',
    display: 'Pool gunite kidney shaped',
  },
  {
    key: 'POOL_IN_GROUND_WITH_HOT_TUB_SPA',
    display: 'Pool in ground with hot tub spa',
  },
  {
    key: 'POOL_UNSPECIFIED_WITH_COVER',
    display: 'Pool unspecified with cover',
  },
  {
    key: 'POOL_UNSPECIFIED_WITH_DECK',
    display: 'Pool unspecified with deck',
  },
  {
    key: 'POOL_UNSPECIFIED_ENCLOSED',
    display: 'Pool unspecified enclosed',
  },
  {
    key: 'POOL_UNSPECIFIED_FENCED',
    display: 'Pool unspecified fenced',
  },
  {
    key: 'POOL_UNSPECIFIED_HEATED',
    display: 'Pool unspecified heated',
  },
  { key: 'POOL_VINYL_WITH_DECK', display: 'Pool vinyl with deck' },
  { key: 'POOL_VINYL_WITH_PATIO', display: 'Pool vinyl with patio' },
  {
    key: 'POOL_VINYL_KIDNEY_SHAPED',
    display: 'Pool vinyl kidney shaped',
  },
  { key: 'POOL_VINYL_L_SHAPED', display: 'Pool vinyl l shaped' },
  { key: 'POOL_VINYL_OVAL_SHAPED', display: 'Pool vinyl oval shaped' },
  { key: 'POOL_WITH_BAR', display: 'Pool with bar' },
  {
    key: 'POOL_WITH_CABANA_POOL_HOUSE',
    display: 'Pool with cabana pool house',
  },
  { key: 'POOL_WITH_CANOPY', display: 'Pool with canopy' },
  { key: 'POOL_WITH_DIVING_BOARD', display: 'Pool with diving board' },
  { key: 'POOL_WITH_EQUIPMENT', display: 'Pool with equipment' },
  { key: 'POOL_WITH_FOUNTAIN', display: 'Pool with fountain' },
  { key: 'POOL_WITH_HOT_TUB_SPA', display: 'Pool with hot tub spa' },
  { key: 'POOL_WITH_PATIO', display: 'Pool with patio' },
  { key: 'POOL_WITH_SAUNA', display: 'Pool with sauna' },
  { key: 'POOL_WITH_WATERFALL', display: 'Pool with waterfall' },
  { key: 'POOL_ARROW_SHAPED', display: 'Pool arrow shaped' },
  {
    key: 'POOL_BRICK_MASONRY_STONE',
    display: 'Pool brick masonry stone',
  },
  { key: 'POOL_CIRCULAR', display: 'Pool circular' },
  { key: 'POOL_CONCRETE', display: 'Pool concrete' },
  { key: 'POOL_FIBERGLASS', display: 'Pool fiberglass' },
  { key: 'POOL_GUNITE', display: 'Pool gunite' },
  { key: 'POOL_IRREGULAR_SHAPED', display: 'Pool irregular shaped' },
  { key: 'POOL_KIDNEY_SHAPED', display: 'Pool kidney shaped' },
  { key: 'POOL_L_SHAPED', display: 'Pool l shaped' },
  { key: 'POOL_METAL', display: 'Pool metal' },
  { key: 'POOL_OVAL_SHAPED', display: 'Pool oval shaped' },
  {
    key: 'POOL_PLASTIC_VINYL_LINED',
    display: 'Pool plastic vinyl lined',
  },
  {
    key: 'POOL_PRE_FABRICATED_VINYL',
    display: 'Pool pre fabricated vinyl',
  },
  { key: 'POOL_SOLAR_HEATED', display: 'Pool solar heated' },
  { key: 'POOL_STONE_GRANITE', display: 'Pool stone granite' },
  {
    key: 'POOL_VINYL_LINING_STEEL_WALLED',
    display: 'Pool vinyl lining steel walled',
  },
  {
    key: 'POOLS_THREE_PLUS_UNSPECIFIED',
    display: 'Pools three plus unspecified',
  },
  { key: 'POOLS_TWO_UNSPECIFIED', display: 'Pools two unspecified' },
  {
    key: 'PUBLIC_MUNICIPAL_COMMERCIAL',
    display: 'Public municipal commercial',
  },
  { key: 'SAUNA_SPA_ONLY', display: 'Sauna spa only' },
  { key: 'SAUNA_ONLY', display: 'Sauna only' },
  { key: 'SPA_GAZEBO_ONLY', display: 'Spa gazebo only' },
  { key: 'SPA_HOT_TUB_DECK_ONLY', display: 'Spa hot tub deck only' },
  { key: 'SPA_HOT_TUB_ONLY', display: 'Spa hot tub only' },
  { key: 'TYPE_NOT_SPECIFIED', display: 'Type not specified' },
  { key: 'WADING_POOL', display: 'Wading pool' },
];

export const educationOptions = [
  { key: 'Unknown', display: 'Unknown' },
  { key: 'NoHighSchoolDiploma', display: 'No high school diploma' },
  { key: 'HighSchoolDiploma', display: 'High school diploma' },
  { key: 'SomeCollegeNoDegree', display: 'Some college no degree' },
  {
    key: 'VocationalTechnicalDegree',
    display: 'Vocational technical degree',
  },
  { key: 'AssociatesDegree', display: 'Associates degree' },
  { key: 'Bachelors', display: 'Bachelors' },
  { key: 'Masters', display: 'Masters' },
  { key: 'Phd', display: 'Phd' },
  { key: 'MedicalDegree', display: 'Medical degree' },
  { key: 'LawDegree', display: 'Law degree' },
];

export const genderOptions = [
  { key: 'Unknown', display: 'Unknown' },
  { key: 'Female', display: 'Female' },
  { key: 'Male', display: 'Male' },
  { key: 'NotSpecified', display: 'Not specified' },
  { key: 'NonBinary', display: 'Non-binary' },
];

export const maritalStatusOptions = [
  { key: 'Unknown', display: 'Unknown' },
  { key: 'Single', display: 'Single' },
  { key: 'Married', display: 'Married' },
  { key: 'DomesticPartner', display: 'Domestic partner' },
  { key: 'Widowed', display: 'Widowed' },
  { key: 'Separated', display: 'Separated' },
  { key: 'Divorced', display: 'Divorced' },
];

export const licenseStatusOptions = [
  { key: 'Valid', display: 'Valid' },
  { key: 'Permit', display: 'Permit' },
  { key: 'Expired', display: 'Expired' },
  { key: 'Suspended', display: 'Suspended' },
  { key: 'Cancelled', display: 'Cancelled' },
  { key: 'Not Licensed', display: 'Not licensed' },
  { key: 'Permanently Revoked', display: 'Permanently revoked' },
];

export const interestOptions = [
  { key: 'Home', display: 'Home' },
  { key: 'Auto', display: 'Auto' },
];

export const employmentStatusOptions = [
  { key: 'Employed', display: 'Employed' },
  { key: 'Unemployed', display: 'Unemployed' },
];

export const applicantTypeOptions = [
  { key: 'Applicant', display: 'Applicant' },
  { key: 'CoApplicant', display: 'CoApplicant' },
];

export const relationOptions = [
  { key: 'Child', display: 'Child' },
  { key: 'Domestic Partner', display: 'Domestic partner' },
  { key: 'Employee', display: 'Employee' },
  { key: 'Insured', display: 'Insured' },
  { key: 'Other', display: 'Other' },
  { key: 'Parent', display: 'Parent' },
  { key: 'Relative', display: 'Relative' },
  { key: 'Spouse', display: 'Spouse' },
];

export const vehicleUsageOptions = [
  { key: 'Business', display: 'Business' },
  { key: 'Farming', display: 'Farming' },
  { key: 'Pleasure', display: 'Pleasure' },
  { key: 'Work', display: 'Work' },
  { key: 'School', display: 'School' },
];

export const vehicleOwnershipOptions = [
  { key: 'Owned', display: 'Owned' },
  { key: 'Leased', display: 'Leased' },
  { key: 'Lien', display: 'Lien' },
];

export const homeOwnershipOptions = [
  { key: 'Home (owned)', display: 'Home (owned)' },
  { key: 'Condo (owned)', display: 'Condo (owned)' },
  { key: 'Apartment', display: 'Apartment' },
  { key: 'Rental Home/Condo', display: 'Rental Home/Condo' },
  { key: 'Mobile Home', display: 'Mobile Home' },
  { key: 'Live With Parents', display: 'Live With Parents' },
  { key: 'Other', display: 'Other' },
];

export const yearsDropDownOptions = [
  { key: '6 months or less', display: '6 months or less' },
  { key: '6-12 months', display: '6-12 months' },
  { key: '1 year', display: '1 year' },
  { key: '2 years', display: '2 years' },
  { key: '3 years', display: '3 years' },
  { key: '4 years', display: '4 years' },
  { key: '5 years', display: '5 years' },
  { key: '6 years', display: '6 years' },
  { key: '7 years', display: '7 years' },
  { key: '8 years', display: '8 years' },
  { key: '9 years', display: '9 years' },
  { key: '10 years', display: '10 years' },
  { key: 'More than 10 years', display: 'More than 10 years' },
];

export const monthsDropDownOptions = [
  { key: '6', display: '6 months or less' },
  { key: '11', display: '6-12 months' },
  { key: '12', display: '1 year' },
  { key: '24', display: '2 years' },
  { key: '36', display: '3 years' },
  { key: '48', display: '4 years' },
  { key: '60', display: '5 years' },
  { key: '72', display: '6 years' },
  { key: '84', display: '7 years' },
  { key: '96', display: '8 years' },
  { key: '108', display: '9 years' },
  { key: '120', display: '10 years' },
  { key: '121', display: 'More than 10 years' },
];

export const policyTermOptions = [
  { key: '6 Month', display: '6 Month' },
  { key: '12 Month', display: '12 Month' },
];