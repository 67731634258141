import { useState } from "react";
import Button from '@/components/shared/button';
import Checkbox from '@/components/shared/checkbox';
import { Policy } from "../../models/policy";
import { useFormContext} from '../form/FormContext'


interface Props {
    header: string;
    policiesList: Policy[];
    formKey: string;
}

const FormListSection: React.FC<Props> = ({
    header,
    policiesList,
    formKey
}) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [selectedCount] = useState<number>(0);
    const { togglePolicy, selectedPolicyKeys, toggleCategory } = useFormContext();

    return (
        <div
            className={`p-[16px] w-full font-normal rounded-[4px] bg-light-surfaces-primary
          text-light-text-primary dark:text-dark-text-primary`}
        >
            <div
                className={`flex gap-4 items-center cursor-pointer`}
                onClick={() => setExpanded(!expanded)}
            >
                <span className="material-symbols-outlined font24">
                    {expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
                </span>
                <span className="font-segoe">
                    {header}&nbsp;({policiesList.length})
                </span>
                {policiesList.length > 1 && expanded && selectedCount < policiesList.length && (
                    <Button
                        type="link"
                        callback={() => toggleCategory(formKey)}
                        classes="font-segoe font-normal"
                    >
                        Select all
                    </Button>
                )}
                {policiesList.length > 1 && expanded && selectedCount === policiesList.length && (
                    <Button
                        type="link"
                        callback={() => toggleCategory(formKey)}
                        classes="font-segoe font-normal"
                    >
                        Deselect All
                    </Button>
                )}
            </div>
            {expanded &&
                policiesList.map((policy) => {
                    const isSelected = selectedPolicyKeys.some(({formKey: innerFormKey, policyKey})=> innerFormKey === formKey && policyKey === policy.policyId)
                    return(
                    <div
                        className={`flex gap-4 p-[16px] font-normal font-segoe
              w-full rounded-[4px] bg-light-surfaces-primary
              text-light-text-primary dark:text-dark-text-primary`}
                    >
                        <Checkbox
                            checked= {isSelected}
                            onChange={() => togglePolicy(policy.policyId, formKey)}
                            label={`${policy.carrier.name} (${policy.policyNumber})`}
                            classes="font-segoe"
                        />
                    </div>
                )})}
        </div>
    );
}

export default FormListSection