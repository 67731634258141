import { PropsWithChildren } from 'react';
import If from '../If';

const Section = ({
  headingText,
  materialIcon,
  classes,
  actions,
  children,
}: PropsWithChildren<{
  headingText?: string;
  materialIcon?: string;
  classes?: string;
  actions?: JSX.Element | null;
}>): JSX.Element => {
  const heading = (
    <div className="flex items-center gap-2">
      <If condition={Boolean(materialIcon)}>
        <span
          className="material-symbols-outlined font24 text-light-text-secondary dark:text-dark-text-secondary"
          data-testid="section-icon"
        >
          {materialIcon}
        </span>
      </If>
      <If condition={Boolean(headingText)}>
        <span
          className="text-light-text-primary dark:text-dark-text-primary text-lg leading-6"
          data-testid="section-heading-text"
        >
          {headingText}
        </span>
      </If>
    </div>
  );

  return (
    <>
      <span className='flex justify-between'>
        {heading}
        {actions}
      </span>

      <div className={`flex flex-col pl-8 gap-8 ${classes ?? ''}`}>
        {children}
      </div>
    </>
  );
};

export default Section;
