import Header from './components/header';
import Forms from './components/form/FormController'
import { useParams } from 'react-router-dom';
import { IPublicClientApplication } from '@azure/msal-browser';
import { FormProvider } from './components/form/FormContext';

export default function Page({ pca }: { pca: IPublicClientApplication }) {
  const { id: accountId } = useParams();

  if (!accountId) {
    return;
  }

  return (
    <>
      <Header />
      <FormProvider>
        <Forms account={accountId} />
      </FormProvider>  
    </>
  );
}
