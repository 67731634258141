import { RequestError, request } from '../../../components/common/utils';
import { Policy } from '../models/policy';
import { PolicyJob } from '../models/policyJob';
import { PolicyJobInputSchema } from '../models/policyJobInputSchema';
import { PolicyJobStatus } from '../models/policyJobStatus';

export const getJobList = async (authedFetch: typeof fetch): Promise<any[]> => {
  const url = `/apim/joborchestration/v1/jobs`;

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await request(url, requestOptions, authedFetch);

  if (response instanceof RequestError) {
    return Promise.reject();
  }

  return await response.json();
};

export const getJobStatus = async (
  jobId: number,
  authedFetch: typeof fetch
): Promise<PolicyJobStatus> => {
  const url = `/apim/joborchestration/v1/jobInstances/${jobId}`;

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await request(url, requestOptions, authedFetch);

  if (response instanceof RequestError) {
    return Promise.reject();
  }

  return await response.json();
};

export const autoPullDocument = async (
  policyList: Policy[],
  policyId: string,
  jobId: string,
  authedFetch: typeof fetch
): Promise<any> => {
  const url = `/apim/joborchestration/v1/jobInstances`;

  const policy = policyList.find((policy: Policy) => policy.policyId === policyId);
  const policyNumber = policy?.policyNumber;
  if (!policyNumber) {
    throw new Error('Policy number not found');
  }

  const jobData = {
    jobId,
    jobInputData: {
      policyId,
      policyNumber,
      effectiveDate: policy?.effectiveDate,
      expirationDate: policy?.expirationDate,
    } as PolicyJobInputSchema,
  };

  const jsonBody = JSON.stringify(jobData);

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: jsonBody,
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};
