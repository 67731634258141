import React from 'react';

export interface IntakeHeaderDisplayProps {
  label: string;
  value?: string | boolean | Date | number | null;
  subValue?: string;
}

const IntakeHeaderDisplay = ({ label, value, subValue }: IntakeHeaderDisplayProps) => {
  if (value === undefined || value === null || value === '') {
    return null;
  }

  const leftBorder =
    label === 'Customer'
      ? 'border-l border-l-light-borders-secondary dark:border-l-dark-borders-secondary'
      : '';

  const isUrl = (str: string) => {
    try {
      new URL(str);
      return true;
    } catch (_) {
      return false;
    }
  };

  return (
    <div className={`flex flex-col justify-center items-start gap-1 min-w-52 ${leftBorder} pl-6`}>
      <span className="text-lg leading-6 text-light-borders-tertiary dark:text-dark-borders-tertiary">
        {label}
      </span>
      <span
        className="text-lg leading-6 text-light-text-inverse flex-none font-semibold"
        data-testid={`${label}-info`}
      >
        {isUrl(value.toString()) ? (
          <a
            href={value.toString()}
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            View in AMS
          </a>
        ) : (
          value?.toString()
        )}
        {subValue && <span className="font-normal"> {subValue?.toLowerCase()}</span>}
      </span>
    </div>
  );
};

export default IntakeHeaderDisplay;
