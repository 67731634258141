/**
 * Displays a greyed out backdrop and dialog container.
 * 
 * @param {Object} config - The configuration object for this component.
 * @param {HTML} config.children - The markup to display in the dialog.
 * @param {boolean} config.open - Flag indicating whether the dialog is displayed.
 * 
 * @example
 *  <Dialog open={open}>
        ...
        dialog contents
        ...
    </Dialog>
 */
export default function Dialog({ children, open }: { children: any; open: boolean }) {
  if (!open) {
    return;
  }
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 flex justify-center items-center z-20">
      <div className="w-[475px] h-fit rounded-3xl bg-light-surfaces-primary dark:bg-dark-surfaces-primary bg-opacity-100 p-[24px]">
        {children}
      </div>
    </div>
  );
}
