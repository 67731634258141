import { useState, useEffect, useCallback } from 'react';
import { IntakeNavOptions } from '../../components/common/constants';
import SideNav from '../../components/SideNav';
import Switch from '../../components/Switch';
import { NavBarItem } from '../../components/SideNavItem';
import type { AgentIntakeForm } from '../../services/forms/agent/schema';
import { agentIntake } from '../../services/forms/agent';
import { FormPage } from '../../components/forms';
import { useForm, FormProvider } from 'react-hook-form';
import { defaultValuesMap } from '../../services/forms/agent/schema';
import type { DefaultValuesMap } from '../../services/forms/agent/schema';
import { useWindowConfirm } from '../../components/utility/useWindowConfirm';
import { useData } from '@/pages/opportunity/components/utility/useData';
import {
  ErrorType,
  findQuoteGroup,
  flattenToEAV,
  getErrorMessage,
} from './services/utils';
import { postEav } from './services/network/eav';
import AlertToast, {
  AlertToast as AlertToastInterface,
} from '@/components/shared/alertToast';
import { useAuthenticatedFetch } from '../../components/common/useAuthenticatedFetch';
import { formSearch } from './services/utils/formSearch';
import { SendToRaterDialog } from '@/components/forms/SendToRaterDialog';
import { useRaterIntegration } from './components/utility/useRaterIntegration';
import {
  checkQuoteGroupsStatus,
  QuoteSubmissionResult,
  sendQuote,
} from './services/network/quote';
import { useQuoteSubmissionForApplication } from './components/utility/useQuoteSubmissionForApp';
import { InsuranceProductEnum } from '@bwinsurance/meta-rater-types';
import {
  RaterEnum,
  RaterProductEnum,
} from '@/services/forms/models/rateProductTypes';

const OpportunityNew = () => {
  const authedFetch = useAuthenticatedFetch();
  const { formData, loading, errorText, eavEntityId } = useData();
  const { raterProductEnabledMap } = useRaterIntegration({
    loading,
    crmAgentId: formData?.crm?.agentId,
  });
  const { completedQuoteGroups, setCompletedQuoteGroups } =
    useQuoteSubmissionForApplication({
      loading,
      applicationId: formData?.application?.id,
    });

  const [selectedTab, setSelectedTab] = useState<NavBarItem>({
    icon: <span className="material-symbols-outlined">people_outline</span>,
    value: IntakeNavOptions.APPLICANT,
    label: 'Applicant(s)',
    search_count: null,
  });
  const [alertToast, setAlertToast] = useState<AlertToastInterface>({
    text: '',
    type: 'info',
  });
  const [alertToastOpen, setAlertToastOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [raterDialogOpen, setRaterDialogOpen] = useState(false);
  const [sendingToRater, setSendingToRater] = useState(false);
  const [lastSaved, setLastSaved] = useState<string | null>(null);

  type Field = {
    display: string;
    page: string;
  };
  const [searchResults, setSearchResults] = useState<Field[] | null>(null);

  const formMethods = useForm<AgentIntakeForm>({
    defaultValues: agentIntake.defaults,
    mode: 'onBlur',
  });

  const { isDirty, isValid } = formMethods.formState;  

  useEffect(() => {
    if (formData) {
      formMethods.reset(formData);
    }
  }, [formData, formMethods]);

  const onSubmit = (data: AgentIntakeForm) => {
    console.log(data);
  };

  const showAlertToast = useCallback((type: string, text: string) => {
    setAlertToast({
      type: type,
      text: text,
    });
    setAlertToastOpen(true);
  }, []);

  const handleCloseAlert = () => {
    setAlertToastOpen(false);
  };

  const getLastSaved = () => {
    const now = new Date();
    const formattedDate = now.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    return formattedDate;
  };

  const onSave = useCallback(async () => {
    let saveError = false;
    let lastSaved = null;

    if (!isDirty && isValid) {
      showAlertToast('success', 'Data saved');
      lastSaved = getLastSaved();
      saveError = false;
      return { saveError, lastSaved };
    }

    if (!isValid) {
      showAlertToast('warning', getErrorMessage('InvalidFields'));
      saveError = true;
      return { saveError, lastSaved };
    }

    if (eavEntityId) {
      setIsSaving(true);

      try {
        const data = formMethods.getValues();
        const eav = flattenToEAV(data, eavEntityId);

        const result = await postEav({ body: eav, fetchFn: authedFetch });

        if (!result) {
          saveError = true;
          throw new Error('Failed to save EAV');
        }

        showAlertToast('success', 'Data saved');
        formMethods.reset(formMethods.getValues(), { keepValues: true });
        saveError = false;
        lastSaved = getLastSaved();
      } catch (err) {
        console.log(err);
        showAlertToast('warning', 'Something went wrong. Please try again!');
        saveError = true;
      }

      setIsSaving(false);
    }

    return { saveError, lastSaved };
  }, [isDirty, isValid, eavEntityId, formMethods, authedFetch, showAlertToast]);

  useWindowConfirm(isDirty, onSave);

  useEffect(() => {
    const autoSave = async () => {
      if (isDirty) {
        try {
          const { lastSaved } = await onSave();
          setLastSaved(lastSaved);
        } catch (error) {
          console.error('Auto-save failed:', error);
        }
      }
    };

    const autoSaveInterval = setInterval(() => {
      autoSave();
    }, 60000); // 1 minute

    return () => clearInterval(autoSaveInterval);
  }, [isDirty, onSave]);

  useWindowConfirm(isDirty, onSave);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const results = value ? formSearch(value, agentIntake) : null;
    setSearchValue(value);
    setSearchResults(results);
  };

  const handleGetQuoteGroups = async ({
    applicationId,
    quoteSubmissionId,
  }: QuoteSubmissionResult) => {
    const quoteGroups = await checkQuoteGroupsStatus({
      applicationId,
      quoteSubmissionId,
      fetchFn: authedFetch,
    });

    const { successQuoteGroups, errorQuoteGroups } = findQuoteGroup({
      rater: RaterEnum.QuoteRush,
      insuranceProduct: InsuranceProductEnum.Enum.Home,
      quoteGroups: quoteGroups || [],
    });
    if (successQuoteGroups.length) {
      setCompletedQuoteGroups(successQuoteGroups);
      const leadId = successQuoteGroups[0].raterData?.leadId;
      const leadIdString = leadId ? ` (Lead ID: ${leadId})` : '';
      showAlertToast(
        'success',
        `${RaterProductEnum.home} sent to ${RaterEnum.QuoteRush}${leadIdString}`
      );
    }
    if (errorQuoteGroups.length) {
      const errorName = errorQuoteGroups[0].errors?.[0]?.name as ErrorType;
      const errorMessage = errorQuoteGroups[0].errors?.[0]?.message;
      const message = getErrorMessage(errorName, { message: errorMessage });
      showAlertToast('warning', message);
    }
  };

  const handleSendRater = async () => {
    if (completedQuoteGroups.length) {
      // agent can only send data to rater one time per product
      // implementing home only, there should be only one success quote group
      const leadId = completedQuoteGroups[0].raterData?.leadId;
      showAlertToast(
        'warning',
        getErrorMessage('AlreadySentError', { leadId: leadId })
      );
      toggleRaterDialog();
      return;
    }
    try {
      setSendingToRater(true);
      const { saveError } = await onSave();
      if (!saveError) {
        if (!eavEntityId) {
          throw new Error('Missing entity id, unable to send to rater');
        }
        const quoteSubmission = await sendQuote({
          entityId: eavEntityId,
          raterName: RaterEnum.QuoteRush,
          insuranceProduct: InsuranceProductEnum.Enum.Home,
          fetchFn: authedFetch,
        });

        if (!quoteSubmission) {
          throw new Error('Failed to send to rater.');
        }

        await handleGetQuoteGroups(quoteSubmission);
      }
    } catch (error) {
      console.log(error);
      showAlertToast('warning', getErrorMessage());
    }
    setSendingToRater(false);
    toggleRaterDialog();
  };

  const toggleRaterDialog = () => {
    setRaterDialogOpen(!raterDialogOpen);
  };

  if (loading) {
    return (
      <div className="text-center text-xl mt-10">
        <span>Loading</span>
      </div>
    );
  }
  if (errorText) {
    return (
      <div className="text-center text-xl mt-10">
        <span>{errorText}</span>
      </div>
    );
  }

  return (
    <div className="border border-[#E9E9E9] gap-5 h-full">
      {sendingToRater && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/70 gap-2">
          <span className="material-symbols-outlined text-[20px] animate-spin text-light-text-inverse">
            progress_activity
          </span>
          <span className="text-light-text-inverse text-lg font-semibold">
            Sending...
          </span>
        </div>
      )}
      <AlertToast
        open={alertToastOpen}
        closeAlert={handleCloseAlert}
        type={alertToast.type}
        text={alertToast.text}
      />
      <SendToRaterDialog
        open={raterDialogOpen}
        handleSendRater={handleSendRater}
        toggleRaterDialog={toggleRaterDialog}
      />
      <FormProvider {...formMethods}>
        <SideNav
          selected={selectedTab}
          setSelected={setSelectedTab}
          onSave={onSave}
          onSearch={onSearch}
          isSaving={isSaving}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          lastSaved={lastSaved}
          setLastSaved={setLastSaved}
          toggleRaterDialog={toggleRaterDialog}
          raterProductEnabledMap={raterProductEnabledMap}
        />
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className="flex flex-col ml-52 p-6 gap-8">
            <Switch selectedKey={selectedTab.parent || selectedTab.value}>
              <FormPage
                key={IntakeNavOptions.APPLICANT}
                page={agentIntake.pages.applicant}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
              />
              <FormPage
                key={IntakeNavOptions.AUTO}
                page={agentIntake.pages.auto}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
              />
              <FormPage
                key={IntakeNavOptions.HOME}
                page={agentIntake.pages.property}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
                quoteGroups={completedQuoteGroups}
              />
            </Switch>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default OpportunityNew;
