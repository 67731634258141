import Header from './components/documents/header';
import Documents from './components/documents/documents';
import { useParams } from 'react-router-dom';
import { IPublicClientApplication } from '@azure/msal-browser';

export default function Page({ pca }: { pca: IPublicClientApplication }) {
  const { id: accountId } = useParams();

  if (!accountId) {
    return;
  }

  return (
    <>
      <Header />
      <Documents account={accountId} pca={pca} />
    </>
  );
}
