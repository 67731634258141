import {
  genderOptions,
  maritalStatusOptions,
  employmentStatusOptions,
  licenseStatusOptions,
  relationOptions,
  vehicleUsageOptions,
  vehicleOwnershipOptions,
  homeOwnershipOptions,
  booleanDropdownOptions,
  stateCodeOptions,
  policyTermOptions,
  educationOptions,
  monthsDropDownOptions,
} from '../helpers';
import type { FormPage } from '../models/formTypes';

export const auto: FormPage = {
  id: 'auto',
  display: 'Auto',
  icon: 'directions_car',
  elements: [
    {
      id: 'residence',
      display: 'Residence',
      type: 'section',
      elements: [
        {
          name: 'residence.ownership',
          display: 'Ownership status',
          placeholder: 'Select...',
          type: 'dropdown',
          options: homeOwnershipOptions,
        },
      ],
    },
    {
      id: 'drivers',
      icon: 'people_outline',
      display: 'Driver',
      type: 'dynamicPanel',
      defaults: 'driverDefaults',
      elements: [
        {
          id: 'generalInfo',
          display: 'General Info',
          type: 'section',
          elements: [
            {
              name: 'name.firstName',
              display: 'First name',
              type: 'text',
              placeholder: 'Enter first name...',
              syncWith: { fieldName: 'applicant.firstName' },
              validation: {
                maxLength: {
                  value: 255,
                  message: 'First name cannot be more than 255 characters',
                },
              },
            },
            {
              name: 'name.lastName',
              display: 'Last name',
              type: 'text',
              placeholder: 'Enter last name...',
              syncWith: { fieldName: 'applicant.lastName' },
              validation: {
                maxLength: {
                  value: 255,
                  message: 'Last name cannot be more than 255 characters',
                },
              },
            },
            {
              name: 'gender',
              display: 'gender',
              type: 'dropdown',
              placeholder: 'Select...',
              options: genderOptions,
              syncWith: { fieldName: 'applicant.gender' },
            },
            {
              name: 'dateOfBirth',
              display: 'Date of birth',
              type: 'date',
              syncWith: { fieldName: 'applicant.dateOfBirth' },
            },
            {
              name: 'maritalStatus',
              display: 'Marital status',
              placeholder: 'Select...',
              type: 'dropdown',
              options: maritalStatusOptions,
              syncWith: { fieldName: 'applicant.maritalStatus' },
            },
            {
              name: 'relation',
              display: 'Relation',
              placeholder: 'Select...',
              type: 'dropdown',
              options: relationOptions,
              syncWith: { fieldName: 'applicant.relation' },
            },
            {
              name: 'education',
              display: 'Education',
              placeholder: 'Select...',
              type: 'dropdown',
              options: educationOptions,
              syncWith: { fieldName: 'applicant.education' },
            },
            {
              name: 'employmentStatus',
              display: 'Employment Status',
              placeholder: 'Select...',
              type: 'dropdown',
              options: employmentStatusOptions,
              syncWith: { fieldName: 'applicant.employmentStatus' },
            },
            {
              name: 'industry',
              display: 'Industry',
              visibleIf: {
                fieldName: 'employmentStatus',
                includeList: ['Employed'],
              },
              placeholder: 'Select...',
              type: 'apidropdown',
              optionsBy: 'dropdown/industry',
              syncWith: { fieldName: 'applicant.industry' },
            },
            {
              name: 'occupation',
              display: 'Occupation',
              visibleIf: {
                fieldName: 'employmentStatus',
                includeList: ['Employed'],
              },
              placeholder: 'Select...',
              type: 'apidropdown',
              optionsBy: 'dropdown/occ_{industry}',
              syncWith: { fieldName: 'applicant.occupation' },
            },
          ],
        },
        {
          id: 'licenseDetails',
          display: 'Drivers License Details',
          type: 'section',
          elements: [
            {
              name: 'license.licenseNumber',
              display: 'License number',
              type: 'text',
              placeholder: 'Enter license number...',
            },
            {
              name: 'license.state',
              display: 'License state',
              placeholder: 'Select...',
              type: 'dropdown',
              options: stateCodeOptions,
            },
            {
              name: 'license.licensedDate',
              display: 'Date licensed',
              type: 'date',
            },
            {
              name: 'license.status',
              display: 'License status',
              placeholder: 'Select...',
              type: 'dropdown',
              options: licenseStatusOptions,
            },
            {
              name: 'hasReceivedViolation',
              display: 'Has a traffic violation',
              type: 'bool',
              options: booleanDropdownOptions,
            },
            {
              name: 'hasAccidentWithinLastFiveYears',
              display: 'Had an accident in last 5 years',
              type: 'bool',
              options: booleanDropdownOptions,
            },
            {
              name: 'hasLicenseRevokedSuspended',
              display: 'Has license been suspended',
              type: 'bool',
              options: booleanDropdownOptions,
            },
          ],
        },
      ],
    },
    {
      id: 'vehicles',
      icon: 'directions_car',
      display: 'Vehicle',
      type: 'dynamicPanel',
      defaults: 'vehicleDefaults',
      elements: [
        {
          id: 'generalInfo',
          display: 'General Info',
          type: 'section',
          elements: [
            {
              name: 'year',
              display: 'Year',
              type: 'apidropdown',
              placeholder: 'Select...',
              optionsBy: 'lookups/auto/years',
            },
            {
              name: 'make',
              display: 'make',
              placeholder: 'Select...',
              type: 'apidropdown',
              optionsBy: 'lookups/auto/makes?year={year}',
            },
            {
              name: 'model',
              display: 'model',
              placeholder: 'Select...',
              type: 'apidropdown',
              optionsBy: 'lookups/auto/models?year={year}&make={make}',
            },
            {
              name: 'subModel',
              display: 'Trim',
              type: 'text',
              placeholder: 'Enter trim...',
            },
            {
              name: 'usage.ownership',
              display: 'Ownership',
              placeholder: 'Select...',
              type: 'dropdown',
              options: vehicleOwnershipOptions,
            },
            {
              name: 'usage.vin',
              display: 'Vehicle identification number (VIN)',
              type: 'text',
              placeholder: 'Enter VIN...',
              validation: {
                minLength: {
                  value: 3,
                  message: 'VIN cannot be less than 3 digits',
                },
                maxLength: {
                  value: 17,
                  message: 'VIN cannot be more than 17 digits',
                },
              },
            },
            {
              name: 'usage.odometer',
              display: 'Odometer',
              type: 'number',
              placeholder: 'Enter odometer...',
            },
          ],
        },
        {
          id: 'usage',
          display: 'Usage',
          type: 'section',
          elements: [
            {
              name: 'usage.usage',
              display: 'Usage',
              placeholder: 'Select...',
              type: 'dropdown',
              options: vehicleUsageOptions,
            },
            {
              name: 'usage.principalDriverId',
              display: 'Primary Driver',
              placeholder: 'Select...',
              type: 'dynamicdropdown',
              optionsBy: 'drivers',
            },
            {
              name: 'usage.oneWayMiles',
              display: 'One Way Miles',
              type: 'number',
              placeholder: 'Enter one way miles...',
            },
            {
              name: 'usage.daysPerWeek',
              display: 'Days Per Week',
              placeholder: 'Select...',
              type: 'dropdown',
              options: [
                { key: '1', display: '1' },
                { key: '2', display: '2' },
                { key: '3', display: '3' },
                { key: '4', display: '4' },
                { key: '5', display: '5' },
                { key: '6', display: '6' },
                { key: '7', display: '7' },
              ],
            },
            {
              name: 'usage.weeksPerMonth',
              display: 'Weeks Per Month',
              placeholder: 'Select...',
              type: 'dropdown',
              options: [
                { key: '1', display: '1' },
                { key: '2', display: '2' },
                { key: '3', display: '3' },
                { key: '4', display: '4' },
              ],
            },
            {
              name: 'usage.annualMiles',
              display: 'Annual Miles',
              type: 'number',
              placeholder: 'Enter annual miles...',
            },
          ],
        },
      ],
    },
    {
      id: 'policy',
      display: 'Policy',
      type: 'section',
      elements: [
        {
          name: 'auto.policy.effectiveDate',
          display: 'Effective Date',
          type: 'date',
        },
        {
          name: 'auto.policy.term',
          display: 'Term',
          placeholder: 'Select...',
          type: 'dropdown',
          options: policyTermOptions,
        },
      ],
    },
    {
      // TODO: Adjust this sections duration dropdowns when data model issue is settled
      id: 'priorPolicy',
      display: 'Prior policy',
      type: 'section',
      elements: [
        {
          name: 'auto.priorPolicy.hasPriorPolicy',
          display: 'Has existing coverage/policy',
          type: 'dropdown',
          placeholder: 'Select...',
          options: [
            { key: 'hasCurrentPolicy', display: 'Has current policy' },
            { key: 'hasExpiredPolicy', display: 'Policy is expired' },
            { key: 'hasNeverInsured', display: 'Has no prior policy' },
          ],
        },
        {
          name: 'auto.priorPolicy.carrier',
          display: 'Carrier',
          type: 'text',
          placeholder: 'Enter carrier...',
          visibleIf: {
            fieldName: 'auto.priorPolicy.hasPriorPolicy',
            includeList: ['hasCurrentPolicy'],
          },
        },
        {
          name: 'auto.priorPolicy.monthsWithCarrier',
          display: 'Years with carrier',
          placeholder: 'Select...',
          visibleIf: {
            fieldName: 'auto.priorPolicy.hasPriorPolicy',
            includeList: ['hasCurrentPolicy'],
          },
          type: 'dropdown',
          options: monthsDropDownOptions,
        },
        {
          name: 'auto.priorPolicy.expirationDate',
          display: 'Expiration date',
          visibleIf: {
            fieldName: 'auto.priorPolicy.hasPriorPolicy',
            includeList: ['hasCurrentPolicy'],
          },
          type: 'date',
        },
        // Questions for expired coverage
        {
          name: 'auto.priorPolicy.reasonForLapse',
          display: 'Reason for lapse',
          visibleIf: {
            fieldName: 'auto.priorPolicy.hasPriorPolicy',
            includeList: ['hasExpiredPolicy'],
          },
          placeholder: 'Select...',
          type: 'dropdown',
          options: [
            { key: 'Active Military Duty', display: 'Active Military Duty' },
            { key: 'Driving A Company Car', display: 'Driving A Company Car' },
            {
              key: 'Driving A Car Owned By A Relative',
              display: 'Driving A Car Owned By A Relative',
            },
            {
              key: 'Has Not Owned Or Operated A Vehicle',
              display: 'Has Not Owned Or Operated A Vehicle',
            },
            {
              key: 'Owned Veh Parked/Stored Off Road/Not Driven',
              display: 'Owned Veh Parked/Stored Off Road/Not Driven',
            },
            { key: 'Non-Payment', display: 'Non-Payment' },
            { key: 'Other', display: 'Other' },
          ],
        },
        {
          name: 'auto.priorPolicy.durationOfLapsedCoverage',
          display: 'Duration of lapse',
          visibleIf: {
            fieldName: 'auto.priorPolicy.hasPriorPolicy',
            includeList: ['hasExpiredPolicy'],
          },
          placeholder: 'Select...',
          type: 'dropdown',
          options: monthsDropDownOptions,
        },
        // Question for no previous policy
        {
          name: 'auto.priorPolicy.reasonNoPrior',
          display: 'Reason no prior',
          visibleIf: {
            fieldName: 'auto.priorPolicy.hasPriorPolicy',
            includeList: ['hasNeverInsured'],
          },
          placeholder: 'Select...',
          type: 'dropdown',
          options: [
            { key: 'Active Military Duty', display: 'Active Military Duty' },
            { key: 'Driving A Company Car', display: 'Driving A Company Car' },
            {
              key: 'Driving A Car Owned By A Relative',
              display: 'Driving A Car Owned By A Relative',
            },
            {
              key: 'Has Not Owned Or Operated A Vehicle',
              display: 'Has Not Owned Or Operated A Vehicle',
            },
            {
              key: 'Owned Veh Parked/Stored Off Road/Not Driven',
              display: 'Owned Veh Parked/Stored Off Road/Not Driven',
            },
            { key: 'Non-Payment', display: 'Non-Payment' },
            { key: 'Other', display: 'Other' },
          ],
        },
      ],
    },
  ],
};
