import { useEffect, useState } from 'react';
import { getRaterIntegration } from '../../services/network/dynamics';
import { mapRaterIntegrationFromRecordSet } from '../../services/utils';
import { RaterIntegration, RaterIntegrationResponse } from '../../models/raterIntegration';
import { useMsal } from '@azure/msal-react';
import { getAccessToken } from '../../../../components/common/utils';
import { RaterEnum, RaterProductEnabledMap, RaterProductEnum } from '@/services/forms/models/rateProductTypes';

export const useRaterIntegration = ({
  loading,
  crmAgentId,
}: {
  loading: boolean;
  crmAgentId?: string;
}) => {
  const { instance } = useMsal();

  const raterProductEnabledMapDefaults: RaterProductEnabledMap = {
    [`${RaterEnum.QuoteRush}${RaterProductEnum.home}`]: false,
    [`${RaterEnum.EZLynx}${RaterProductEnum.home}`]: false,
  };
  const [raterProductEnabledMap, setRaterProductEnabledMap] = useState<RaterProductEnabledMap>(
    raterProductEnabledMapDefaults
  );
  const raterProducts = [
    {
      rater: RaterEnum.QuoteRush,
      product: RaterProductEnum.home,
    },
    {
      rater: RaterEnum.EZLynx,
      product: RaterProductEnum.home,
    },
  ];

  const isRaterEnabled = (
    product: string,
    rater: string,
    raterIntegrations: RaterIntegration[]
  ) => {
    const raterConfig = raterIntegrations.find(
      (config: RaterIntegration) =>
        config.product.toUpperCase() === product.toUpperCase() &&
        config.rater.toUpperCase() === rater.toUpperCase()
    );
    if (!raterConfig) {
      console.log(`Rater Integration Info not found for ${rater} ${product}`);
      return false;
    }
    return true;
  };

  const loadRaterIntegration = async (agentId: string) => {
    try {
      const accessToken = await getAccessToken(instance);

      if (!accessToken) {
        throw new Error('Failed to get access token');
      }
      const result: RaterIntegrationResponse[] | null = await getRaterIntegration(
        agentId,
        accessToken
      );
      const raterIntegrations: RaterIntegration[] = (result || []).map(
        mapRaterIntegrationFromRecordSet
      );
      const newRaterProductEnabledMap = { ...raterProductEnabledMap };
      
      raterProducts.forEach(({ rater, product }) => {
        newRaterProductEnabledMap[`${rater}${product}`] = isRaterEnabled(
          product,
          rater,
          raterIntegrations
        );
      });

      setRaterProductEnabledMap(newRaterProductEnabledMap);
    } catch (error) {
      console.log('Failed to load rater integration data', error);
      setRaterProductEnabledMap(raterProductEnabledMapDefaults);
    }
  };

  useEffect(() => {
    if (!loading && crmAgentId) {
      loadRaterIntegration(crmAgentId);
    }
  }, [loading, crmAgentId]);

  return { raterProductEnabledMap };
};
