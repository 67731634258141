import type { FC } from 'react';
import Dialog from '@/components/shared/dialog';
import { Button } from '@/components/ui/button';

type SendToRaterDialogProps = {
  open: boolean;
  handleSendRater: () => void;
  toggleRaterDialog: () => void;
};

const SendToRaterDialog: FC<SendToRaterDialogProps> = ({
  open,
  handleSendRater,
  toggleRaterDialog,
}) => {
  return (
    <Dialog open={open}>
      <div className="flex justify-between items-center gap-2.5">
        <h6 className="leading-[26.6px] font-semibold text-light-text-contrast dark:text-dark-text-contrast">
          Send Homeowners to QuoteRUSH?
        </h6>
        <Button onClick={toggleRaterDialog} variant={'ghost'} size={'icon'}>
          <span className="material-symbols-outlined text-[20px] text-light-icons-secondary dark:text-dark-icons-secondary">
            close
          </span>
        </Button>
      </div>
      <div className="mt-3 flex flex-col gap-3 text-light-text-contrast dark:text-dark-text-contrast text-sm">
        <p>
          Are you sure you're ready to <span className="font-semibold">send Homeowners</span> to
          QuoteRUSH?
        </p>
        <p>
          This <span className="font-semibold">can only be done once</span>. You can make further
          adjustments directly in QuoteRUSH.
        </p>
      </div>
      <div className="flex justify-end gap-2.5 pt-12">
        <Button
          data-testid={'confirm-send-button'}
          onClick={handleSendRater}
          variant={'link'}
          size={'lg'}
        >
          <span>Send Homeowners to QuoteRUSH</span>
        </Button>
        <Button onClick={toggleRaterDialog} variant={'outline'} size={'sm'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};

export { SendToRaterDialog };
